import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../../Api";

const initialState = {
    allCollections: {
        collections: [],
        status: "idle",
        isLoading: false,
        error: null,
    },
    collectionsByCategoryId: {
        collections: [],
        status: "idle",
        isLoading: false,
        error: null,
    }
};

// Async thunks...

export const fetchComponentCollections = createAsyncThunk(
    "componentCollection/fetchCollectionsById",
    async (sectionId, thunkAPI) => {
        try {
            const response = await api.get(
                `${process.env.REACT_APP_API_URL}/api/fence/getComponentCollections`
            );
            return response.data.data;
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: error.message });
        }
    }
);

export const fetchComponentCollectionsByCategoryId = createAsyncThunk(
    "componentCollection/fetchCollectionsByCategoryId",
    async (categoryId, thunkAPI) => {
        try {
            const response = await api.get(
                `${process.env.REACT_APP_API_URL}/api/fence/getComponentCollectionsByCategoryId/${categoryId}`
            );
            return response.data.data;
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: error.message });
        }
    }
);



export const addComponentCollection = createAsyncThunk(
    "componentCollection/addComponent",
    async (collectionData, { rejectWithValue }) => {
        try {
            const response = await api.post(
                `${process.env.REACT_APP_API_URL}/api/fence/addComponentCollection`,
                collectionData
            );
            return response.data.success ? response.data.data : rejectWithValue({ error: response.data.message });
        } catch (error) {
            return rejectWithValue({ error: error.response?.data?.message || error.message });
        }
    }
);

export const updateComponentCollection = createAsyncThunk(
    "componentCollection/updateComponent",
    async (updateData, thunkAPI) => {
        try {
            const response = await api.put(
                `${process.env.REACT_APP_API_URL}/api/fence/updateComponentCollection`,
                updateData
            );
            return response.data.success ? response.data.data : thunkAPI.rejectWithValue({ error: response.data.message });
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: error.response?.data?.message || error.message });
        }
    }
);


export const deleteComponentCollection = createAsyncThunk(
    "componentCollection/deleteComponent",
    async (collectionId,collectionData, thunkAPI) => {
        try {
            const response = await api.delete(
                `${process.env.REACT_APP_API_URL}/api/fence/deleteComponentCollection?id=${collectionId}`,
                collectionData
            );
            return response.data.data;
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: error.message });
        }
    }
);

const componentCollectionsSlice = createSlice({
    name: "componentCollections",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            // Handling all collections
            .addCase(fetchComponentCollections.pending, (state) => {
                state.allCollections.status = "loading";
            })
            .addCase(fetchComponentCollections.fulfilled, (state, action) => {
                state.allCollections.status = "succeeded";
                state.allCollections.collections = action.payload;
            })
            .addCase(fetchComponentCollections.rejected, (state, action) => {
                state.allCollections.status = "failed";
                state.allCollections.error = action.error.message;
            })
            // Handling collections by category ID
            .addCase(fetchComponentCollectionsByCategoryId.pending, (state) => {
                state.collectionsByCategoryId.status = "loading";
            })
            .addCase(fetchComponentCollectionsByCategoryId.fulfilled, (state, action) => {
                state.collectionsByCategoryId.status = "succeeded";
                state.collectionsByCategoryId.collections = action.payload;
            })
            .addCase(fetchComponentCollectionsByCategoryId.rejected, (state, action) => {
                state.collectionsByCategoryId.status = "failed";
                state.collectionsByCategoryId.error = action.error.message;
            })
            // Add component collection
            .addCase(addComponentCollection.pending, (state) => {
                state.allCollections.isLoading = true;
            })
            .addCase(addComponentCollection.fulfilled, (state, action) => {
                state.allCollections.isLoading = false;
                state.allCollections.status = "succeeded";
                state.allCollections.collections.push(action.payload);
            })
            .addCase(addComponentCollection.rejected, (state, action) => {
                state.allCollections.isLoading = false;
                state.allCollections.status = "failed";
                state.allCollections.error = action.error.message;
            })
            .addCase(updateComponentCollection.pending, (state) => {
                state.allCollections.isLoading = true;
            })
            .addCase(updateComponentCollection.fulfilled, (state, action) => {
                state.allCollections.isLoading = false;
                state.allCollections.status = "succeeded";
                const updatedCollection = action.payload;
                const index = state.allCollections.collections.findIndex(collection => collection._id === updatedCollection._id);
                if (index !== -1) {
                    state.allCollections.collections[index] = updatedCollection;
                }
            })
            .addCase(updateComponentCollection.rejected, (state, action) => {
                state.allCollections.isLoading = false;
                state.allCollections.status = "failed";
                state.allCollections.error = action.error.message;
            })
            .addCase(deleteComponentCollection.pending, (state) => {
                state.allCollections.isLoading = true;
            })
            .addCase(deleteComponentCollection.fulfilled, (state, action) => {
                state.allCollections.isLoading = false;
                state.allCollections.status = "succeeded";
                state.allCollections.collections = state.allCollections.collections.filter(collection => collection._id !== action.meta.arg);
            })
            .addCase(deleteComponentCollection.rejected, (state, action) => {
                state.allCollections.isLoading = false;
                state.allCollections.status = "failed";
                state.allCollections.error = action.error.message;
            });
    },
});

export default componentCollectionsSlice.reducer;
