import React, {Suspense, useEffect, useState} from "react";
import {FormControl, FormLabel} from "@chakra-ui/react";
import {COMPONENT_COLUMNS} from "../utils/utils";
import {handleAddComponentsByCSV} from "../fence-components/handleApiCalls";
import {useDispatch, useSelector} from "react-redux";
import {fetchFenceTypes} from "../../../../../redux/reducers/fence/types-slice/fenceTypeSlice";
import {addVendor} from "../../../../../redux/reducers/vendor/vendorSlice";
import {showToast} from "../../../../utils/toastUtils";

// Lazy loading modal components
const AddVendorModal = React.lazy(() => import("../../../vendors/AddVendorModal"));

function MappingComponent({ componentKeys,headerValues, onHeaderMappingChange,enabledCategory,onSave, isColumnMode=false, header1="Columns", header2="Sheet Columns" }) {

    return (
        <div className="w-1/2 overflow-hidden">
            {
                enabledCategory ? (
                    <>
                        {
                            isColumnMode && (
                                <div className="flex">
                                    <FormControl
                                        className="flex items-center w-[90%] border border-purple-500 py-1 px-2 rounded">
                                        <FormLabel
                                            className="block text-sm text-purple-500 whitespace-nowrap w-1/2 ml-3">Selected
                                            Category:</FormLabel>
                                        <FormLabel
                                            className="block text-sm text-green-700 font-bold whitespace-nowrap w-1/2 ml-3">{enabledCategory?.sheetName || "No category selected"}</FormLabel>
                                    </FormControl>
                                    <button
                                        onClick={()=>onSave(enabledCategory)}
                                        className="bg-green-500 text-white px-4 py-1 rounded hover:bg-green-300 ml-4">{enabledCategory?.saved ? "Update" : "Save"}
                                    </button>
                                </div>

                            )
                        }
                        <div className="flex mt-1">
                            <FormLabel
                                className="block text-sm font-semibold text-gray-900 whitespace-nowrap w-1/2 ml-3">{header1}:</FormLabel>
                            <FormLabel
                                className="block text-sm font-semibold text-gray-900 whitespace-nowrap w-1/2 ml-20">{header2}:</FormLabel>
                        </div>
                        <div className="overflow-y-auto h-full w-full">
                            {headerValues?.map((header, index) => {
                                return (
                                    <FormControl className="flex items-center justify-between mr-2" key={index}>
                                        <div className="flex w-1/2">
                                            <FormLabel
                                                className="block text-xs font-medium text-gray-700 whitespace-nowrap">{index + 1}.</FormLabel>
                                            <FormLabel
                                                className="block text-xs font-medium text-gray-700 whitespace-nowrap ml-4">{header.header}:</FormLabel>
                                        </div>
                                        <select
                                            value={header.mappingKey}
                                            onChange={(e) => onHeaderMappingChange(header, e.target.value,index)}
                                            className="w-1/2 mt-1 px-1 py-1 text-xs border border-gray-500 rounded text-gray-800 cursor-pointer"
                                        >
                                            <option className="bg-red-100" value={""}>Not specified</option>
                                            {
                                                componentKeys?.length ? componentKeys.map(option => (
                                                    <option className={header.mappingKey === option ? "bg-gray-100" : ""} key={option} value={option}>{option}</option>
                                                )) : <option>No any header</option>
                                            }
                                        </select>

                                    </FormControl>
                                )
                            })
                            }
                        </div>
                    </>
                ) : ''
            }
        </div>
    );
}
function getKeyValue(obj, accessor) {
    // Try to get the value directly first
    if (obj.hasOwnProperty(accessor)) {
        return obj[accessor];
    }
    // Fallback to checking case-insensitive match if direct access didn't work
    accessor = accessor.toLowerCase();
    for (const [key, value] of Object.entries(obj)) {
        if (key.toLowerCase().includes(accessor)) {
            return value;
        }
    }
    return null; // Return null if no matching keys are found
}

function getKeyByAccessors(componentKeys, accessors) {
    let key = ''
    if (!componentKeys?.length) return key;

    for (const accessor of accessors) {
        const ind = componentKeys?.map(k=>k.toLowerCase())?.indexOf(accessor.toLowerCase())
        if (ind > -1) {
            key = componentKeys[ind];
        }
    }
    if (!key){
        for (const accessor of accessors) {
            const dd = componentKeys?.map(k=>k.toLowerCase())?.find(kk=>kk?.indexOf(accessor.toLowerCase()) > -1);
            if (dd) {
                key = dd;
            }
        }
    }

    return key;
}


const MappingHeadersModal = ({
                                 onCancel,
                                 headerText="Map Columns",
                                 categories,
                                 sheets,
                             }) => {

    const dispatch = useDispatch();
    const fencesTypes = useSelector(state => state?.fenceTypes?.data);
    const fenceVendors = useSelector((state) => state.fenceVendors.data);
    const isSaving = useSelector((state) => state.fenceCompsCsv.isLoading);

    const [mappedData, setMappedData] = useState([])

    const [categoriesMappingOptions, setCategoriesMappingOptions] = useState([])
    const [componentKeys, setComponentKeys] = useState([])

    const [enabledCategory, setEnabledCategory] = useState('');
    const [selectedCategory, setSelectedCategory] = useState('');

    const [selectedFenceType, setSelectedFenceType] = useState('');
    const [selectedVendor, setSelectedVendor] = useState('');
    const [showAddVendorModal, setShowAddVendorModal] = useState(false);



    useEffect(() => {
        dispatch(fetchFenceTypes()); // Fetch fence types if not already loaded
    }, [dispatch]);

    useEffect(() => {
        if (fencesTypes.length) setSelectedFenceType(fencesTypes[0])
        if (fenceVendors.length) setSelectedVendor(fenceVendors[0])
    }, [fencesTypes.length,fenceVendors.length]);



    useEffect(() => {
        if (sheets?.length) {
            setCategoriesMappingOptions(sheets.map(s=>({value: s.sheetName, label: s.sheetName})))
        }
    }, [sheets]);
    useEffect(() => {
        if (categories.length && sheets?.length) {
            setMappedData(categories.map(cate=>({
                sheetName:cate.categoryName,
                categoryId:cate._id,
                mappedSheetName:sheets[0].sheetName,
                data:sheets[0].data,
                checked:false,
                saved:false,
                headerValues:[]
            })))
        }
    }, [categories,sheets]);

    useEffect(() => {
        if (sheets?.length && selectedCategory) {
            const selectedSheet = sheets.find(s => s.sheetName === selectedCategory);
            if (selectedSheet) {
                const sheetColumns = Object.keys(selectedSheet.data[0]);
                setComponentKeys(sheetColumns);
            }
        }
    }, [sheets, selectedCategory,enabledCategory]);


    useEffect(()=>{
        if (componentKeys?.length && enabledCategory && !enabledCategory?.saved ) {
            setMappedData(md => {
                const mdList = [...md]
                const ind = mdList.findIndex(i => i.sheetName === enabledCategory.sheetName)
                if (ind > -1) {
                    mdList[ind].headerValues = getUpdatedHeaderValues(componentKeys)
                }
                return mdList;
            })

        }
    },[componentKeys,enabledCategory])


    const getUpdatedHeaderValues = (keys) =>{
        const mappedHeaders= COMPONENT_COLUMNS.map(col=>{
            return {key:col.accessor,header:col.Header,mappingKey:getKeyByAccessors(keys,col.mappingKeys)}
        })
        return mappedHeaders;
    }
    const onCategoryChangeForSheet = (e,categoryName) =>{
        if (categoryName !== enabledCategory?.sheetName) return;
        setupMappedState(e.target.value,enabledCategory.sheetName)
    }

    function setupMappedState(value,enabledSheetName){
        setSelectedCategory(value);
        setMappedData(md=>{
            const mdList = [...md]
            const sheetObj = sheets.find(i=>i.sheetName === value)
            if (sheetObj){
                const ind = mdList.findIndex(i=>i.sheetName === enabledSheetName)
                if (ind > -1){
                    mdList[ind] = {...mdList[ind],mappedSheetName:sheetObj.sheetName,data:sheetObj.data}
                }
            }
            return mdList;
        })
    }


    const handleRadioChange = (value,index) => {
        setMappedData(list=>{
            const nList = [...list].map(i=>({...i,checked:false}))
            if (nList[index]){
                nList[index].checked = value
                setEnabledCategory(nList[index])
                setupMappedState(nList[index].mappedSheetName,nList[index].sheetName)
            }
            return nList;
        })
    };

    const onHeaderMappingChange = (header,value,index) => {
        if (!enabledCategory) {
            console.log("enabledCategory not found." )
            return;
        }
        setMappedData(md=>{
            const mdList = [...md]
            const ind = mdList.findIndex(i=>i.sheetName === enabledCategory.sheetName)
            if (ind > -1){
                let dd = mdList[ind]?.headerValues || {};
                dd[index].mappingKey = value
                mdList[ind].headerValues = dd
            }
            return mdList;
        })
    };
    const onSaveColumns = (category) => {
        if (!category) {
            console.log("enabledCategory not found." )
            return;
        }
        setMappedData(md=>{
            let mdList = [...md]
            const ind = mdList.findIndex(i=>i.sheetName === category.sheetName)
            if (ind > -1){
                if (mdList[ind]){
                    mdList[ind].saved = true;
                }
            }
            return mdList;
        })
    };

    const onNextStep = () => {
        if (!mappedData){
            console.log("Data not found" )
            return;
        }
        // Here you can add more logs to check if data is correctly formatted before dispatching
        if (mappedData) {
            handleAddComponentsByCSV(dispatch,{data:mappedData,fenceType:selectedFenceType,vendor:selectedVendor})
        } else {
            console.error('No data to save to the database.');
        }
    };


    const handleAddNewVendor = async (vendorData) => {
        try {
            await dispatch(addVendor(vendorData));
            showToast("Vendor successfully added", "success");
        } catch (error) {
            console.log(error.message);
            showToast("Error adding vendor:", "error");

        }
    };

    return (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 h-full w-full z-50 flex items-center justify-center" id="my-modal">
            <div className="relative bg-white border shadow-lg rounded-md flex flex-col w-[70%] h-[90%] mx-auto my-4">
                <header className=" h-[40px] border-b flex justify-center items-center mx-2">
                    <p className="text-md font-bold">{headerText}</p>
                </header>
                <main className="flex justify-around px-5 py-2 overflow-hidden" style={{height:"calc(100% - 0px)"}}>
                    <div className="w-1/2">
                        {/*Fence Type*/}
                        <div className="pb-2 flex items-center ml-3">
                            <label htmlFor="fenceTypeId" className="text-xs font-bold text-gray-700 mr-4">Select Fence Type <strong className="text-red-500">* </strong> :</label>
                            <select
                                id="fenceTypeId"
                                name="fenceTypeId"
                                value={selectedFenceType?.type || ''}
                                onChange={(e)=>setSelectedFenceType(fencesTypes[e.target.selectedIndex])}
                                className="w-1/2 mt-1 px-2 py-1 border border-purple-500 rounded text-xs text-purple-800"
                            >
                                {fencesTypes.map((type) => (
                                    <option key={type._id} value={type.type}>{type.type}</option>
                                ))}
                            </select>
                        </div>
                        {/*Vendor*/}
                        <FormControl key={"vendors"} className="flex items-center">
                            <FormLabel className="block text-xs font-bold text-gray-700 ml-3 mr-2">Select Primary Vendor <strong className="text-red-500">* </strong>: </FormLabel>
                            <select
                                value={selectedVendor?.name}
                                onChange={(e) => {
                                    setSelectedVendor(fenceVendors[e.target.selectedIndex])
                                }}
                                name={'Vendor'}
                                className="w-[40%] mt-1 px-2 py-1 text-xs border border-gray-500 rounded text-gray-800 cursor-pointer"
                            >
                                {fenceVendors?.length ? fenceVendors.map((vendor) => (
                                    <option key={vendor._id} name={vendor} value={vendor.name}>
                                        {vendor.name}
                                    </option>
                                )) : <option value={""}>No Any</option>}
                            </select>
                            <button title="Add new vendor (if not exist in the list)" className="bg-blue-500 hover:bg-blue-700 ml-2 text-white text-xs px-2 py-1 rounded cursor-pointer" onClick={() => { setShowAddVendorModal(true) }}>
                                Add new vendor
                            </button>
                        </FormControl>


                        {/*categories mappping*/}
                        <div className="flex mt-1">
                            <FormLabel className="block text-sm font-semibold text-gray-900 whitespace-nowrap w-1/2 ml-3">My Categories:</FormLabel>
                            <FormLabel className="block text-sm font-semibold text-gray-900 whitespace-nowrap w-1/2 ml-20">File Categories:</FormLabel>
                        </div>
                        {mappedData?.map((category, index) => (
                            <FormControl className="w-[90%] flex items-center justify-between" key={index}>
                                <div className="flex w-[30%]">
                                    <FormLabel className="block text-xs font-medium text-gray-700 whitespace-nowrap">{index + 1}.</FormLabel>
                                    <FormLabel className="block text-xs font-medium text-gray-700 whitespace-nowrap ml-4">{category.sheetName}:</FormLabel>
                                </div>
                                <select
                                    value={category.mappedSheetName}
                                    onChange={(e)=>onCategoryChangeForSheet(e,category.sheetName)}
                                    className="min-w-[50%] max-h-[50%] mt-1 px-1 py-1 text-xs border border-gray-500 rounded text-gray-800 cursor-pointer"
                                >
                                    {
                                        categoriesMappingOptions?.length ? categoriesMappingOptions.map(option => (
                                            <option key={option.value} value={option.value}>{option.label}</option>
                                        )) : <option >No Item Selected</option>
                                    }
                                </select>
                                <input
                                    type="radio"
                                    checked={category?.checked || false}
                                    onChange={(e)=>handleRadioChange(e.target.checked,index)} // no-op since we manage state via the dropdown
                                    className="form-radio h-4 w-4 text-green-500 ml-2 active:bg-white/70 cursor-pointer"
                                />
                                <span className="text-green-500 ml-2">{category.saved ? "✔" : "x"}</span>

                            </FormControl>
                        ))}
                    </div>

                    <MappingComponent headerValues={mappedData?.find(d=>d.sheetName === enabledCategory?.sheetName)?.headerValues}
                                      componentKeys={componentKeys}
                                      isColumnMode={true}
                                      enabledCategory={enabledCategory}
                                      onHeaderMappingChange={onHeaderMappingChange}
                                      onSave={onSaveColumns}
                                      />
                </main>
                <footer className="px-5 py-2 border-t h-[50px]">
                    <div className="flex justify-end items-center space-x-3">
                        <button onClick={onCancel} className="bg-gray-500 text-white px-4 py-1 rounded hover:bg-gray-600">Cancel</button>
                        <button onClick={onNextStep}

                                className="linear relative bg-purple-500 text-white px-4 py-1 rounded hover:bg-purple-600">
                            {isSaving ? (
                                <div className="flex items-center justify-center">
                                    <div className="mr-2 animate-spin rounded-full border-t-2 border-r-2 border-b-2 border-l-2 border-white h-4 w-4"></div>
                                    Saving to Database ...
                                </div>
                            ) : "Save to Database"
                            }


                            </button>
                    </div>
                </footer>
            </div>
            <Suspense fallback={<div>Loading...</div>}>
                {showAddVendorModal && <AddVendorModal
                    onClose={() => setShowAddVendorModal(false)}
                    onSave={handleAddNewVendor}
                />}
            </Suspense>
        </div>
    );
};

export default MappingHeadersModal;
