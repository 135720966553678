import React, { useState, useEffect, lazy, Suspense } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    deleteMyFenceStyle,
    fetchMyFenceStyles,
    addMyFenceStyle,
    updateMyFenceStyle
} from "../../../../redux/reducers/fence/fences/myFenceStylesSlice";
import { fetchFenceTypes } from "../../../../redux/reducers/fence/types-slice/fenceTypeSlice";
import Navbar from "../../../../components/navbar";
import {FENCE_STYLES_TABLE_COLUMNS} from "../../../utils/constants";
import MyFenceStylesTable from "./MyFenceStylesTable";
import MyDropdown from "../../../common-comps/dropdown/MyDropdown";
import SearchComponent from "../components/fence-components/search/SearchComponent";
import {fetchFenceComponentCategories} from "../../../../redux/reducers/fence/fence-component-categories/fenceComponentCategoriesSlice";
import {showToast} from "../../../utils/toastUtils";
import MySpinner from "../../../common-comps/spinner/MySpinner";
import FenceBuilder from "./fence-builder/new-fence-builder/FenceBuilder";

// Lazy load the modal component
const FenceBuilderModal = lazy(() => import("./fence-builder/FenceBuilderModal"));
const DeleteConfirmationModal = lazy(() => import("../../../../utils/DeleteConfirmationModal"));

const MyFenceStyles = () => {
    const dispatch = useDispatch();
    const {data:fencesStyles, isLoading:loadingData} = useSelector((state) => state.myFenceStyles.allFenceStyles);
    const fenceTypes = useSelector(state => state.fenceTypes.data);

    const [fenceTypeList, setFenceTypeList] = useState([{_id:-1,type:'All'}]);
    const [selectedType, setSelectedType] = useState({_id:-1,type:'All'});

    const [selectedCateStyles, setSelectedCateStyles] = useState([]);


    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [isUpdating, setIsUpdating] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);
    const [editMode, setEditMode] = useState(false);
    const [loadingSpinner, setLoadingSpinner] = useState(false);


    useEffect(() => {
        dispatch(fetchFenceTypes());
        dispatch(fetchFenceComponentCategories());
        dispatch(fetchMyFenceStyles());
    }, [dispatch]);
    useEffect(() => {
        setLoadingSpinner((!fencesStyles?.length && loadingData) || false)
    }, [loadingData]);

    useEffect(() => {
        if (fenceTypes?.length){
            const types = [{_id:-1,type:'All'},...fenceTypes]
            setFenceTypeList(types)
            setSelectedType(types[0])
        }

    }, [fenceTypes]);

    useEffect(() => {
        if (selectedType && selectedType?._id !== -1) {
            setSelectedCateStyles(fencesStyles?.filter(c=>c.fenceType?._id === selectedType?._id) || [])
        }else {
            setSelectedCateStyles(fencesStyles || [])
        }
    }, [selectedType,fencesStyles]);

    const handleDelete = async (itemId) => {
        try {
            setIsDeleting(true);
            const response = await dispatch(deleteMyFenceStyle(itemId));
            if (response.meta.requestStatus === "fulfilled") {
                showToast("Fence style deleted successfully", 'success');
            } else {
                showToast(`Network error`, 'error');
            }
            setIsDeleteModalOpen(false);
        } finally {
            setIsDeleting(false);
        }
    };

    const handleUpdate = async (styleDataJson,fenceImage,id,callback) => {
        try {
            setIsUpdating(true);
            const formData = new FormData();
            formData.append("styleData", styleDataJson);

            if (fenceImage) {
                let image = ''
                if (fenceImage instanceof Blob) {
                    formData.append('fenceImage', fenceImage, fenceImage.name);
                }else {
                    image = fenceImage
                }
                image && formData.append('image',image)
            }
            const response = await dispatch(updateMyFenceStyle({styleData:formData,id}));
            if (response?.meta?.requestStatus === "fulfilled") {
                showToast("Fence style updated successfully", 'success');
                callback && callback()
            } else {
                showToast(response.payload.error.response.data.message, 'error');
                setIsUpdating(false);
            }
        } catch (error) {
            showToast(error.toString(), 'error');
            setIsUpdating(false);
        }
    };

    const handleSave = async (styleDataJson,fenceImage,callback) => {
        try {
            setIsSaving(true);
            const formData = new FormData();
            formData.append("styleData", styleDataJson);

            if (fenceImage) {
                let image = ''
                if (fenceImage instanceof Blob) {
                    formData.append('fenceImage', fenceImage, fenceImage.name);
                }else {
                    image = fenceImage
                }
                image && formData.append('image',image)
            }
            const response = await dispatch(addMyFenceStyle(formData));
            if (response?.meta?.requestStatus === "fulfilled") {
                showToast("Fence style added successfully", 'success');
                callback && callback()

            } else {
                showToast(response.payload.error, 'error');
                setIsSaving(false);
            }
        } catch (error) {
            showToast(error.toString(), 'error');
            setIsSaving(false);
        }
    };

    const handleEditComponent = (component) => {
        setEditMode(true);
        setSelectedItem(fencesStyles?.find(c=>c._id === component?._id) || null);
        setIsModalOpen(true)

    }
    const modifiedHandleDeleteComponent = (component) => {
        setSelectedItem(fencesStyles?.find(c=>c._id === component?._id) || null);
        setIsDeleteModalOpen(true)
    };


    const handleSearch = (searchTerm) => {
        const styles = [...fencesStyles]
        const filteredStyles = !searchTerm ? styles : styles.filter(c => {
            // Assuming you want to match the search term case-insensitively from the start
            // of the component name
            return c.style.toLowerCase().startsWith(searchTerm.toLowerCase());
        });
        setSelectedCateStyles(filteredStyles);
    }

    function closeStates(){
        setIsSaving(false);
        setIsUpdating(false);
    }

    function handleCloseModal(){
        closeStates();
        setEditMode(false);
        setIsModalOpen(false);
        setSelectedItem(null);
    }

    return (
        <div className="h-full">
            <Navbar
                heading={"My fence styles"}
                linkText={"fence-styles"}
                buttonText={"Add New Fence"}
                handleButtonClicked={() => setIsModalOpen(true)}
            />
            <div className="flex justify-between items-center">
                <SearchComponent onChange={handleSearch} placeholder="Search by style name"/>
                <div className="flex">
                    <MyDropdown
                        items={fenceTypeList}
                        selectedValue={selectedType?.type || ''}
                        onSelectItem={(e) => setSelectedType(fenceTypeList[e.target.selectedIndex])}
                        labelKey="type"
                        placeholder="No Type Found"
                        title={"Filter by fence type:"}

                    />
                </div>
            </div>
            <div style={{height:'calc(100% - 90px)'}} className="overflow-y-auto relative">
                {loadingSpinner && <MySpinner withBg={false} customClasses="absolute z-10 top-[10%] w-full"/>}
                <MyFenceStylesTable
                    columns={FENCE_STYLES_TABLE_COLUMNS}
                    data={selectedCateStyles} // Adjust this if necessary to match your data structure
                    onUpdate={handleEditComponent}
                    onDelete={modifiedHandleDeleteComponent}
                />
            </div>
            <Suspense fallback={<div>Loading...</div>}>
                {
                    isModalOpen && <FenceBuilderModal
                        isOpen={isModalOpen}
                        editMode={editMode}
                        onClose={handleCloseModal}
                        selectedStyleItem={selectedItem}
                        isSaving={isSaving}
                        isUpdating={isUpdating}
                        onSave={handleSave}
                        onUpdate={handleUpdate}
                        closeStates={closeStates}
                    />
                }
                {
                    isDeleteModalOpen && <DeleteConfirmationModal
                        isOpen={isDeleteModalOpen}
                        loading={isDeleting}
                        onClose={() => setIsDeleteModalOpen(false)}
                        onDelete={() => handleDelete(selectedItem?._id)}
                    />
                }
            </Suspense>

        </div>
    );
};

export default MyFenceStyles;
