import React, { useState, useRef, useEffect } from 'react';

const AddTypeModal = ({ isOpen, onClose, editMode, onSave, isSaving, selectedItem }) => {
    const [typeData, setTypeData] = useState({
        _id: selectedItem?._id || '',
        type: selectedItem?.type || '',
        abbreviation: selectedItem?.abbreviation || ''  // Added abbreviation field
    });

    const modalRef = useRef(null);

    useEffect(() => {
        if (selectedItem) {
            setTypeData({
                _id: selectedItem._id || '',
                type: selectedItem.type || '',
                abbreviation: selectedItem.abbreviation || ''  // Set abbreviation from selectedItem
            });
        }
    }, [selectedItem]);


    const handleSubmit = () => {
        onSave(typeData);
        setTypeData({_id: '', type: '', abbreviation: ''});  // Reset abbreviation field on submit
        onClose();
    };

    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 z-50 overflow-auto bg-smoke-500">
            <div className="flex items-start justify-center min-h-screen">
                <div
                    ref={modalRef}
                    className="relative max-w-lg w-[350px] p-10 top-[100px] left-[50px] bg-white rounded shadow-lg cursor-grab"
                >
                    <div className="flex items-center justify-between">
                        <h2 className="text-lg font-bold">{editMode ? "Update " : "Add New"} Type</h2>
                        <button onClick={onClose} className="text-2xl font-semibold">&times;</button>
                    </div>
                    <div className="mt-4">
                        <input
                            type="text"
                            placeholder="Enter new type"
                            value={typeData.type}
                            onChange={(e) => setTypeData({ ...typeData, type: e.target.value })}
                            className="w-full px-3 py-2 border rounded"
                        />
                        <input
                            type="text"
                            placeholder="Abbreviation (e.g.Chainlink = CL)"
                            value={typeData.abbreviation}
                            onChange={(e) => setTypeData({ ...typeData, abbreviation: e.target.value })}
                            className="w-full px-3 py-2 mt-2 border rounded"
                        />
                    </div>
                    <div className="flex justify-center mt-4 space-x-2">
                        <button
                            disabled={isSaving}
                            onClick={handleSubmit}
                            className="disabled:opacity-50 px-4 py-2 text-white bg-blue-500 rounded hover:bg-blue-600"
                        >
                            {editMode ? "Update " : "Add"} Type
                        </button>
                        <button
                            onClick={onClose}
                            className="px-4 py-2 text-gray-700 border border-gray-300 rounded hover:bg-gray-100"
                        >
                            Cancel
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AddTypeModal;
