import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import UserTable from "../tables/components/UserTable";
import {fetchUsers} from "../../../redux/reducers/users/userSilce";
import IconButton from "../../common-comps/tailwind-css/buttons/IconButton";
import {MdOutlineAdd} from "react-icons/md";
import AddUserModal from "./AddUserModal";
import Navbar from "../../../components/navbar";

const Users = () => {
    const dispatch = useDispatch();
    const userData = useSelector((state) => state?.userDetail?.data);

    const [shouldRefreshTable, setShouldRefreshTable] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);

    useEffect(() => {
        dispatch(fetchUsers());
        setShouldRefreshTable(false);
    }, [dispatch, shouldRefreshTable]);

    return (
        <div>
            <Navbar
                heading={"Users"}
                linkText={"users"}
                buttonText={"Add User"}
                handleButtonClicked={() => setIsModalOpen(true)}
            />
            <div className="h-full ">
                <UserTable
                    tableData={userData}
                    shouldRefetchTable={shouldRefreshTable}
                    setShouldRefetchTable={setShouldRefreshTable}
                />
            </div>
            <AddUserModal
                isOpen={isModalOpen}
                onClose={() => setIsModalOpen(false)}
            />
        </div>
    );
};

export default Users;
