import React, {useCallback, useEffect, useRef, useState} from 'react';
import * as XLSX from 'xlsx';
import { useDispatch, useSelector } from "react-redux";
import { fetchFenceComponentCategories } from "../../../../../redux/reducers/fence/fence-component-categories/fenceComponentCategoriesSlice";
import {fetchVendors} from "../../../../../redux/reducers/vendor/vendorSlice";
import MyButton from "../../../../common-comps/MyButton";
import MappingHeadersModal from "./MappingHeadersModal";

const CsvDataSheet = () => {
    const dispatch = useDispatch();
    const categories = useSelector(state => state.fenceComponentCategories.allCategories.categories);
    const vendors = useSelector((state) => state.fenceVendors.data);

    const uploadComponentsCsvRef = useRef(null);
    const [csvAllSheetsData, setCsvAllSheetsData] = useState([]);
    const [uploadedFileName, setUploadedFileName] = useState('');

    const [showMappingModal, setShowMappingModal] = useState(false);

    useEffect(() => {
        dispatch(fetchVendors());
        dispatch(fetchFenceComponentCategories());
    }, [dispatch]);

    const handleFileChange = useCallback((event) => {
        const file = event.target.files[0];
        setUploadedFileName(file?.name || '');
        if (!file) return;

        const reader = new FileReader();
        reader.onload = (e) => {
            let data;
            const fileType = file.name.split('.').pop();

            if (fileType === 'csv') {
                data = e.target.result;
                const parsedData = XLSX.read(data, { type: 'binary' });
                const sheetName = parsedData.SheetNames[0];
                const jsonData = XLSX.utils.sheet_to_json(parsedData.Sheets[sheetName], { defval: "" });
                setCsvAllSheetsData([{ categoryName: sheetName, data: jsonData }]);
            } else if (fileType === 'xlsx') {
                const workbook = XLSX.read(e.target.result, { type: 'binary' });



                const sheets = workbook.SheetNames.map((sheetName) => {
                    const formattedSheetName = sheetName.replace(/^[A-Z] - /, '');
                    return {
                        sheetName: formattedSheetName,
                        data: XLSX.utils.sheet_to_json(workbook.Sheets[sheetName], { defval: "" })
                    };
                });
                setCsvAllSheetsData(sheets);


                //show Mapping Modal
                setShowMappingModal(true)
            }
        };

        reader.readAsBinaryString(file);
    }, [categories,vendors]); // Dependencies include categories to re-create function when categories change

    return (
        <>
            <div className="flex flex-col">
                <div className="flex justify-start items-center">
                    <div>
                        <div>
                            <MyButton
                                text={'Click to Upload Csv File'}
                                textSize={'xSmall'}
                                size="small"
                                color="blue"
                                onClick={() => uploadComponentsCsvRef.current.click()}/>
                            <span className="text-gray-700 text-xs">{uploadedFileName}</span>
                        </div>
                        <input ref={uploadComponentsCsvRef} className="hidden" type="file" accept=".xlsx" onChange={handleFileChange} />
                    </div>
                </div>
            </div>
            {
                showMappingModal && (
                    <MappingHeadersModal
                        categories={categories}
                        sheets={csvAllSheetsData}
                        onCancel={()=>setShowMappingModal(false)}
                    />
                )
            }
        </>
    );
};

export default CsvDataSheet;
