import React from "react";
import {FormControl, FormLabel } from "@chakra-ui/react";
import {AiOutlineQuestionCircle} from "react-icons/ai";
import IconButton from "../../../../../common-comps/tailwind-css/buttons/IconButton";

function PostInstallationMethod({ installationMethods, handleInstallationMethodChange, onOpenNote, handleFieldValueChange, addNewField }) {
    return (
        <div className="flex flex-col md:flex-row md:space-x-4">
            <FormControl className="flex-1">
                <FormLabel className="font-bold ">Post installation methods</FormLabel>
                <div className="flex flex-col md:flex-row md:space-x-4">
                    <div className="flex-1">
                        <div className="flex flex-wrap justify-between -mx-1.4 mt-3">
                            <div className="w-1/3">
                                <div className="flex items-center mb-2">
                                    <input
                                        type="checkbox"
                                        id="digHoles"
                                        checked={installationMethods.digHoles.active}
                                        onChange={() => handleInstallationMethodChange('digHoles')}
                                        className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded cursor-pointer"
                                    />
                                    <label htmlFor="digHoles" className="ml-2 block text-sm text-gray-900">Dig Holes / Pour Concrete</label>
                                    {installationMethods.digHoles.active && <span className="ml-4" onClick={onOpenNote}>
                                                        <AiOutlineQuestionCircle className=" cursor-help" />
                                                    </span>}

                                </div>
                                {installationMethods.digHoles.active && (
                                    <div className="space-y-2">
                                        {installationMethods.digHoles.fields.map((field, index) => {
                                            if (field.dependsOn && !installationMethods.digHoles.fields.find(f => f.id === field.dependsOn)?.checked) return null;
                                            return (
                                                <div key={index} className="flex items-center">
                                                    {
                                                        field.type === "checkbox" ? (
                                                            <>
                                                                <input
                                                                    type="checkbox"
                                                                    id={field.id}
                                                                    checked={field.checked}
                                                                    onChange={(e) => handleFieldValueChange('digHoles', index, e.target.checked, true)}
                                                                    className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
                                                                />
                                                                <label htmlFor={field.id} className="block mx-2 whitespace-nowrap text-sm font-smaller text-gray-700">{field.label}</label>
                                                            </>
                                                        ) : field.dependsOn ? installationMethods.digHoles.fields.find(f => f.id === field.dependsOn).checked && (
                                                            <>
                                                                <input
                                                                    type="text"
                                                                    id={field.id}
                                                                    value={field.value}
                                                                    placeholder={field.placeholder}
                                                                    onChange={(e) => handleFieldValueChange('digHoles', index, e.target.value)}
                                                                    className="block w-[20%] px-2 py-1 border border-gray-300 rounded text-sm"
                                                                />
                                                                <label htmlFor={field.id} className="block mx-2 whitespace-nowrap text-sm font-smaller text-gray-700">{field.label}</label>
                                                            </>

                                                        ) : (
                                                            <>
                                                                <input
                                                                    type="text"
                                                                    id={field.id}
                                                                    value={field.value}
                                                                    placeholder={field.placeholder}
                                                                    onChange={(e) => handleFieldValueChange('digHoles', index, e.target.value)}
                                                                    className="block w-[20%] px-2 py-1 border border-gray-300 rounded text-sm"
                                                                />
                                                                <label htmlFor={field.id} className="block mx-2 whitespace-nowrap text-sm font-smaller text-gray-700">{field.label}</label>

                                                            </>

                                                        )
                                                    }
                                                </div>
                                            )
                                        })}
                                        <IconButton text="Add New Field"
                                                    onClick={() => addNewField('digHoles')}
                                                    outlined={true} customClasses={"mt-1 text-xs bg-gray-700 text-white border-gray-700 hover:border-gray-600 hover:bg-gray-600 px-1.5 py-1"}/>
                                    </div>
                                )}
                            </div>
                            <div className="w-1/3">
                                <div className="flex items-center mb-2">
                                    <input
                                        type="checkbox"
                                        id="platedPosts"
                                        checked={installationMethods.platedPosts.active}
                                        onChange={() => handleInstallationMethodChange('platedPosts')}
                                        className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded cursor-pointer"
                                    />
                                    <label htmlFor="platedPosts" className="ml-2 block text-sm text-gray-900">Plated Posts</label>
                                    {installationMethods.platedPosts.active && <span className="ml-4" onClick={onOpenNote}>
                                                        <AiOutlineQuestionCircle className=" cursor-help" />
                                                    </span>}
                                </div>
                                {installationMethods.platedPosts.active && (
                                    <div className="space-y-2">
                                        {installationMethods.platedPosts.fields.map((field, index) => {
                                            if (field.dependsOn && !installationMethods.platedPosts.fields.find(f => f.id === field.dependsOn)?.checked) return null;
                                            return (
                                                <div key={index} className="flex items-center">
                                                    {
                                                        field.type === "checkbox" ? (
                                                            <>
                                                                <input
                                                                    type="checkbox"
                                                                    id={field.id}
                                                                    checked={field.checked}
                                                                    onChange={(e) => handleFieldValueChange('platedPosts', index, e.target.checked, true)}
                                                                    className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
                                                                />
                                                                <label htmlFor={field.id} className="block mx-2 whitespace-nowrap text-sm font-smaller text-gray-700">{field.label}</label>
                                                            </>
                                                        ) : field.dependsOn ? installationMethods.platedPosts.fields.find(f => f.id === field.dependsOn)?.checked && (
                                                            <>
                                                                <input
                                                                    type="text"
                                                                    id={field.id}
                                                                    value={field.value}
                                                                    placeholder={field.placeholder}
                                                                    onChange={(e) => handleFieldValueChange('platedPosts', index, e.target.value)}
                                                                    className="block w-[20%] px-2 py-1 border border-gray-300 rounded text-sm"
                                                                />
                                                                <label htmlFor={field.id} className="block mx-2 whitespace-nowrap text-sm font-smaller text-gray-700">{field.label}</label>
                                                            </>

                                                        ) : (
                                                            <>
                                                                <input
                                                                    type="text"
                                                                    id={field.id}
                                                                    value={field.value}
                                                                    placeholder={field.placeholder}
                                                                    onChange={(e) => handleFieldValueChange('platedPosts', index, e.target.value)}
                                                                    className="block w-[20%] px-2 py-1 border border-gray-300 rounded text-sm"
                                                                />
                                                                <label htmlFor={field.id} className="block mx-2 whitespace-nowrap text-sm font-smaller text-gray-700">{field.label}</label>

                                                            </>

                                                        )
                                                    }
                                                </div>
                                            )
                                        })}
                                        <IconButton text="Add New Field"
                                                    onClick={() => addNewField('platedPosts')}
                                                    outlined={true} customClasses={"mt-2 text-sm bg-gray-700 text-white border-gray-700 hover:border-gray-600 hover:bg-gray-600 px-1.5 py-1.5"}/>

                                    </div>
                                )}
                            </div>
                            <div className="w-1/3">
                                <div className="flex items-center mb-2">
                                    <input
                                        type="checkbox"
                                        id="platedPosts"
                                        checked={installationMethods.drivePosts.active}
                                        onChange={() => handleInstallationMethodChange('drivePosts')}
                                        className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded cursor-pointer"
                                    />
                                    <label htmlFor="platedPosts" className="ml-2 block text-sm text-gray-900">Drive Posts</label>
                                    {installationMethods.drivePosts.active && <span className="ml-4" onClick={onOpenNote}>
                                                        <AiOutlineQuestionCircle className=" cursor-help" />
                                                    </span>}
                                </div>
                                {installationMethods.drivePosts.active && (
                                    <div className="space-y-2">
                                        {installationMethods.drivePosts.fields.map((field, index) => {
                                            if (field.dependsOn && !installationMethods.drivePosts.fields.find(f => f.id === field.dependsOn)?.checked) return null;
                                            return (
                                                <div key={index} className="flex items-center">
                                                    {
                                                        field.type === "checkbox" ? (
                                                            <>
                                                                <input
                                                                    type="checkbox"
                                                                    id={field.id}
                                                                    checked={field.checked}
                                                                    onChange={(e) => handleFieldValueChange('drivePosts', index, e.target.checked, true)}
                                                                    className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
                                                                />
                                                                <label htmlFor={field.id} className="block mx-2 whitespace-nowrap text-sm font-smaller text-gray-700">{field.label}</label>
                                                            </>
                                                        ) : field.dependsOn ? installationMethods.drivePosts.fields.find(f => f.id === field.dependsOn)?.checked && (
                                                            <>
                                                                <input
                                                                    type="text"
                                                                    id={field.id}
                                                                    value={field.value}
                                                                    placeholder={field.placeholder}
                                                                    onChange={(e) => handleFieldValueChange('drivePosts', index, e.target.value)}
                                                                    className="block w-[20%] px-2 py-1 border border-gray-300 rounded text-sm"
                                                                />
                                                                <label htmlFor={field.id} className="block mx-2 whitespace-nowrap text-sm font-smaller text-gray-700">{field.label}</label>
                                                            </>

                                                        ) : (
                                                            <>
                                                                <input
                                                                    type="text"
                                                                    id={field.id}
                                                                    value={field.value}
                                                                    placeholder={field.placeholder}
                                                                    onChange={(e) => handleFieldValueChange('drivePosts', index, e.target.value)}
                                                                    className="block w-[20%] px-2 py-1 border border-gray-300 rounded text-sm"
                                                                />
                                                                <label htmlFor={field.id} className="block mx-2 whitespace-nowrap text-sm font-smaller text-gray-700">{field.label}</label>

                                                            </>

                                                        )
                                                    }
                                                </div>
                                            )
                                        })}
                                        <IconButton text="Add New Field"
                                                    onClick={() => addNewField('drivePosts')}
                                                    outlined={true} customClasses={"mt-2 text-sm bg-gray-700 text-white border-gray-700 hover:border-gray-600 hover:bg-gray-600 px-1.5 py-1.5"}/>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </FormControl>
        </div>
    );
}

export default PostInstallationMethod;
