import React, { useMemo } from "react";
import { useTable } from "react-table";
import Card from "components/card";
import { FaEdit } from "react-icons/fa";
import { MdDelete } from "react-icons/md";

const CategoriesTable = ({ categoriesData, onUpdate, onDelete }) => {
    const data = useMemo(() => categoriesData.map((item, index) => ({
        ...item,
        index: index + 1,  // Adding an index property
        type: item.fenceType ? `${item.fenceType.type} (${item.fenceType.abbreviation})` : 'No Type'  // Handling fenceType object

    })), [categoriesData]);

    const columns = useMemo(() => [
        {
            Header: 'Category',
            accessor: 'categoryName', // Accessor for categoryName
        },
        {
            Header: 'Abbreviation',
            accessor: 'abbreviation', // Accessor for abbreviation
        },
        {
            Header: 'Fence Type',
            accessor: 'type', // Accessor for fenceType
        },
        {
            Header: 'Actions',
            accessor: 'actions',
            Cell: ({ row }) => (
                <div className="flex justify-center gap-2">
                    <button onClick={() => onUpdate(row.original)}>
                        <FaEdit className="text-lg text-[#3b82f6]" />
                    </button>
                    <button onClick={() => onDelete(row.original)}>
                        <MdDelete className="text-[20px] text-[#FF0000]" />
                    </button>
                </div>
            ),
        }
    ], [onDelete, onUpdate]);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = useTable({ columns, data });

    return (
        <Card extra="w-full h-full p-4">
            <table {...getTableProps()} className="w-full">
                <thead>
                {headerGroups.map(headerGroup => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                        <th className="border text-XS border-gray-200 px-3 py-2 text-center dark:border-navy-700 w-[10%]">
                            No
                        </th>
                        {headerGroup.headers.map(column => (
                            <th {...column.getHeaderProps()} className="border text-xs border-gray-200 px-3 py-2 text-center dark:border-navy-700">
                                {column.render('Header')}
                            </th>
                        ))}
                    </tr>
                ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                {rows.map((row, i) => {
                    prepareRow(row);
                    return (
                        <tr {...row.getRowProps()}>
                            <td className="p-3 border text-sm border-gray-200 text-center">
                                {i + 1}
                            </td>
                            {row.cells.map(cell => (
                                <td {...cell.getCellProps()} className="p-3 border text-sm border-gray-200 text-center">
                                    {cell.render('Cell')}
                                </td>
                            ))}
                        </tr>
                    );
                })}
                </tbody>
            </table>
        </Card>
    );
};

export default CategoriesTable;
