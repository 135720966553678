import React, {useEffect, useState} from 'react';
import { FormControl, FormLabel } from '@chakra-ui/react';
import {useSelector} from "react-redux";

const StyleAndTypeComponent = ({setSelectedFenceStyle, setSelectedFenceType,selectedItem}) => {
    const fencesTypes = useSelector((state) => state?.fenceTypes?.data);

    const [fenceStyle, setFenceStyle] = useState(selectedItem?.style || '');
    const [fenceType, setFenceType] = useState(selectedItem?.type || '');

    useEffect(()=>{
        setFenceStyle(selectedItem ? selectedItem.style : null)
        setFenceType(selectedItem ? selectedItem?.fenceType : null)
    },[selectedItem])


    useEffect(() => {
        if (fencesTypes?.length && !selectedItem) {
            setFenceType(fencesTypes[0]);
        }
    }, [fencesTypes]);


    useEffect(()=>{
        setSelectedFenceStyle(fenceStyle || '')
    },[fenceStyle])
    useEffect(()=>{
        setSelectedFenceType(fenceType)
    },[fenceType])
    return (
        <div className="w-full flex flex-col md:flex-row md:space-x-4">
            <FormControl className="w-1/2 flex-1" mt={4}>
                <FormLabel className="text-purple-700">Enter Style</FormLabel>
                <input
                    className="w-full rounded border text-sm focus:outline-none focus:border-blue-500 border-solid border-purple-700 px-2 py-1 dark:border-[#202848] dark:bg-[#111c44]"
                    placeholder="Enter Fence Style"
                    value={fenceStyle}
                    onChange={(e) => setFenceStyle(e.target.value)}
                />
            </FormControl>
            <FormControl className="w-1/3">
                <FormLabel>Fence Type</FormLabel>
                <select
                    className="w-full mt-1 px-2 py-1 border border-gray-500 rounded cursor-pointer"
                    value={fenceType?.type}
                    onChange={(e) => setFenceType(fencesTypes[e.target.selectedIndex])}
                >
                    {fencesTypes.map((type) => (
                        <option key={type._id} value={type.type}>{type.type}</option>
                    ))}
                </select>
            </FormControl>
        </div>
    );
};

export default StyleAndTypeComponent;
