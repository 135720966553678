import React, {useState} from "react";
import {getSizeOfComponent} from "../admin/fence/fences/fence-builder/addFenceUtils";

export const FENCE_COMPONENT_COLUMNS = [
    { Header: "Fence type", accessors: ["fenceType"], type:'text', checked: true,show:false },
    { Header: "Component Name", accessors: ["name"], type:'text', checked: true, show:true },
    { Header: "Category", accessors: ["category"], type:'text', checked: true, show:true },
    { Header: "Collection", accessors: ["componentCollection"], type:'text', checked: true, show:true },
    { Header: "Description", accessors: ["description"], type:'text', checked: true, show:true },
    { Header: "Size 1 / Post Diameter (inches)", accessors: ["size1"], checked: true },
    { Header: "Size 2 / Wall / Rail Diameter (inches)", accessors: ["size2"], checked: true },
    { Header: "Size 3 / Height / Length (inches)", accessors: ["size3"], checked: true },
    { Header: "System Number", accessors: ["systemNumber"],type:'text', checked: true, show: true },
    { Header: "Primary Vendor Part Number", accessors: ["primaryVendorPartNumber"],type:'number', checked: true },
    { Header: "Full Bundle/Case/Pallet", accessors: ["fullBundleCasePallet"], checked: true },
    { Header: "Sold In Increments Of", accessors: ["soldInIncrementsOf"],type:'number', checked: true },
    { Header: "Unit of Measure", accessors: ["unitOfMeasure"],type:'text', checked: true },
    { Header: "Weight", accessors: ["weight"],type:'number', checked: true },
    { Header: "Price", accessors: ["price"],type:'number', checked: true, show:true },
    { Header: "Color", accessors: ["color"],type:'text', checked: true, show:true },
    { Header: "Color Abbreviation", accessors: ["colorAbbreviation"], checked: true },
    { Header: "Images", accessors: ["images"], checked: true, show: true },
    { Header: "Primary Vendor", accessors: ["primaryVendor"], checked: true },
    { Header: "Cost", accessors: ["cost"],type:'number', checked: true },
    { Header: "Brand/Manufacturer", accessors: ["brandManufacturer"], checked: true },
    { Header: "SKU", accessors: ["sku"], checked: true },
    { Header: "Part Number", accessors: ["partNumber"], checked: true,show:false },
    { Header: "UPC Barcode", accessors: ["upcBarcode"], checked: true },
    { Header: "MPN", accessors: ["mpn"], checked: true },
    { Header: "EAN-13 or JAN Barcode", accessors: ["ean13OrJanBarcode"], checked: true },
    { Header: "Reference Number", accessors: ["referenceNumber"],type:'number', checked: true },
    { Header: "Active", accessors: ["active"], checked: true, show:true },
];

export const FENCE_STYLES_TABLE_COLUMNS = [
    { Header: "Style", accessor: "style", type: 'text', show: true },
    { Header: "Heights (ft)", accessor: "heights", type: 'number', show: true },
    { Header: "Colors", accessor: "colors", type: 'text', show: true },
    { Header: "Installation Methods", accessor: "installationMethods", type: 'text', show: true },
    { Header: "Fence Type", accessor: "fenceType", type: 'text', show: true },
    { Header: "Image", accessor: "image", type: 'text', show: true },
    // { Header: "Post Material", accessor: "material.posts", type: 'text', show: false },
    // { Header: "Mesh Material", accessor: "material.mesh", type: 'text', show: false },  // Optional
    // { Header: "Hookups Material", accessor: "material.hookups", type: 'text', show: false } // Optional
];

export const postInstallationMethodIds = {
    digHolesPourConcrete:"digHolesPourConcrete",
    surfaceMountPosts:"surfaceMountPosts",
    drivePosts:"drivePosts"}




const COMMON_POST_OPTIONS = [
    { label: "Terminal Posts Extra Length (ft)", value: "", placeholder: "e.g., 2.5", id: "terminalPostLength" },
    { label: "Gate Posts Extra Length (ft)", value: "", placeholder: "e.g., 2.5", id: "gatePostLength" },
    { label: "Line Posts Extra Length (ft)", value: "", placeholder: "e.g., 2", id: "linePostLength" },
];

export const POST_INSTALLATION_METHODS = [
    {
        name: "Dig Holes / Pour Concrete",
        id: postInstallationMethodIds.digHolesPourConcrete,
        active: false,
        data: [
            ...COMMON_POST_OPTIONS,
            {
                label: "Excavate holes",
                value: false,
                id: "excavateHoles",
                type: 'checkbox',
                children: [
                    {
                        label: "Hole Diameter (inch)",
                        value: "",
                        placeholder: "e.g., 8",
                        id: "holeDiameter",
                        type: "text",
                        dependsOn: "excavateHoles"
                    },
                    {
                        label: "Hole Depth (inch)",
                        value: "",
                        placeholder: "e.g., 30",
                        id: "holeDepth",
                        type: "text",
                        dependsOn: "excavateHoles"
                    },
                ]
            },
            {
                label: "Set Posts with Concrete",
                value: false,
                placeholder: "Enter value",
                id: "setPostsWithConcrete",
                type: 'checkbox'
            }
        ]
    },
    { name: "Surface Mount Posts",
        id: postInstallationMethodIds.surfaceMountPosts,
        active: false, data: [...COMMON_POST_OPTIONS] },
    { name: "Drive Posts",
        id: postInstallationMethodIds.drivePosts,
        active: false, data: [...COMMON_POST_OPTIONS] },
];

export const COMPS_LOGIC = {
    ENABLED_SIZE_1:'Size 1 / Post Diameter / Min opening',
    ENABLED_SIZE_2:'Size 2 / Wall / Rail Diameter / max opening',
    ENABLED_SIZE_3:'Size 3 / Height / Length',
    SELECT_SPECIFIC_COMPONENT:'Select Specific Component',
    GET_COMPONENTS_BY_RANGE:'GET_COMPONENTS_BY_RANGE'

}
export const COMP_DEPENDENCIES = {
    fenceHeight: "Fence Height",
    fenceColor: "Fence Color",
    terminalPostDiameter: "Terminal Post Diameter",
    gatePostDiameter: "Gate Post Diameter",
    linePostDiameter: "Line Post Diameter",
    topRailDiameter: "Top Rail Diameter",
    minimumOpening: "Minimum Opening",
    maximumOpening: "Maximum Opening"
};


export const POST_COMPS = [
    {
        name: 'Post',
        id:'post',
        dependencies:{fenceColor:true,fenceHeight:true},
        logics: [COMPS_LOGIC.ENABLED_SIZE_1]
    },
    {
        name: 'Post Cap',
        id:'postCap',
        dependencies:{fenceColor:true},
        logics: [COMPS_LOGIC.ENABLED_SIZE_1]
    },
]
export const LINE_POST_COMPS = [
    {
        name: 'Line Post',id:'linePost',
        dependencies:{fenceHeight:true,fenceColor:true},
        logics: [COMPS_LOGIC.ENABLED_SIZE_1]
    },
    {
        name: 'Line Post Top',
        id:'linePostTop',
        dependencies:{fenceColor:true},
        logics: [COMPS_LOGIC.ENABLED_SIZE_1]
    },
    {
        name: 'Line Ties',
        id:'lineTies',
        dependencies:{fenceColor:true},
        logics: [COMPS_LOGIC.ENABLED_SIZE_1,COMPS_LOGIC.ENABLED_SIZE_2]
    },
]
export const SEC_COMPS = [
    {
        name: 'ChainLink Mesh',
        id:'chainLinkMesh',
        dependencies:{fenceHeight:true,fenceColor:true},
    },
    {
        name: 'Tension Wire' ,
        id:'tensionWire',
        dependencies:{fenceColor:true}
    },
    {
        name: 'Top Rail',
        id:'topRail',
        dependencies:{fenceColor:true},
        logics:[COMPS_LOGIC.ENABLED_SIZE_1,COMPS_LOGIC.ENABLED_SIZE_3]
    },
    {
        name: 'Top Rail Ties',
        id:'lineRailTies',
        dependencies:{fenceColor:true},
        logics:[COMPS_LOGIC.ENABLED_SIZE_1]
    },
    {
        name: 'Barb Wire' ,
        id:'barbWire',
        logics:[COMPS_LOGIC.SELECT_SPECIFIC_COMPONENT]
    },
    {
        name: 'Hog Rings' ,
        id:'hogRings',
        logics:[COMPS_LOGIC.SELECT_SPECIFIC_COMPONENT]

    },
]
export const HOOKUPS_COMPS = [
    {
        name: 'Tension Band',
        id:'tensionBand',
        dependencies:{fenceColor:true,terminalPostDiameter:true},
        logics:[COMPS_LOGIC.ENABLED_SIZE_1]
    },
    {
        name: 'Brace Band',
        id:'braceBand',
        dependencies:{fenceColor:true},
        logics:[COMPS_LOGIC.ENABLED_SIZE_1]
    },
    {
        name: 'Rail End',
        id:'railEnd',
        dependencies:{fenceColor:true},
        logics:[COMPS_LOGIC.ENABLED_SIZE_1]
    },
    {
        name: 'Rail End Combo Cup',
        id:'railEndComboCup',
        dependencies:{fenceColor:true},
        logics:[COMPS_LOGIC.ENABLED_SIZE_1]
    },
    {
        name: 'Tension Bar',
        id:'tensionBar',
        dependencies:{fenceColor:true, fenceHeight:true},
    },
    {
        name: 'Nuts and Bolts',
        id:'nutsAndBolts',
        logics:[COMPS_LOGIC.SELECT_SPECIFIC_COMPONENT]
    },
    {
        name: 'Truss Rod',
        id:'trussRod',
        dependencies:{fenceColor:true},
        logics:[COMPS_LOGIC.ENABLED_SIZE_1]

    },
    {
        name: 'Truss Rod Tightener',
        id:'trussRodTightener',
        dependencies:{fenceColor:true},
        logics:[COMPS_LOGIC.ENABLED_SIZE_1]

    },
    {
        name: 'Brace Rail',
        id:'braceRail',
        dependencies:{fenceColor:true},
        logics:[COMPS_LOGIC.ENABLED_SIZE_1]

    },
]

const gateLogic = [COMPS_LOGIC.ENABLED_SIZE_1,COMPS_LOGIC.ENABLED_SIZE_2,COMPS_LOGIC.GET_COMPONENTS_BY_RANGE]
export const FENCE_GATES = [
    { name: 'Single Swing Gate',
        id: 'singleSwingGate',
        dependencies: { fenceColor: true, fenceHeight: true },
        logics: [...gateLogic] },
    { name: 'Double Swing Gate', id: 'doubleSwingGate', dependencies: { fenceColor: true, fenceHeight: true }, logics: [...gateLogic] },
    { name: 'Industrial Single Swing', id: 'industrialSingleSwing', dependencies: { fenceColor: true, fenceHeight: true }, logics: [...gateLogic] },
    { name: 'Industrial Double Swing', id: 'industrialDoubleSwing', dependencies: { fenceColor: true, fenceHeight: true }, logics: [...gateLogic] },
    { name: 'Cantilever Slide Gate', id: 'cantileverSlideGate', dependencies: { fenceColor: true, fenceHeight: true }, logics: [...gateLogic] },
    { name: 'Roll Gate', id: 'rollGate', dependencies: { fenceColor: true, fenceHeight: true }, logics: [...gateLogic] }
];



// for calculation quantity of the component (formula)

export const QUANTITY_OPS = {
    fenceHeight:"Fence Height",
    totalFenceLength:"Total Fence Length",
    topRailLength:"Top Rail Length",
    totalHookups:"Total Hookups",
}