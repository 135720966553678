import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../../Api";

const initialState = {
    data: [],
    status: "idle",
    isLoading: false,
    error: null,
};

// Create an async thunk for fetching fence styles
export const fetchFenceStyles = createAsyncThunk(
    "fence/fetchStyles",
    async (_, thunkAPI) => {
        try {
            const response = await api.get(
                `${process.env.REACT_APP_API_URL}/api/fence/getFenceStyles`
            );
            return response.data.data;
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: error.message });
        }
    }
);

// Create an async thunk for adding a new fence style
export const addFenceStyle = createAsyncThunk(
    "fence/addFenceStyle",
    async (styleData, { rejectWithValue }) => {
        try {
            const response = await api.post(
                `${process.env.REACT_APP_API_URL}/api/fence/addFenceStyle`,
                styleData
            );
            if (response.data.success) {
                return response.data.data;
            } else {
                return rejectWithValue({
                    error: response.data.message || "Failed to add style",
                });
            }
        } catch (error) {
            console.error(error, "Error while adding style");
            return rejectWithValue({ error: error.message });
        }
    }
);

// Create an async thunk for updating a fence style
export const updateFenceStyle = createAsyncThunk(
    "fence/updateFenceStyle",
    async (styleData, thunkAPI) => {
        try {
            const response = await api.put(
                `${process.env.REACT_APP_API_URL}/api/fence/updateFenceStyle`,
                styleData
            );
            return response.data.data;
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: error.message });
        }
    }
);

// Create an async thunk for deleting a fence style
export const deleteFenceStyle = createAsyncThunk(
    "fence/deleteFenceStyle",
    async (id, thunkAPI) => {
        try {
            await api.delete(
                `${process.env.REACT_APP_API_URL}/api/fence/deleteFenceStyle?id=${id}`
            );
            return id;
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: error.message });
        }
    }
);

const stylesSlice = createSlice({
    name: "styles",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchFenceStyles.pending, (state) => {
                state.status = "loading";
            })
            .addCase(fetchFenceStyles.fulfilled, (state, action) => {
                state.status = "succeeded";
                state.data = action.payload;
            })
            .addCase(fetchFenceStyles.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.error.message;
            })
            .addCase(addFenceStyle.pending, (state) => {
                state.isLoading = true;
                state.status = "loading";
            })
            .addCase(addFenceStyle.fulfilled, (state, action) => {
                state.status = "succeeded";
                state.isLoading = false;
                state.data.push(action.payload);
            })
            .addCase(addFenceStyle.rejected, (state, action) => {
                state.isLoading = false;
                state.status = "failed";
                state.error = action.error.message;
            })
            .addCase(updateFenceStyle.pending, (state) => {
                state.isLoading = true;
                state.status = "loading";
            })
            .addCase(updateFenceStyle.fulfilled, (state, action) => {
                state.status = "succeeded";
                state.isLoading = false;
                const updatedStyle = action.payload;
                const index = state.data.findIndex(
                    (style) => style._id === updatedStyle._id
                );
                if (index !== -1) {
                    state.data[index] = updatedStyle;
                }
            })
            .addCase(updateFenceStyle.rejected, (state, action) => {
                state.isLoading = false;
                state.status = "failed";
                state.error = action.error.message;
            })
            .addCase(deleteFenceStyle.fulfilled, (state, action) => {
                state.status = "succeeded";
                state.data = state.data.filter(
                    (style) => style._id !== action.payload
                );
            });
    },
});

export default stylesSlice.reducer;
