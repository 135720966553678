import React, { useMemo } from "react";
import { useTable } from "react-table";
import Card from "components/card";
import { FaEdit } from "react-icons/fa";
import { MdDelete } from "react-icons/md";

const ComponentCollectionsTable = ({ collectionsData, onUpdate, onDelete }) => {
    const data = useMemo(() => collectionsData.map((item, index) => ({
        ...item,
        index: index + 1,  // Adding an index property
        fenceTypeDisplay: item.fenceType ? `${item.fenceType.type} (${item.fenceType.abbreviation})` : 'No Type',
        categoryDisplay: item.category ? `${item.category.categoryName} (${item.category.abbreviation})` : 'No Category',
    })), [collectionsData]);

    const columns = useMemo(() => [
        {
            Header: 'Collection Name',
            accessor: 'collectionName',
        },
        {
            Header: 'Collection ID',
            accessor: 'collectionId',
        },
        {
            Header: 'Category',
            accessor: 'categoryDisplay',  // Display formatted category name
        },
        {
            Header: 'Fence Type',
            accessor: 'fenceTypeDisplay',  // Display formatted fence type
        },
        {
            Header: 'Actions',
            accessor: 'actions',
            Cell: ({ row }) => (
                <div className="flex justify-center gap-2">
                    <button onClick={() => onUpdate(row.original)}>
                        <FaEdit className="text-lg text-[#3b82f6]" />
                    </button>
                    <button onClick={() => onDelete(row.original)}>
                        <MdDelete className="text-[20px] text-[#FF0000]" />
                    </button>
                </div>
            ),
        }
    ], [onDelete, onUpdate]);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = useTable({ columns, data });

    return (
        <Card extra="w-full h-full p-4">
            <table {...getTableProps()} className="w-full">
                <thead>
                {headerGroups.map(headerGroup => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map(column => (
                            <th {...column.getHeaderProps()} className="border text-xs border-gray-200 px-3 py-2 text-center dark:border-navy-700">
                                {column.render('Header')}
                            </th>
                        ))}
                    </tr>
                ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                {rows.map((row, i) => {
                    prepareRow(row);
                    return (
                        <tr {...row.getRowProps()}>
                            {row.cells.map(cell => (
                                <td {...cell.getCellProps()} className="p-3 border text-xs border-gray-200 text-center">
                                    {cell.render('Cell')}
                                </td>
                            ))}
                        </tr>
                    );
                })}
                </tbody>
            </table>
        </Card>
    );
};

export default ComponentCollectionsTable;
