import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import TypesTable from './TypesTable';
import AddTypeModal from "./AddTypeModal";
import IconButton from "../../../common-comps/tailwind-css/buttons/IconButton";
import { MdOutlineAdd } from "react-icons/md";
import DeleteConfirmationModal from "../../../../utils/DeleteConfirmationModal";
import {toast} from "react-toastify";
import {
    deleteFenceType,
    fetchFenceTypes,
    addFenceType, updateFenceType
} from "../../../../redux/reducers/fence/types-slice/fenceTypeSlice";
import Navbar from "../../../../components/navbar";
import {showToast} from "../../../utils/toastUtils";

const Types = () => {
    const dispatch = useDispatch();
    const fencesTypes = useSelector((state) => state?.fenceTypes?.data);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [deleteId, setDeleteId] = useState("");

    const [isDeleting, setIsDeleting] = useState(false);
    const [IsSaving, setIsSaving] = useState(false);

    const [selectedItem, setSelectedItem] = useState(null);
    const [editMode, setEditMode] = useState(false);

    useEffect(() => {
        dispatch(fetchFenceTypes());
    }, [dispatch]);

    const handleEdit = (item) => {
        setSelectedItem(item)
        setEditMode(true)
        setIsModalOpen(true)
    };
    const handleDelete = async (itemId) => {
        try {
            setIsDeleting(true);
            const response = await dispatch(deleteFenceType(itemId));

            if (response.meta.requestStatus === "fulfilled") {
                showToast("Successfully component deleted", 'success');
            } else {
                showToast("Network error", 'error');
            }
            setIsDeleteModalOpen(false);
        }catch (error) {
            toast.error(`Network error`, {
                position: toast.POSITION.TOP_RIGHT,
            });

        }finally {
            setIsDeleting(false);
        }
    };
    const handleUpdate = async (newTypeData) => {
        try {
            setIsSaving(true);
            const response = await dispatch(updateFenceType(newTypeData));
            if (response?.meta?.requestStatus === "fulfilled") {
                showToast("Type updated successfully", 'success');
            } else {
                showToast(response.payload.error.response.data.message, 'error');
            }
        } catch (error) {
            showToast(error, 'error');
        } finally {
            setIsSaving(false);
            setEditMode(false)
        }
    };
    const handleAdd = async (typeData) => {
        try {
            setIsSaving(true);
            if (typeData.hasOwnProperty('_id')) delete typeData._id;
            if (typeData.type !== "") {
                const response = await dispatch(addFenceType(typeData));
                if (response?.meta?.requestStatus === "fulfilled") {
                    showToast("Successfully type added", 'success');
                } else {
                    showToast(response.payload?.error || response.payload?.error.response.data.message, 'error');
                }
            } else {
                showToast("Please Fill Mandatory Data", 'error');
            }
        } catch (error) {
            showToast(error.message || "Failed to Add Type!!", 'error');
        } finally {
            setIsSaving(false);
            setEditMode(false)
        }
    };

    return (
        <div className="h-full">
            <Navbar
                buttonText={"Add Type"}
                linkText={"fenceType"}
                heading={"Fence Types"}
                handleButtonClicked={() => setIsModalOpen(true)}
            />
            <TypesTable typesData={fencesTypes || []} onUpdate={handleEdit} onDelete={(row)=>{
                setIsDeleteModalOpen(true);
                setDeleteId(row._id);
            }}  />
            <AddTypeModal
                isOpen={isModalOpen}
                editMode={editMode}
                onClose={() => setIsModalOpen(false)}
                selectedItem={selectedItem}
                isSaving={IsSaving}
                onSave={editMode ? handleUpdate : handleAdd}
            />
            <DeleteConfirmationModal
                isOpen={isDeleteModalOpen}
                loading={isDeleting}
                onClose={() => setIsDeleteModalOpen(false)}
                onDelete={() => handleDelete(deleteId)}
            />
        </div>
    );
};

export default Types;
