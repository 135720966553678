import React from 'react';

const FloatingLabelInput = ({ id, type, placeholder, label, value, onChange, customClasses,wrapperClasses }) => {
    // Destructure custom classes for input and label, if provided
    const { inputClass = '', labelClass = '' } = customClasses || {};

    return (
        <div className={"relative w-full" + wrapperClasses}>
            <input
                type={type || 'text'}
                id={id}
                value={value}
                onChange={onChange}
                placeholder={placeholder || ' '}
                className={`block border rounded px-2 py-2 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-500 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer ${inputClass}`}
            />
            <label
                htmlFor={id}
                className={`absolute text-sm cursor-auto text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-1 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1 ${labelClass}`}
            >
                {label}
            </label>
        </div>
    );
};

export default FloatingLabelInput;
