import React, { useEffect, useState, Suspense } from 'react';
import {Button, FormControl, FormLabel} from '@chakra-ui/react';
import { useDispatch, useSelector } from "react-redux";
import { handleAddNewData, handleUpdateComponent, handleDeleteComponent } from './handleApiCalls'; // Adjust the path as necessary

import SearchComponent from "./search/SearchComponent";
import {FENCE_COMPONENT_COLUMNS} from "../../../../utils/constants";
import {fetchFenceComponents} from "../../../../../redux/reducers/fence/fence-component-slice/fenceComponentSlice";
import {
    fetchFenceComponentCategories
} from "../../../../../redux/reducers/fence/fence-component-categories/fenceComponentCategoriesSlice";
import Navbar from "../../../../../components/navbar";
import MyCustomTable from "../../../../common-comps/custom-table/MyCustomTable";
import MyDropdown from "../../../../common-comps/dropdown/MyDropdown";
import {
    fetchComponentCollectionsByCategoryId
} from "../../../../../redux/reducers/fence/comp-collections/componentCollectionsSlice";
import MySpinner from "../../../../common-comps/spinner/MySpinner";
import CsvDataSheet from "../csv data sheet/CsvDataSheet";

// Lazy load the AddComponentModal
const AddComponentModal = React.lazy(() => import("./AddComponentModal"));

const FenceComponents = () => {
    const dispatch = useDispatch();
    const {data:fenceComponents, isLoading:loadingData} = useSelector(state => state.fenceComponents.allComponents);
    const fenceCategories = useSelector(state => state.fenceComponentCategories.allCategories.categories);
    const compCollections = useSelector(state => state.componentCollections?.collectionsByCategoryId?.collections) || [];

    const [showModal, setShowModal] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [selectedComponent, setSelectedComponent] = useState(null);

    const [fenceCategoriesList, setFenceCategoriesList] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [fenceCollectionList, setCollectionList] = useState([]);
    const [selectedCollection, setSelectedCollection] = useState(null);
    const [selectedCateComponents, setSelectedCateComponents] = useState([]);

    const [isSavingData, setIsSavingData] = useState(false);
    const [loadingSpinner, setLoadingSpinner] = useState(false);


    useEffect(() => {
        dispatch(fetchFenceComponents());
        dispatch(fetchFenceComponentCategories());
    }, [dispatch]);

    useEffect(() => {
        setLoadingSpinner((!fenceComponents?.length && loadingData) || false)
    }, [loadingData]);

    useEffect(() => {
        if (fenceCategories?.length){
            const categories = [{_id:-1,categoryName:'All'},...fenceCategories]
            setFenceCategoriesList(categories)
            setSelectedCategory(categories[0])
        }

    }, [fenceCategories]);
    useEffect(() => {
        const colls = [{_id:-1,collectionName:'All'},...compCollections]
        setCollectionList(colls)
        setSelectedCollection(colls[0])

    }, [compCollections]);

    useEffect(() => {
        if (selectedCategory && selectedCategory?._id !== -1) {
            dispatch(fetchComponentCollectionsByCategoryId(selectedCategory._id));
        }
    }, [selectedCategory]);

    useEffect(() => {
        if (selectedCategory && selectedCategory?._id !== -1) {
            setSelectedCateComponents((selectedCollection?._id !== -1 && selectedCategory) ?
            fenceComponents.filter(c=>c.componentCollection?._id === selectedCollection._id && c.category._id === selectedCategory._id) :
                fenceComponents?.filter(c=>c.category._id === selectedCategory._id) || [])
        }else {
            setSelectedCateComponents(fenceComponents || [])
        }
    }, [selectedCategory,selectedCollection,fenceComponents]);


    const handleAddNewComponent = () => {
        setEditMode(false);
        setSelectedComponent(null);
        setShowModal(true);
    };

    const handleEditComponent = (component) => {
        setEditMode(true);
        setSelectedComponent(fenceComponents.find(c=>c._id === component?._id) || null);
        setShowModal(true);
    }

    // Modified handlers that pass 'dispatch' to the imported functions
    const modifiedHandleAddNewData = (newComponentData,callback) => {
        handleAddNewData(dispatch, newComponentData, setShowModal, setIsSavingData, setEditMode,callback);
    };

    const modifiedHandleUpdateComponent = (updatedComponentData,callback) => {
        handleUpdateComponent(dispatch, updatedComponentData, selectedComponent, setShowModal, setIsSavingData, setEditMode,callback);
    };

    const modifiedHandleDeleteComponent = (compData) => {
        handleDeleteComponent(dispatch, compData);
    };

    const handleSearchComponent = (searchTerm) => {
        if (selectedCategory._id === -1){ // Means All
            const components = [...fenceComponents]
            const filteredComps = !searchTerm ? components :  components.filter(c => {
                // Assuming you want to match the search term case-insensitively from the start
                // of the component name
                return c.name.toLowerCase().startsWith(searchTerm.toLowerCase());
            });
            setSelectedCateComponents(filteredComps);
        }else {
            const components = [...fenceComponents]?.filter(c=>c.category._id === selectedCategory._id);
            const flattenedComponents = (!searchTerm) ?
                components :
                components.filter(component => {
                    // Assuming you want to match the search term case-insensitively from the start
                    // of the component name
                    return component.name.toLowerCase().startsWith(searchTerm.toLowerCase());
                });
            setSelectedCateComponents(flattenedComponents);
        }
    };

    return (
        <div className="h-full">
            <div className="h-full flex flex-col shadow-2xl">
                <Navbar
                    buttonText={"Add New Component"}
                    linkText={"components"}
                    heading={"Fence Components"}
                    handleButtonClicked={handleAddNewComponent}
                />
                {/*Nav bar*/}
                <div className="flex justify-between items-center">
                    <SearchComponent onChange={handleSearchComponent}/>
                    <CsvDataSheet/>
                    <div className="flex">
                        <MyDropdown
                            items={fenceCategoriesList}
                            selectedValue={selectedCategory?.categoryName || ''}
                            onSelectItem={(e) => setSelectedCategory(fenceCategoriesList[e.target.selectedIndex])}
                            labelKey="categoryName"
                            title={"Filter by category:"}
                            placeholder="No Category Found"
                        />
                        <MyDropdown
                            items={fenceCollectionList}
                            selectedValue={selectedCollection?.collectionName || ''}
                            onSelectItem={(e) => setSelectedCollection(fenceCollectionList[e.target.selectedIndex])}
                            labelKey="collectionName"
                            valueKey="collectionName"
                            title={"Collection"}
                            placeholder="Select a collection"
                        />
                    </div>
                </div>
                <div style={{height:'calc(100% - 90px)'}} className="overflow-y-auto mt-1 relative">
                    {loadingSpinner && <MySpinner withBg={false} customClasses="absolute z-10 top-[10%] w-full"/>}
                    <MyCustomTable
                        columns={FENCE_COMPONENT_COLUMNS}
                        data={selectedCateComponents} // Adjust this if necessary to match your data structure
                        onUpdate={handleEditComponent}
                        onDelete={modifiedHandleDeleteComponent}
                    />
                </div>

            </div>

            <Suspense fallback={<div>Loading...</div>}>
                {showModal && (
                    <AddComponentModal
                        editMode={editMode}
                        selectedItem={selectedComponent}
                        isSaving={isSavingData}
                        onClose={() => {
                            setEditMode(false)
                            setShowModal(false)
                        }}
                        onSave={editMode ? modifiedHandleUpdateComponent : modifiedHandleAddNewData}
                        handleSaveAsNew={modifiedHandleAddNewData}
                        allComponents={fenceComponents}
                    />
                )}
            </Suspense>
        </div>
    );
};

export default FenceComponents;
