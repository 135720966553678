import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import api from "../../../../Api";

// Define initial state with detailed sections
const initialState = {
    allFenceStyles: {
        data: [],
        status: "idle",
        isLoading: false,
        error: null,
    },
    fenceStyleById: {
        data: null,
        status: "idle",
        isLoading: false,
        error: null,
    }
};

// Create an async thunk for fetching all fence styles
export const fetchMyFenceStyles = createAsyncThunk(
    "fence/fetchStyles",
    async (_, thunkAPI) => {
        try {
            const response = await api.get(
                `${process.env.REACT_APP_API_URL}/api/fence/getMyFenceStyles`
            );
            return response.data.data;
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: error.message });
        }
    }
);

// Create an async thunk for adding a new fence style
export const addMyFenceStyle = createAsyncThunk(
    "fence/addMyFenceStyle",
    async (styleData, { rejectWithValue }) => {
        try {
            const response = await api.post(
                `${process.env.REACT_APP_API_URL}/api/fence/addMyFenceStyle`,
                styleData
            );
            if (response.data.success) {
                return response.data.data;
            } else {
                return rejectWithValue({
                    error: response.data.message || "Failed to add style",
                });
            }
        } catch (error) {

            console.error(error, "Error while adding style");
            return rejectWithValue({ error: error.response.data.message });
        }
    }
);

// Create an async thunk for fetching a specific fence style by ID
export const fetchFenceStyleById = createAsyncThunk(
    "fence/fetchFenceStyleById",
    async (id, thunkAPI) => {
        try {
            const response = await api.get(
                `${process.env.REACT_APP_API_URL}/api/fence/getFenceStyleById/${id}`
            );
            if (response.data.success) {
                return response.data.data;
            } else {
                return thunkAPI.rejectWithValue({
                    error: response.data.message || "Failed to fetch style",
                });
            }
        } catch (error) {
            console.error("Error fetching specific fence style by ID", error);
            return thunkAPI.rejectWithValue({ error: error.message });
        }
    }
);


// Create an async thunk for updating a fence style
export const updateMyFenceStyle = createAsyncThunk(
    "fence/updateMyFenceStyle",
    async ({styleData,id} , thunkAPI) => {
        try {
            const response = await api.put(
                `${process.env.REACT_APP_API_URL}/api/fence/updateMyFenceStyle/${id}`,
                styleData
            );
            if (response.data.success) {
                return response.data.data;
            } else {
                return thunkAPI.rejectWithValue({
                    error: response.data.message || "Failed to update style",
                });
            }
        } catch (error) {
            console.error("Error updating fence style", error);
            return thunkAPI.rejectWithValue({ error: error.response.data.message });

        }
    }
);

// Create an async thunk for deleting a fence style
export const deleteMyFenceStyle = createAsyncThunk(
    "fence/deleteFenceStyle",
    async (id, thunkAPI) => {
        try {
            await api.delete(
                `${process.env.REACT_APP_API_URL}/api/fence/deleteMyFenceStyle?id=${id}`
            );
            return id;
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: error.message });
        }
    }
);


// Define the slice
const myFenceStylesSlice = createSlice({
    name: "myFenceStyles",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchMyFenceStyles.pending, (state) => {
                state.allFenceStyles.status = "loading";
                state.allFenceStyles.isLoading = true;
            })
            .addCase(fetchMyFenceStyles.fulfilled, (state, action) => {
                state.allFenceStyles.status = "succeeded";
                state.allFenceStyles.data = action.payload;
                state.allFenceStyles.isLoading = false;

            })
            .addCase(fetchMyFenceStyles.rejected, (state, action) => {
                state.allFenceStyles.status = "failed";
                state.allFenceStyles.error = action.error.message;
                state.allFenceStyles.isLoading = false;
            })
            .addCase(addMyFenceStyle.pending, (state) => {
                state.allFenceStyles.status = "loading";
            })
            .addCase(addMyFenceStyle.fulfilled, (state, action) => {
                state.allFenceStyles.status = "succeeded";
                state.allFenceStyles.data.push(action.payload);
            })
            .addCase(addMyFenceStyle.rejected, (state, action) => {
                state.allFenceStyles.status = "failed";
                state.allFenceStyles.error = action.error.message;
            })
            .addCase(fetchFenceStyleById.pending, (state) => {
                state.fenceStyleById.isLoading = true;
            })
            .addCase(fetchFenceStyleById.fulfilled, (state, action) => {
                state.fenceStyleById.status = "succeeded";
                state.fenceStyleById.data = action.payload;
                state.fenceStyleById.isLoading = false;
            })
            .addCase(fetchFenceStyleById.rejected, (state, action) => {
                state.fenceStyleById.status = "failed";
                state.fenceStyleById.error = action.error.message;
                state.fenceStyleById.isLoading = false;
            })
            .addCase(updateMyFenceStyle.pending, (state) => {
                state.allFenceStyles.isLoading = true;
            })
            .addCase(updateMyFenceStyle.fulfilled, (state, action) => {
                state.allFenceStyles.status = "succeeded";
                state.allFenceStyles.isLoading = false;
                const updatedComponent = action.payload;
                const index = state.allFenceStyles.data.findIndex(
                    (component) => component._id === updatedComponent._id
                );
                if (index !== -1) {
                    state.allFenceStyles.data[index] = updatedComponent;
                }
            })
            .addCase(updateMyFenceStyle.rejected, (state, action) => {
                state.allFenceStyles.status = "failed";
                state.allFenceStyles.error = action.error.message;
                state.allFenceStyles.isLoading = false;
            })
            .addCase(deleteMyFenceStyle.pending, (state) => {
                state.allFenceStyles.isLoading = true;
            })
            .addCase(deleteMyFenceStyle.fulfilled, (state, action) => {
                state.allFenceStyles.status = "succeeded";
                state.allFenceStyles.isLoading = false;
                const componentId = action.payload;
                state.allFenceStyles.data = state.allFenceStyles.data.filter(
                    (component) => component._id !== componentId
                );
            })
            .addCase(deleteMyFenceStyle.rejected, (state, action) => {
                state.allFenceStyles.status = "failed";
                state.allFenceStyles.error = action.error.message;
                state.allFenceStyles.isLoading = false;
            });
    },
});

export default myFenceStylesSlice.reducer;
