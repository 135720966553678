import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../../Api";

const initialState = {
    allCategories: {
        categories: [],
        status: "idle",
        isLoading: false,
        error: null,
    },
    categoriesByFenceTypeId: {
        categories: [],
        status: "idle",
        isLoading: false,
        error: null,
    }
};

// Fetching all fence component categories
export const fetchFenceComponentCategories = createAsyncThunk(
    "fence/fetchComponentCategories",
    async (_, thunkAPI) => {
        try {
            const response = await api.get(
                `${process.env.REACT_APP_API_URL}/api/fence/getComponentCategories`
            );
            return response.data.data;
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: error.message });
        }
    }
);

// Fetching fence component categories by fenceTypeId
export const fetchFenceComponentCategoriesByFenceTypeId = createAsyncThunk(
    "fence/fetchComponentCategoriesByFenceTypeId",
    async (fenceTypeId, thunkAPI) => {
        try {
            const response = await api.get(
                `${process.env.REACT_APP_API_URL}/api/fence/getComponentCategoriesByFenceTypeId/${fenceTypeId}`
            );
            return response.data.data;
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: error.message });
        }
    }
);
// Adding a new fence component category
export const addFenceComponentCategory = createAsyncThunk(
    "fence/addComponentCategory",
    async (categoryData, { rejectWithValue }) => {
        try {
            const response = await api.post(
                `${process.env.REACT_APP_API_URL}/api/fence/addComponentCategory`,
                categoryData
            );
            if (response.data.success) {
                return response.data.data;
            } else {
                return rejectWithValue({
                    error: response.payload?.error?.message || response.data.message || "Failed to add category",
                });
            }
        } catch (error) {
            return rejectWithValue({ error: error.response?.data?.message || error.message });
        }
    }
);

// Updating a fence component category
export const updateFenceComponentCategory = createAsyncThunk(
    "fence/updateComponentCategory",
    async (categoryData, thunkAPI) => {
        try {
            const response = await api.put(
                `${process.env.REACT_APP_API_URL}/api/fence/updateComponentCategory`,
                categoryData
            );
            return response.data.data;
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: error.message });
        }
    }
);

// Deleting a fence component category
export const deleteFenceComponentCategory = createAsyncThunk(
    "fence/deleteComponentCategory",
    async (id, { rejectWithValue }) => {
        try {
            const response = await api.delete(
                `${process.env.REACT_APP_API_URL}/api/fence/deleteComponentCategory?id=${id}`
            );
            return response.data.data ? response.data.data : rejectWithValue({ error: "Deletion unsuccessful" });
        } catch (error) {
            return rejectWithValue({ error: error.message });
        }
    }
);

const fenceComponentCategoriesSlice = createSlice({
    name: "fenceComponentCategories",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            // Handling all categories
            .addCase(fetchFenceComponentCategories.pending, (state) => {
                state.allCategories.status = "loading";
            })
            .addCase(fetchFenceComponentCategories.fulfilled, (state, action) => {
                state.allCategories.status = "succeeded";
                state.allCategories.categories = action.payload;
            })
            .addCase(fetchFenceComponentCategories.rejected, (state, action) => {
                state.allCategories.status = "failed";
                state.allCategories.error = action.error.message;
            })
            // Handling categories by fence type ID
            .addCase(fetchFenceComponentCategoriesByFenceTypeId.pending, (state) => {
                state.categoriesByFenceTypeId.status = "loading";
            })
            .addCase(fetchFenceComponentCategoriesByFenceTypeId.fulfilled, (state, action) => {
                state.categoriesByFenceTypeId.status = "succeeded";
                state.categoriesByFenceTypeId.categories = action.payload;
            })
            .addCase(fetchFenceComponentCategoriesByFenceTypeId.rejected, (state, action) => {
                state.categoriesByFenceTypeId.status = "failed";
                state.categoriesByFenceTypeId.error = action.error.message;
            })
            // Add Fence Component Category
            .addCase(addFenceComponentCategory.pending, (state) => {
                state.allCategories.isLoading = true;
            })
            .addCase(addFenceComponentCategory.fulfilled, (state, action) => {
                state.allCategories.isLoading = false;
                state.allCategories.status = "succeeded";
                state.allCategories.categories.push(action.payload);
            })
            .addCase(addFenceComponentCategory.rejected, (state, action) => {
                state.allCategories.isLoading = false;
                state.allCategories.status = "failed";
                state.allCategories.error = action.error.message;
            })
            .addCase(updateFenceComponentCategory.pending, (state) => {
                state.allCategories.isLoading = true;
            })
            .addCase(updateFenceComponentCategory.fulfilled, (state, action) => {
                state.allCategories.isLoading = false;
                state.allCategories.status = "succeeded";
                const index = state.allCategories.categories.findIndex(category => category._id === action.payload._id);
                if (index !== -1) {
                    state.allCategories.categories[index] = action.payload;
                }
            })
            .addCase(updateFenceComponentCategory.rejected, (state, action) => {
                state.allCategories.isLoading = false;
                state.allCategories.status = "failed";
                state.allCategories.error = action.error.message;
            })
            .addCase(deleteFenceComponentCategory.fulfilled, (state, action) => {
                state.allCategories.status = "succeeded";
                state.allCategories.categories = state.allCategories.categories?.filter(category => category._id !== action.payload._id);
            })
            .addCase(deleteFenceComponentCategory.rejected, (state, action) => {
                state.allCategories.status = "failed";
                state.allCategories.error = action.error.message;
            });
    }
});

export default fenceComponentCategoriesSlice.reducer;

