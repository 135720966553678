const setCookieValue = (cookieValue, refreshToken) => {
  const expirationDate = new Date();
  expirationDate.setTime(expirationDate.getTime() + 12 * 60 * 60 * 1000);
  const expires = expirationDate.toUTCString();

  document.cookie = `token=${cookieValue}; Expires=${expires};path=/;`;
  if (refreshToken !== undefined) {
    document.cookie = `refreshToken=${refreshToken}; Expires=${expires};path=/;`;
  }
};
const getCookieValue = () => {
  const cookieToken = document.cookie.split(";");
  let authToken;
  let refreshToken;
  for (const token of cookieToken) {
    if (token.includes("token=")) {
      const tokenParts = token.split("=");
      authToken = tokenParts[1].trim();
    }
    if (token.includes("refreshToken=")) {
      const tokenParts = token.split("=");
      refreshToken = tokenParts[1].trim();
    }
  }

  return { authToken, refreshToken };
};
// const removeTokenCookie = () => {
//   document.cookie = "token=; expires=Thu, 01 Jan 1970 00:00:00 UTC;";
// };

const removeTokenCookie = () => {
  const existingCookie = document.cookie;

  // Split the existing cookie string into an array of key-value pairs
  const cookieArray = existingCookie.split(";");

  // Iterate through the array to find and remove the "token" attribute
  const updatedCookieArray = cookieArray.map((cookie) => {
    const trimmedCookie = cookie.trim();
    if (trimmedCookie.indexOf("token=") === 0) {
      // If the cookie contains the "token" attribute, remove it
      return trimmedCookie.replace(/token=[^;]+/, "");
    }
    // If it's not the "token" attribute, leave it unchanged
    return trimmedCookie;
  });

  // Join the updated array back into a string
  const updatedCookieString = updatedCookieArray.join("; ");

  // Update the document.cookie with the modified cookie string
  document.cookie = updatedCookieString;
};

const setTokenCookie = (tokenValue) => {
  // Set the cookie with the name "token" and the provided value, expires in 15 minutes
  const expirationTime = new Date(
    new Date().getTime() + 15 * 60 * 1000
  ).toUTCString();
  document.cookie = `token=${tokenValue}; path=/; expires=${expirationTime}`;
};

const removeCookies = () => {
  document.cookie = "token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
  document.cookie =
    "refreshToken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
};
export {
  setCookieValue,
  setTokenCookie,
  getCookieValue,
  removeCookies,
  removeTokenCookie,
};
