// import { useState } from "react";
import { BsThreeDotsVertical } from "react-icons/bs";
import Card from "components/card";
import React from "react";
import DeleteConfirmationModal from "../../utils/DeleteConfirmationModal";
import { ReactSVG } from "react-svg";
import { useDispatch } from "react-redux";

import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  IconButton,
} from "@chakra-ui/react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  Input,
  useDisclosure,
  Button,
} from "@chakra-ui/react";
import { useState } from "react";
import {
  updateShape,
  deleteShape,
} from "../../redux/reducers/shape/shapeSlice";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const NftCard = ({ title, itemId, price, image, bidders, extra }) => {
  const [formData, setFormData] = useState({
    title: "",
    selectedImage: null,
    id: "",
  });
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [imagePreview, setImagePreview] = useState(null);
  const [loading, setLoading] = useState(false);

  const [editFormData, seteditFormData] = useState({
    title: "",
    selectedImage: null,
  });
  const dispatch = useDispatch();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    seteditFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const handleDelete = async (itemId) => {
    try {
      setLoading(true); // Set loading state to true
      const response = await dispatch(deleteShape(itemId));
      if (response.meta.requestStatus === "fulfilled") {
        toast.success("Successfully shape deleted", {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        toast.error(`Network error`, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
      // Close the modal after deletion
    } finally {
      setIsDeleteModalOpen(false);
      setLoading(false); // Set loading state to false after deletion
    }
  };

  const handleSelectItem = (title, image, itemId) => {
    setFormData({
      title: title,
      selectedImage: image,
      id: itemId,
    });
    seteditFormData({
      title: title,
      selectedImage: image,
    });
    onOpen();
  };
  const handleImageChange = (e) => {
    const selectedImage = e.target.files[0];
    seteditFormData((prevData) => ({
      ...prevData,
      selectedImage,
    }));

    if (selectedImage) {
      const reader = new FileReader();

      reader.onloadend = () => {
        setImagePreview(reader.result);
      };

      reader.readAsDataURL(selectedImage);
    } else {
      setImagePreview(null);
    }
  };
  const handleSave = async () => {
    try {
      setLoading(true); // Set loading state to true
      // For example, you can dispatch an action to save the data
      let formDataAppend = new FormData(); //formdata object

      editFormData?.title !== "" &&
        formDataAppend.append("name", editFormData?.title);

      editFormData?.selectedImage &&
        formDataAppend.append("image", editFormData?.selectedImage);

      formDataAppend.append("id", formData?.id);

      const response = await dispatch(updateShape(formDataAppend));

      if (response.meta.requestStatus === "fulfilled") {
        toast.success("Successfully shape updated", {
          position: toast.POSITION.TOP_RIGHT,
        });
        onClose();
        seteditFormData({ title: "", selectedImage: null });
        setImagePreview(null);
      } else {
        toast.error(`Network error`, {
          position: toast.POSITION.TOP_RIGHT,
        });
        onClose();
        seteditFormData({ title: "", selectedImage: null });
        setImagePreview(null);
      }
    } catch (error) {
      toast.error(error, {
        position: toast.POSITION.TOP_RIGHT,
      });
    } finally {
      setLoading(false);
    }
  };

  const initialRef = React.useRef(null);
  const finalRef = React.useRef(null);
  return (
    <Card
      extra={`flex flex-col w-full h-full !p-4 3xl:p-![18px] bg-white ${extra}`}
    >
      <div className="h-full w-full">
        <div className=" relative w-full">
          <ReactSVG
            src={image}
            className="card-img mb-3 h-full w-full rounded-xl 3xl:h-full 3xl:w-full"
          />

          <button className="absolute top-0 right-0 flex items-center justify-center rounded-full bg-white p-2 text-brand-500 hover:cursor-pointer">
            <div className="flex h-full w-full items-center justify-center rounded-full text-xl hover:bg-gray-50 dark:text-navy-900">
              <div className="menu_item_chakara ">
                <Menu>
                  <MenuButton
                    as={IconButton}
                    aria-label="Options"
                    icon={<BsThreeDotsVertical />}
                    variant="outline"
                  />
                  <MenuList className="dark:bg-[#111c44] dark:text-white">
                    <MenuItem
                      onClick={() => handleSelectItem(title, image, itemId)}
                    >
                      Edit
                    </MenuItem>
                    <MenuItem onClick={() => setIsDeleteModalOpen(true)}>
                      Delete
                    </MenuItem>
                    <DeleteConfirmationModal
                      isOpen={isDeleteModalOpen}
                      onClose={() => setIsDeleteModalOpen(false)}
                      onDelete={() => handleDelete(itemId)}
                      loading={loading}
                    />
                  </MenuList>
                </Menu>
              </div>
            </div>
          </button>
        </div>

        <div className=" flex items-center justify-between md:flex-col md:items-start lg:flex-row lg:justify-between xl:flex-col xl:items-start 3xl:flex-row 3xl:justify-between">
          <p className="text-lg font-bold text-navy-700 dark:text-white">
            {" "}
            {title}{" "}
          </p>
          {bidders.length !== 0 && (
            <div className="flex flex-row-reverse md:mt-2 lg:mt-0">
              <span className="z-0 ml-px inline-flex h-8 w-8 items-center justify-center rounded-full border-2 border-white bg-[#E0E5F2] text-xs text-navy-700 dark:!border-navy-800 dark:bg-gray-800 dark:text-white">
                +5
              </span>
              {bidders.map((avt, key) => (
                <span
                  key={key}
                  className="z-10 -mr-3 h-8 w-8 rounded-full border-2 border-white dark:!border-navy-800"
                >
                  <img
                    className="h-full w-full rounded-full object-cover"
                    src={avt}
                    alt=""
                  />
                </span>
              ))}
            </div>
          )}
        </div>
        {bidders.length !== 0 && (
          <div className="flex items-center justify-between md:flex-col md:items-start lg:flex-row lg:justify-between xl:flex-col 2xl:items-start 3xl:flex-row 3xl:items-center 3xl:justify-between">
            <div className="flex">
              <p className="mb-2 text-sm font-bold text-brand-500 dark:text-white">
                Current Bid: {price} <span>ETH</span>
              </p>
            </div>
            <button
              href=""
              className="linear rounded-[20px] bg-brand-900 px-4 py-2 text-base font-medium text-white transition duration-200 hover:bg-brand-800 active:bg-brand-700 dark:bg-brand-400 dark:hover:bg-brand-300 dark:active:opacity-90"
            >
              Place Bid
            </button>
          </div>
        )}
      </div>
      <Modal
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}
        isOpen={isOpen}
        onClose={onClose}
      >
        <div className="main-modal-chakar main-modal-chakar-dark fixed top-0 left-0 h-full w-full ">
          {" "}
          <ModalOverlay />
          <ModalContent className="dark:bg-111c44 dark:text-white">
            <ModalHeader className="text-lg font-bold text-navy-700 dark:text-white">
              Edit Shape
            </ModalHeader>
            <div className="modalButton_close">
              <ModalCloseButton />
            </div>
            <ModalBody pb={6}>
              <FormControl className="mb-2">
                <FormLabel>Title</FormLabel>
                <Input
                  className="w-full rounded-lg border border-solid border-gray-300 p-2 dark:border-[#202848] dark:bg-[#111c44]"
                  ref={initialRef}
                  placeholder="Title"
                  name="title"
                  value={editFormData.title}
                  onChange={handleInputChange}
                />
              </FormControl>

              {imagePreview ? (
                <img
                  src={imagePreview}
                  alt="Preview"
                  style={{ maxWidth: "100px", maxHeight: "100px" }}
                />
              ) : (
                <ReactSVG
                  src={editFormData.selectedImage}
                  className="h-50 w-50 model-svg mb-3 rounded-xl"
                />
              )}
              <FormControl mt={4}>
                <label className="flex w-full cursor-pointer items-center justify-center rounded-lg bg-blue-500 px-4 py-2 text-white hover:bg-blue-600 dark:bg-[#7551ff]">
                  <input
                    type="file"
                    onChange={handleImageChange}
                    className="hidden"
                  />
                  Upload Image
                </label>
              </FormControl>
            </ModalBody>

            <ModalFooter>
              <Button
                className={`linear mt-4 flex items-center justify-center rounded-xl bg-brand-500 px-2 py-2 text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200`}
                colorScheme="blue"
                onClick={() => handleSave()}
                isLoading={loading}
                loadingText="Saving..."
              >
                {loading && (
                  <div className="absolute inset-0 flex items-center justify-center">
                    <div className="h-6 w-6 animate-spin rounded-full border-t-4 border-brand-700 border-t-brand-700 border-r-brand-500 border-b-brand-500"></div>
                  </div>
                )}
                Save
              </Button>
              <Button
                onClick={onClose}
                className="linear mt-4 flex items-center justify-center rounded-xl bg-brand-500 px-2 py-2 text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
              >
                Cancel
              </Button>
            </ModalFooter>
          </ModalContent>
        </div>
      </Modal>
    </Card>
  );
};

export default NftCard;
