import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import DashIcon from "components/icons/DashIcon";
import { IoIosArrowUp, IoIosArrowDown } from "react-icons/io";
export function SidebarLinks(props) {
  const location = useLocation();
  const { routes } = props;

  const [activeParent, setActiveParent] = useState(null);

  const isActiveRoute = (route) => {
    return location.pathname.includes(route.path);
  };

  const toggleParent = (index) => {
    setActiveParent((prev) => (prev === index ? null : index));
  };
  const Icon = ({ icon, isActive }) => {
    const iconColorClass = isActive
      ? "text-[#422afb] dark:text-white"
      : "text-gray-500";

    return <span className={`leading-1 ${iconColorClass}`}>{icon}</span>;
  };

  // const Icon = ({ icon, isActive }) => {
  //   const iconColor = isActive ? "#422afb" : "#a3aed0";

  //   return <span style={{ color: iconColor }}>{icon}</span>;
  // };

  const createLinks = (routes) => {
    return routes.map((route, index) => (
      <div key={index}>
        <Link to={`${route.layout}/${route.path}`}>
          <div
            className={`relative mb-3 flex cursor-pointer ${
              activeParent === index ? "active" : ""
            }`}
            onClick={() => toggleParent(index)}
          >
            <li className="my-[3px] flex items-center ml-4">
              <span
                className={`text-sm ${
                  isActiveRoute(route) && !route.children?.find(c=>isActiveRoute(c))
                    ? "font-bold text-brand-500 dark:text-white"
                    : "font-medium text-gray-600"
                }`}
              >
                {route.icon ? route.icon : <DashIcon />}{" "}
              </span>
              <p
                className={`text-md ml-4 ${
                  isActiveRoute(route) && !route.children?.find(c=>isActiveRoute(c))
                    ? "font-bold text-navy-700 dark:text-white"
                    : "font-medium text-gray-600"
                }`}
              >
                {route.name !== "Reset Password" && route.name}
              </p>
              {route.children && (
                <span className="ml-2">
                  {activeParent === index ? (
                    <IoIosArrowUp />
                  ) : (
                    <IoIosArrowDown
                      className={
                        activeParent === index
                          ? "text-black-600"
                          : "text-gray-600"
                      }
                    />
                  )}
                </span>
              )}
            </li>
          </div>
        </Link>
        {/* Render children */}
        {route.children && activeParent === index && (
          <ul className="ml-4">
            {route.children.map((childRoute, childIndex) => (
              <Link
                key={childIndex}
                to={`${route.layout}/${route.path}/${childRoute.path}`}
              >
                <li
                  className={`my-[11px] flex items-center ml-4 ${
                    isActiveRoute(childRoute) ? "active-child" : ""
                  }`}
                >
                  <span className="text-sm">
                    {childRoute.icon ? (
                      <Icon
                        icon={childRoute.icon}
                        isActive={isActiveRoute(childRoute)}
                      />
                    ) : (
                      <DashIcon />
                    )}{" "}
                  </span>
                  <p
                    className={`text-md ml-2 whitespace-nowrap ${
                      isActiveRoute(childRoute)
                        ? "font-bold text-navy-700 dark:text-white"
                        : "text-gray-600"
                    }`}
                  >
                    {childRoute.name}
                  </p>
                </li>
              </Link>
            ))}
          </ul>
        )}
      </div>
    ));
  };

  return <>{createLinks(routes)}</>;
}

export default SidebarLinks;
