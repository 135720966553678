import {
    addFenceComponent,
    updateFenceComponent,
    deleteFenceComponent,
    fetchFenceComponents,
} from "../../../../../redux/reducers/fence/fence-component-slice/fenceComponentSlice";
import {showToast} from "../../../../utils/toastUtils";
import {addFenceCompsByCSV} from "../../../../../redux/reducers/fence/fence-comps-csv/fenceCompsCsvSlice";

export const handleAddNewData = async (dispatch, newComponentData, setShowModal, setIsSavingData, setEditMode,callback) => {
    try {
        setIsSavingData(true);
        const formData = new FormData();
        Object.keys(newComponentData).forEach(key => {
            if (key !== 'images') {
                formData.append(key, newComponentData[key]);
            }
        });

        if (newComponentData.images && newComponentData.images.length) {
            let images = []
            newComponentData.images.forEach(image => {
                if (image instanceof Blob) {
                    formData.append('componentImg', image, image.name);
                }else {
                    images.push(image)
                }
            });
            images?.length && formData.append('images',JSON.stringify(images))
        }

        const response = await dispatch(addFenceComponent(formData));
        if (response?.meta?.requestStatus === "fulfilled") {
            showToast("Component successfully added", 'success');
            dispatch(fetchFenceComponents());
            setIsSavingData(false)

            callback && callback()
        } else {
            setIsSavingData(false);
            showToast(response.payload?.error || "Failed to add component", 'error');
        }
    } catch (error) {
        setIsSavingData(false);
        showToast(error.toString(), 'error');
    }
};


export const handleUpdateComponent = async (dispatch, updatedComponentData, selectedComponent, setShowModal, setIsSavingData, setEditMode,callback) => {
    try {
        setIsSavingData(true);
        if (!updatedComponentData._id) {
            showToast(`Data not found`, 'error');
            setIsSavingData(false);
            return;
        }

        const formData = new FormData();
        Object.keys(updatedComponentData).forEach(key => {
            if (key !== 'images') {
                formData.append(key, updatedComponentData[key]);
            }
        });
        //
        if (updatedComponentData.images && updatedComponentData.images.length) {
            let imagesLinks = [];
            updatedComponentData.images.forEach(image => {
                if (image instanceof Blob) {
                    formData.append('componentImg', image, image.name);
                } else {
                    imagesLinks.push(image);
                }
            });

            if (imagesLinks.length) {
                formData.append('images', JSON.stringify(imagesLinks));
            }
        }

        const response = await dispatch(updateFenceComponent(formData));
        if (response.meta.requestStatus === "fulfilled") {
            showToast("Component updated successfully", 'success');
            dispatch(fetchFenceComponents());
            callback && callback()
        } else {
            showToast(response.payload?.error || "Failed to update component", 'error');
        }
    } catch (error) {
        showToast(error.toString(), 'error');
    } finally {
        setIsSavingData(false);
    }
};

export const handleDeleteComponent = async (dispatch,compData) => {
    try {
        const itemId = compData._id;
        if (!itemId) {
            showToast(`Data not found`, 'error');
            return;
        }

        const response = await dispatch(deleteFenceComponent(itemId));
        if (response.meta.requestStatus === "fulfilled") {
            showToast("Successfully deleted component", 'success');
            dispatch(fetchFenceComponents());
        } else {
            showToast(response.error.message || "Failed to delete component", 'error');
        }
    } catch (error) {
        showToast(error.toString() || "An error occurred while deleting the component.", 'error');
    }
};

export const handleAddComponentsByCSV = async (dispatch,data,callback) => {
    try {
        if (!data) {
            showToast(`Data not found`, 'error');
            return;
        }

        const response = await dispatch(addFenceCompsByCSV(data));
        if (response.meta.requestStatus === "fulfilled") {
            showToast("Successfully added all components", 'success');
            callback && callback()
        } else {
            showToast(response.error.message || "Failed to add components", 'error');
        }
    } catch (error) {
        showToast(error.toString() || "An error occurred while adding components.", 'error');
    }
};
