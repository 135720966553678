import React from 'react';
import { FormControl, FormLabel } from '@chakra-ui/react';

const MyDropdown = ({ items, selectedValue, onSelectItem, labelKey, valueKey, placeholder,title,customCssClasses,innerWrapperClasses='' }) => {
    return (
        <FormControl className={customCssClasses ? customCssClasses : ""}>
            <div className={"flex justify-end items-center mr-4 " + innerWrapperClasses}>
                <FormLabel className="px-2 text-xs whitespace-nowrap">{title}</FormLabel>
                <FormControl className="w-full">
                    <select
                        onChange={onSelectItem}
                        value={selectedValue || ''}
                        className="w-full px-3 py-1 text-sm border border-gray-500 rounded cursor-pointer"
                    >
                        {/*<option value="">{placeholder}</option>*/}
                        {items.map((item, i) => (
                            <option className="cursor-pointer" key={i} value={item[valueKey]}>
                                {item[labelKey]}
                            </option>
                            ))}
                    </select>
                </FormControl>
            </div>
        </FormControl>
    )
}

export default MyDropdown;
