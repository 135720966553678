import React, { useEffect, useState } from 'react';
import { AVAILABLE_FENCE_HEIGHTS } from "../../../../../../../variables/global-data/globalConstants";
import IconButton from "../../../../../../common-comps/tailwind-css/buttons/IconButton";
import { convertToFeet } from "../../../../../../utils/utilFunctions";

const HeightsComponent = ({ selectedFenceHeights, setSelectedFenceHeights, selectedItem }) => {
    const [availableHeights, setAvailableHeights] = useState(AVAILABLE_FENCE_HEIGHTS);
    const [customHeight, setCustomHeight] = useState('');
    const [isCustomHeight, setIsCustomHeight] = useState(false);
    const [unitOfMeasurement, setUnitOfMeasurement] = useState('ft');
    const units = ['ft', 'inch', 'cm'];

    useEffect(() => {
        // Convert selectedItem.heights to an array if it's not already one
        setSelectedFenceHeights(selectedItem?.heights || []);
    }, [selectedItem?.heights?.length, setSelectedFenceHeights]);

    const handleHeightCheckChange = (height, isChecked) => {
        setSelectedFenceHeights(currentHeights => {
            if (isChecked && !currentHeights.includes(height)) {
                return [...currentHeights, height];
            } else {
                return currentHeights.filter(h => h !== height);
            }
        });
    };

    const handleCustomHeightChange = (e) => {
        setCustomHeight(e.target.value);
    };

    const toggleCustomHeight = () => {
        setIsCustomHeight(!isCustomHeight);
        if (!isCustomHeight) {
            setCustomHeight(''); // Reset when toggling off
        }
    };

    const addCustomDesiredHeight = () => {
        const newHeight = customHeight.trim();
        if (newHeight) {
            const heightInFeet = convertToFeet(parseFloat(newHeight), unitOfMeasurement).toString();
            if (!availableHeights.includes(heightInFeet)) {
                setAvailableHeights(currentHeights => [...currentHeights, heightInFeet]);
            }
            setSelectedFenceHeights(currentHeights => {
                return currentHeights.includes(heightInFeet) ? currentHeights : [...currentHeights, heightInFeet];
            });
        } else {
            console.error("Please enter a valid height."); // Using console for errors as a placeholder
        }
    };

    const removeHeight = (heightToRemove) => {
        setAvailableHeights(currentHeights => currentHeights.filter(height => height !== heightToRemove));
        setSelectedFenceHeights(currentHeights => currentHeights.filter(height => height !== heightToRemove));
    };

    return (
        <div className="w-1/2">
            <label className="block text-sm font-bold text-gray-800">
                Fence Heights (ft)
            </label>
            <div className="ml-2">
                <div className="flex flex-wrap">
                    {availableHeights.map((height) => (
                        <div key={height} className="flex items-center m-1">
                            <input
                                type="checkbox"
                                checked={selectedFenceHeights.includes(height)}
                                onChange={(e) => handleHeightCheckChange(height, e.target.checked)}
                                className="mr-2 cursor-pointer"
                            />
                            {height} ft
                            <button
                                onClick={() => removeHeight(height)}
                                className="text-[10px] px-1 text-red-500 hover:text-[11px]"
                            >✖</button>
                        </div>
                    ))}
                </div>
                <div className="flex items-center my-1">
                    <IconButton text={isCustomHeight ? "Hide Custom Height" : "Add Custom Height"}
                                onClick={toggleCustomHeight}
                                outlined={true}
                                customClasses="text-xs bg-gray-700 text-white border-gray-700 hover:border-gray-600 hover:bg-gray-600 px-1.5 py-1 mr-2"/>
                    {isCustomHeight && (
                        <div className="w-full flex items-center">
                            <label className="block text-sm text-gray-800">
                                Enter Custom Height:
                            </label>
                            <input
                                type="number"
                                className="px-1 py-1 border text-xs border-gray-500 text-gray-800 rounded mx-2 focus:outline-none focus:border-blue-500"
                                placeholder="Enter height"
                                value={customHeight}
                                onChange={handleCustomHeightChange}
                            />
                            <div className="flex items-center mr-2">
                                <label className="block text-xs text-gray-800 mr-2">Unit:</label>
                                <select
                                    className="px-1 py-1 border border-gray-500 text-xs rounded focus:outline-none focus:border-blue-500"
                                    value={unitOfMeasurement}
                                    onChange={e => setUnitOfMeasurement(e.target.value)}
                                >
                                    {units.map(unit => (
                                        <option key={unit} value={unit}>{unit}</option>
                                    ))}
                                </select>
                            </div>
                            <IconButton text="Add Height"
                                        onClick={addCustomDesiredHeight}
                                        outlined={true}
                                        customClasses="text-xs bg-purple-700 text-white border-purple-700 hover:border-purple-500 hover:bg-purple-500 px-1.5 py-1"/>
                        </div>
                    )}
                </div>
                {selectedFenceHeights.length > 0 && (
                    <div className="mt-2">
                        <h3 className="text-sm font-bold text-gray-800">Selected Heights:</h3>
                        <ul className="list-disc pl-5">
                            {selectedFenceHeights.map(height => (
                                <li key={height} className="text-gray-700 text-xs">{height} ft</li>
                            ))}
                        </ul>
                    </div>
                )}
            </div>
        </div>
    );
};

export default HeightsComponent;
