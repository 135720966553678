export const FENCE_COLORS = [
    "Galvanized",
    "Aluminum",
    "Black",
    "White",
    "Green",
    "Brown",
    "Red",
]
export const AVAILABLE_FENCE_HEIGHTS = [
    '3',
    '3.5',
    '4',
    '5',
    '6',
    '7',
    '8',
    '9',
    '10',
    '12',
    '16',
    '18',
    '20',
    '21',
];


export const FENCE_COMPONENT_COLORS = {
    galvanized:'GA',
    aluminum:'AL',
    black:'BK',
    white:'WH',
    green:'GN',
    brown:'BN',
    red:'RD',
}