import React from "react";
import { Link } from "react-router-dom";

const Navbar = ({handleButtonClicked, linkText,heading, buttonText}) => {

  return (
      <nav className="z-40 h-[50px] w-full flex items-center">
        <div className="h-[80%] w-full flex flex-row flex-wrap items-center border border-3 border-purple-300 justify-between rounded-lg px-2 backdrop-blur-xl dark:bg-[#0b14374d] bg-[#AAD7D9] shadow-2xl">
          <div className="">
              <a
                  className="text-xs font-normal text-navy-700 hover:underline dark:text-white dark:hover:text-white"
                  href=" "
              >
                Pages
                <span className="mx-1 text-xs text-navy-700 hover:text-navy-700 dark:text-white">
                {" "}
                  /{" "}
              </span>
              </a>
              <Link
                  className="text-xs font-normal capitalize text-navy-700 hover:underline dark:text-white dark:hover:text-white"
                  to="#"
              >
                {linkText}
              </Link>
            </div>
          <p className="flex items-center shrink text-md capitalize text-navy-700 dark:text-white">
            <Link
                to="#"
                className="flex items-center font-bold capitalize hover:text-navy-700 dark:hover:text-white"
            >
              {heading}
            </Link>
          </p>
          <button onClick={handleButtonClicked} className="bg-gray-700 hover:bg-gray-800 text-white text-sm px-3 py-1 rounded-md">
            {buttonText}
          </button>
        </div>
      </nav>
  );
};

export default Navbar;
