import React from "react";
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react";

const DeleteConfirmationModal = ({ isOpen, onClose, onDelete, loading }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <div className="main-modal-chakar main-modal-chakar-dark fixed top-0 left-0 h-full w-full ">
        <ModalOverlay />
        <ModalContent className="dark:bg-111c44 dark:text-white">
          <ModalHeader className="text-lg font-bold text-navy-700  dark:text-white">
            Confirm Deletion
          </ModalHeader>
          <div className="modalButton_close">
            <ModalCloseButton />
          </div>
          <ModalBody>Are you sure you want to delete this item?</ModalBody>

          <ModalFooter>
            <Button
              className="linear mt-4 flex items-center justify-center rounded-xl bg-brand-500 px-2 py-2 text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
              colorScheme="red"
              onClick={onDelete}
              isLoading={loading} // Conditionally show loader
              loadingText="Deleting..."
            >
              {loading && (
                <div className="absolute inset-0 flex items-center justify-center">
                  <div className="h-6 w-6 animate-spin rounded-full border-t-4 border-brand-700 border-t-brand-700 border-r-brand-500 border-b-brand-500"></div>
                </div>
              )}
              Delete
            </Button>
            <Button
              className="linear mt-4 flex items-center justify-center rounded-xl bg-brand-500 px-2 py-2 text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
              onClick={onClose}
              ml={3}
            >
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </div>
    </Modal>
  );
};
export default DeleteConfirmationModal;
