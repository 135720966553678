import React, { useState, useEffect, Suspense } from "react";
import { useDispatch, useSelector } from "react-redux";
import IconButton from "../../../../common-comps/tailwind-css/buttons/IconButton";
import { MdOutlineAdd } from "react-icons/md";
import { FormControl, FormLabel } from "@chakra-ui/react";
import {
    addComponentCollection,
    updateComponentCollection,
    deleteComponentCollection,
    fetchComponentCollections
} from "../../../../../redux/reducers/fence/comp-collections/componentCollectionsSlice";
import CollectionsTable from "./ComponentCollectionsTable";
import { toast } from "react-toastify";
import Navbar from "../../../../../components/navbar";

// Lazy load the AddCollectionModal
const AddCollectionModal = React.lazy(() => import("./AddCollectionModal"));

const ComponentCollections = () => {
    const dispatch = useDispatch();
    const componentCollections = useSelector(state => state.componentCollections.allCollections.collections);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);
    const [editMode, setEditMode] = useState(false);

    useEffect(() => {
        // Assuming you have a sectionId to fetch collections by section
        dispatch(fetchComponentCollections());
    }, [dispatch]);

    const handleAddNewCollection = () => {
        setSelectedItem(null);
        setEditMode(false);
        setIsModalOpen(true);
    };

    const handleEditCollection = (collection) => {
        const selectedCollection = componentCollections.find(c => c._id === collection._id);
        if (!selectedCollection) return;
        setSelectedItem(selectedCollection);
        setEditMode(true);
        setIsModalOpen(true);
    };

    const handleDeleteCollection = async (collectionData) => {
        setIsSaving(true);
        try {
            const id = collectionData._id;
            const response = await dispatch(deleteComponentCollection(id));
            if (response.meta.requestStatus === "fulfilled") {
                toast.success("Collection deleted successfully", {
                    position: toast.POSITION.TOP_RIGHT,
                });
            } else {
                toast.error(response.payload?.error || "Failed to delete collection", {
                    position: toast.POSITION.TOP_RIGHT,
                });
            }
        } catch (error) {
            toast.error("Error deleting collection", {
                position: toast.POSITION.TOP_RIGHT,
            });
        } finally {
            setIsSaving(false);
        }
    };

    const handleSaveOrUpdate = async (newCollectionData) => {
        setIsSaving(true);
        try {
            if (editMode) {
                const response = await dispatch(updateComponentCollection(newCollectionData));
                if (response.meta.requestStatus === "fulfilled") {
                    toast.success("Collection updated successfully", {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                } else {
                    toast.error(response.payload?.error || "Failed to updated collection", {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                }
            } else {
                const response = await dispatch(addComponentCollection(newCollectionData));
                if (response.meta.requestStatus === "fulfilled") {
                    toast.success("Collection Added successfully", {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                } else {
                    toast.error(response.payload?.error || "Failed to Added collection", {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                }
            }
        } catch (error) {
            toast.error("Error saving collection", {
                position: toast.POSITION.TOP_RIGHT,
            });
        } finally {
            setIsSaving(false);
            setIsModalOpen(false);
            setEditMode(false);
        }
    };

    return (
        <div style={{height:'calc(100% - 40px)'}}>
            <div className="flex flex-col">
                <FormControl className="flex-1">
                    <Navbar
                        buttonText={"Add New Collection"}
                        linkText={"comp-collections"}
                        heading={"Component Collections"}
                        handleButtonClicked={handleAddNewCollection}
                    />
                    <CollectionsTable
                        collectionsData={componentCollections || []}
                        onUpdate={handleEditCollection}
                        onDelete={handleDeleteCollection}
                    />
                </FormControl>
            </div>

            <Suspense fallback={<div>Loading...</div>}>
                {isModalOpen && <AddCollectionModal
                    editMode={editMode}
                    onClose={() => setIsModalOpen(false)}
                    selectedItem={selectedItem}
                    isSaving={isSaving}
                    onSave={handleSaveOrUpdate}
                />}
            </Suspense>
        </div>
    );
};

export default ComponentCollections;
