import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { setCookieValue } from "../../../utils/handleCookie";

import api from "Api";

const initialState = {
  user: null,
  isInitialised: false,
  isAuthenticated: false,
  isAuthSuccess: false,
  isLoading: false,
};

export const login = createAsyncThunk(
  'auth/login',
  async (credentials, { rejectWithValue }) => {
    try {
     
      const response = await api.post(
        `${process.env.REACT_APP_API_URL}/api/admin/login`,
        credentials
      );
      const { userName, token } = response?.data?.user;
      const { profileImage } = response?.data.user.personal_information;

      if (
        response.data.user.role === 'SuperAdmin' ||
        response.data.user.role === 'Admin'
      ) {
        setCookieValue(
          response.data.user.token,
          response.data.user.refreshToken
        );
      }

      let role = response.data.user.role;
      return { userName, token, role, profileImage };
    } catch (error) {
      if (error.response && error.response.data && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue('An error occurred during login');
      }
    }
  }
);

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(login.fulfilled, (state, action) => {
        const { userName } = action.payload;
        state.isLoading = false;
        state.user = userName;
        state.isAuthSuccess = true;
        state.isAuthenticated = true;
      })
      .addCase(login.rejected, (state, action) => {
        state.isLoading = false;
        state.isAuthenticated = action.payload;
        state.errorMessage = action.payload
       
      });
  },
});

export default authSlice.reducer;
