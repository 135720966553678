import React, { useMemo, useState } from 'react';
import { useTable, useSortBy, usePagination } from 'react-table';
import Card from 'components/card';
import { FaEdit, FaSort, FaSortUp, FaSortDown, FaAngleDown, FaAngleUp } from 'react-icons/fa';
import { MdDelete } from 'react-icons/md';
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import {FENCE_COMPONENT_COLORS} from "../../../../variables/global-data/globalConstants";
import {capitalizeFirstLetter} from "../../../utils/utilFunctions";

const MyFenceStylesTable = ({ data, columns, onUpdate, onDelete }) => {
    const [expandedRow, setExpandedRow] = useState(null);

    const visibleColumns = columns?.filter(c => c.show);

    const memoizedData = useMemo(() => data?.map((item, index) => ({
        ...item,
        index: index + 1,
        fenceType: item.fenceType ? `${item.fenceType.type} (${item.fenceType.abbreviation})` : 'No Type',
        category: item.category ? `${item.category.categoryName} (${item.category.abbreviation})` : 'No Category',
        componentCollection: item.componentCollection ? `${item.componentCollection.collectionName} (${item.componentCollection.collectionId})` : 'No Collection',
        heights: item.heights ?
            <div className="flex flex-col justify-center w-full items-center">
                <ul className="list-disc">
                    {item.heights.map(height => <li className="text-start">{`${height} ${item.unitOfMeasure || "ft"}`}</li>)}
                </ul>
            </div>: 'No heights',
        colors: item.colors ?
            <div className="flex flex-col justify-center items-center">
                <ul className="list-disc">
                    {item.colors.map(color => <li className="text-start">{`${capitalizeFirstLetter(color)} (${FENCE_COMPONENT_COLORS[color] || color})`}</li>)}
                </ul>
            </div> : 'No colors',
        installationMethods: item?.material?.installationMethodDetail ?
            <div className="flex flex-col justify-center items-center">
                <ul className="list-disc">
                    {item?.material?.installationMethodDetail.map(ins => <li className="text-start">{ins.method}</li>)}
                </ul>
            </div> : 'No install methods'

    })), [data]);

    const memoizedColumns = useMemo(() => [
        {
            Header: 'S.no',
            accessor: 'index',
            Cell: ({ row }) => <span className="text-center">{row.values.index}</span>,
        },
        ...visibleColumns?.map(column => {
            if (column.accessors) {
                column.accessor = row => {
                    for (let accessor of column.accessors) {
                        if (row[accessor] !== undefined) {
                            return row[accessor];
                        }
                    }
                    return '';
                };
            }
            return {
                ...column,
                Cell: (props) => {
                    if (column.Header.toLowerCase() === 'image') {
                        return (
                            <div className="flex">
                                {
                                    props.cell.row.original.image ? <img src={props.cell.row.original.image} alt="img" className="max-h-[30px] w-[30px]" /> : <img key={column} src={''} alt="img" className="max-h-[30px] w-[30px]" />
                                }
                            </div>
                        );
                    }
                    return <span>{props.value}</span>;
                },
            };
        }).concat({
            Header: 'Actions',
            accessor: 'actions',
            Cell: ({ row }) => (
                <div className="flex justify-center items-center gap-2">
                    <button onClick={() => onUpdate(row.original)} className="cursor-pointer">
                        <FaEdit className="text-[22px] text-[#3b82f6]" />
                    </button>
                    <button onClick={() => onDelete(row.original)} className="cursor-pointer">
                        <MdDelete className="text-2xl text-[#FF0000]" />
                    </button>
                    <button title="Expand the row" onClick={() => setExpandedRow(xr => xr === row.index ? null : row.index)} className="text-center hover:bg-gray-100">
                        {expandedRow === row.index ? <FaAngleUp className="h-4 w-4" /> : <FaAngleDown className="h-4 w-4" />}
                    </button>
                </div>
            ),
        }),
    ], [columns, onUpdate, onDelete]);

    const handlePageSizeChange = (e) => {
        const newPageSize = Number(e.target.value);
        setPageSize(newPageSize);
    };

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        prepareRow,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: { pageIndex, pageSize },
    } = useTable(
        { columns: memoizedColumns, data: memoizedData, initialState: { pageIndex: 0, pageSize: 20 } },
        useSortBy,
        usePagination
    );

    return (
        <Card extra="w-full h-full p-2 overflow-auto">
            <table {...getTableProps()} className="w-full">
                <thead>
                {headerGroups.map(headerGroup => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map(column => (
                            <th {...column.getHeaderProps(column.getSortByToggleProps())} className="text-center border border-gray-200 px-3 py-1 text-xs dark:border-navy-700">
                                <div className="flex items-center justify-center">
                                    {column.render('Header')}
                                    {column.isSorted ? (column.isSortedDesc ? <FaSortDown /> : <FaSortUp />) : <FaSort />}
                                </div>
                            </th>
                        ))}
                    </tr>
                ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                {page.map((row, i) => {
                    prepareRow(row);
                    return (
                        <React.Fragment key={row.getRowProps().key}>
                            <tr {...row.getRowProps()}>
                                {row.cells.map(cell => (
                                    <td {...cell.getCellProps()} className="text-center text-xs border border-gray-200 px-1 whitespace-nowrap">
                                        {cell.column.Header === "Price" && "$ "}
                                        {cell.render('Cell')}
                                    </td>
                                ))}
                            </tr>
                            {expandedRow === row.index && (
                                <tr>
                                    <td colSpan={memoizedColumns.length} className="border border-2">
                                        <div className="grid grid-cols-3 gap-4 text-xs p-2">
                                            {columns.map((column, i) => (
                                                <div key={`detail-${i}`} className="p-2 rounded bg-gray-50">
                                                    <span className="font-semibold">{column.Header} : </span>
                                                    <span className="tracking-wider">{row.original[column.accessor || column.accessors[0]]}</span>
                                                </div>
                                            ))}
                                        </div>
                                    </td>
                                </tr>
                            )}
                        </React.Fragment>
                    );
                })}
                </tbody>
            </table>
            <div className="flex justify-between items-center p-2">
                <div>
                    <select
                        value={pageSize}
                        onChange={handlePageSizeChange}
                        className="outline-none p-1 border border-gray-300 rounded text-xs"
                    >
                        {[10, 20, 30, 40, 50].map(size => (
                            <option key={size} value={size}>
                                Show {size}
                            </option>
                        ))}
                    </select>
                </div>
            </div>
            <div className="pagination absolute bottom-0 right-2 flex w-full justify-end py-2">
                <button title="Previous Page" className="mr-2 px-1 bg-gray-100 cursor-pointer hover:bg-gray-300" onClick={() => previousPage()} disabled={!canPreviousPage}>
                    <IoIosArrowBack />
                </button>
                <span className="space-x-2">
                    Page {pageIndex + 1} of {pageOptions.length}
                </span>
                <button title="Next Page" className="ml-2 px-1 bg-gray-100 cursor-pointer hover:bg-gray-300" onClick={() => nextPage()} disabled={!canNextPage}>
                    <IoIosArrowForward />
                </button>
            </div>
        </Card>
    );
};

export default MyFenceStylesTable;
