import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../../Api";

const initialState = {
    data: [],
    status: "idle",
    isLoading: false,
    error: null,
};

export const addFenceCompsByCSV = createAsyncThunk(
    "fence/fence-components-csv",
    async (csvData, { rejectWithValue }) => {
        try {
            const response = await api.post(
                `${process.env.REACT_APP_API_URL}/api/fence/fence-components-csv`,
                csvData
            );

            if (response.data.success) {
                return response.data.data;
            } else {
                return rejectWithValue({
                    error: response.data.message || "Failed to add CSV data",
                });
            }
        } catch (error) {
            return rejectWithValue({ error: error?.response?.data?.message || error.message });
        }
    }
);

const fenceCompsCsvSlice = createSlice({
    name: "fenceCSV",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(addFenceCompsByCSV.pending, (state) => {
                state.status = "loading";
                state.isLoading = true;
            })
            .addCase(addFenceCompsByCSV.fulfilled, (state, action) => {
                state.status = "succeeded";
                state.isLoading = false;
            })
            .addCase(addFenceCompsByCSV.rejected, (state, action) => {
                state.status = "failed";
                state.isLoading = false;
                state.error = action.payload.error;
            });
    },
});

export default fenceCompsCsvSlice.reducer;
