import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../../Api";

const initialState = {
    data: [],
    status: "idle",
    isLoading: false,
    error: null,
};
// Create an async thunk for fetching component
export const fetchFenceTypes = createAsyncThunk(
    "fence/fetchTypes",
    async (_, thunkAPI) => {
        try {
            const response = await api.get(
                `${process.env.REACT_APP_API_URL}/api/fence/getFenceTypes`
            );

            return response.data.data;
        } catch (error) {
            // You can handle errors here if needed
            return thunkAPI.rejectWithValue({ error: error.message });
        }
    }
);

// Create an async thunk for adding a new fence type
export const addFenceType = createAsyncThunk(
    "fence/addFenceType",
    async (typeData, { rejectWithValue }) => {
        try {

            const response = await api.post(
                `${process.env.REACT_APP_API_URL}/api/fence/addFenceType`,
                typeData
            );

            // Assuming the response structure is { code, success, message, data }
            if (response.data.success) {
                // Component added successfully
                return response.data.data; // Assuming you want to return the added component data
            } else {
                // Handle the case where the request was successful but the server indicates failure
                return rejectWithValue({
                    error: response.data.message || "Failed to add component",
                });
            }
        } catch (error) {
            // Handle errors here if needed
            return rejectWithValue({ error: error?.response?.data?.message || error.message });
        }
    }
);

// Create an async thunk for updating a fence type
export const updateFenceType = createAsyncThunk(
    "fence/updateFenceType",
    async (typeData, thunkAPI) => {
        try {
            const response = await api.put(
                `${process.env.REACT_APP_API_URL}/api/fence/updateFenceType`,
                typeData
            );

            return response.data.data;
        } catch (error) {
            // You can handle errors here if needed
            return thunkAPI.rejectWithValue({ error: error.message });
        }
    }
);

// Create an async thunk for deleting a component
export const deleteFenceType = createAsyncThunk(
    "fence/deleteFenceType",
    async (id) => {
        try {
            await api.delete(
                `${process.env.REACT_APP_API_URL}/api/fence/deleteFenceType?id=${id}`
            );
            return id;
        } catch (error) {}
    }
);

const componentSlice = createSlice({
    name: "types",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder

            // fetch all components

            .addCase(fetchFenceTypes.pending, (state) => {
                state.status = "loading";
            })
            .addCase(fetchFenceTypes.fulfilled, (state, action) => {
                state.status = "succeeded";
                state.data = action.payload;
            })
            .addCase(fetchFenceTypes.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.error.message;
            })

            // add component

            .addCase(addFenceType.pending, (state) => {
                state.isLoading = true;
                state.status = "loading";
            })
            .addCase(addFenceType.fulfilled, (state, action) => {
                state.status = "succeeded";
                state.isLoading = false;
                state.data.push(action.payload);
            })
            .addCase(addFenceType.rejected, (state, action) => {
                state.isLoading = false;
                state.status = "failed";
                state.error = action.error.message;
            })

            // update component

            .addCase(updateFenceType.pending, (state) => {
                state.isLoading = true;
                state.status = "loading";
            })
            .addCase(updateFenceType.fulfilled, (state, action) => {
                state.status = "succeeded";
                state.isLoading = false;
                const updatedType = action.payload;
                const index = state.data.findIndex(
                    (type) => type._id === updatedType._id
                );
                if (index !== -1) {
                    state.data[index] = updatedType;
                }
            })
            .addCase(updateFenceType.rejected, (state, action) => {
                state.isLoading = false;
                state.status = "failed";
                state.error = action.error.message;
            })

            // delete component

            .addCase(deleteFenceType.fulfilled, (state, action) => {
                const id = action.payload;
                state.status = "succeeded";
                state.data = state.data.filter(
                    (type) => type._id !== id
                );
            });
    },
});

export default componentSlice.reducer;
