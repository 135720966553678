import Card from "components/card";
import React, { useCallback, useMemo, useState } from "react";
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import { FaEdit } from "react-icons/fa";
import { MdDelete } from "react-icons/md";

import { useDispatch } from "react-redux";
import {
  updateUsers,
  deleteUsers,
} from "../../../../redux/reducers/users/userSilce";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  Select,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  Input,
  useDisclosure,
  Button,
} from "@chakra-ui/react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DeleteConfirmationModal from "../../../../utils/DeleteConfirmationModal";
const UserTable = ({ tableData, setShouldRefetchTable }) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [editFormData, seteditFormData] = useState({});
  const [passwordNew, setPasswordNew] = useState();

  const [deleteId, setDeleteId] = useState("");

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const handleEdit = useCallback(
    (row) => {
      seteditFormData(row);
      onOpen();
    },
    [seteditFormData, onOpen]
  );
  const columns = useMemo(
    () => [
      {
        Header: "No",
        accessor: (row, index) => index + 1,
      },
      {
        Header: "Email",
        accessor: "email",
      },
      {
        Header: "Username",
        accessor: "userName",
      },
      {
        Header: "Role",
        accessor: "role",
      },
      {
        Header: "Personal number",
        accessor: "personal_information.mobileNumber",
      },
      {
        Header: "Profile Image",
        accessor: "personal_information.profileImage",
        Cell: ({ cell }) => (
          <img
            src={cell.value}
            alt="Profile "
            style={{ width: "50px", height: "50px", borderRadius: "50%" }}
          />
        ),
      },
      {
        Header: "Actions",
        accessor: "actions",
        Cell: ({ row }) => (
          <>
            <button onClick={() => handleEdit(row.original)}>
              <FaEdit className="text-[20px] text-[#0000FF]" />
            </button>
            &nbsp;&nbsp;&nbsp;
            <button
              onClick={() => {
                setIsDeleteModalOpen(true);

                setDeleteId(row.original._id);
              }}
            >
              <MdDelete className="text-[20px] text-[#FF0000]" />
            </button>
          </>
        ),
      },
    ],
    [handleEdit, setIsDeleteModalOpen, setDeleteId]
  );

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "password") {
      setPasswordNew(value);
    } else {
      seteditFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const data = useMemo(() => tableData, [tableData]);

  const tableInstance = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    initialState,
  } = tableInstance;
  initialState.pageSize = 11;

  const handleDelete = async () => {
    try {
      setIsLoading(true);

      const response = await dispatch(deleteUsers(deleteId));

      if (response.meta.requestStatus === "fulfilled") {
        toast.success("Successfully User deleted", {
          position: toast.POSITION.TOP_RIGHT,
        });
        setShouldRefetchTable(true);
        setIsDeleteModalOpen(false);
      } else {
        toast.error(`Network error`, {
          position: toast.POSITION.TOP_RIGHT,
        });
        setIsDeleteModalOpen(false);
      }
    } finally {
      setIsLoading(false);
    }
  };
  const handleSave = async () => {
    try {
      setIsLoading(true);
      if (editFormData.email && editFormData.password && editFormData.role) {
        let obj = {
          email: editFormData.email,
          id: editFormData._id,
          password: passwordNew !== undefined && passwordNew,
          role: editFormData.role,
        };
        const response = await dispatch(updateUsers(obj));

        if (response.meta.requestStatus === "fulfilled") {
          toast.success("Successfully user updated", {
            position: toast.POSITION.TOP_RIGHT,
          });
          seteditFormData({});
          onClose();
        } else {
          toast.error(`You have no permission to update this user!`, {
            position: toast.POSITION.TOP_RIGHT,
          });
          seteditFormData({});
          onClose();
        }
      } else {
        toast.error("Please Fill Mandatory Data!", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } catch (error) {
      toast.error(error, {
        position: toast.POSITION.TOP_RIGHT,
      });
    } finally {
      setIsLoading(false);
    }
  };
  const initialRef = React.useRef(null);
  const finalRef = React.useRef(null);
  return (
    <Card extra={"w-full h-full px-4"}>
      <div className="h-full overflow-x-auto ">
        {isLoading && (
          <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 transform">
            <div className="h-8 w-8 animate-spin rounded-full border-t-2 border-b-2 border-blue-500 border-blue-500"/>
          </div>
        )}
        {!isLoading && (
          <table
            className="mt-4 h-max w-full"
            variant="simple"
            color="gray-500"
            mb="24px"
            {...getTableProps()}
          >
            <thead>
              {headerGroups.map((headerGroup, index) => (
                <tr {...headerGroup.getHeaderGroupProps()} key={index}>
                  {headerGroup.headers.map((column, index) => (
                    <th
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      className="border-b border-gray-200 pr-[35px] pb-[10px] dark:!border-navy-700 text-start"
                      key={index}
                    >
                      <div className="text-xs font-bold tracking-wide text-gray-700">
                        {column.render("Header")}
                      </div>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {page.map((row, index) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()} key={index}>
                    {row.cells.map((cell, index) =>
                      cell.column.id === "actions" ? (
                        <td>
                          {row.cells.map(
                            (cell, index) =>
                              cell.column.id === "actions" && (
                                <div key={index}>{cell.render("Cell")}</div>
                              )
                          )}
                        </td>
                      ) : (
                        <td
                          {...cell.getCellProps()}
                          key={index}
                          className="py-2"
                        >
                          <p className="text-xs text-navy-800 dark:text-white">
                            {Array.isArray(cell.value)
                              ? cell.value.join(", ")
                              : cell.value}
                          </p>
                        </td>
                      )
                    )}
                  </tr>
                );
              })}
            </tbody>
          </table>
        )}
      </div>
      <DeleteConfirmationModal
        isOpen={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
        onDelete={() => handleDelete()}
      />
      <Modal
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}
        isOpen={isOpen}
        onClose={onClose}
      >
        <div className="main-modal-chakar main-modal-chakar-dark fixed top-0 left-0 h-full w-full ">
          <ModalOverlay />
          <ModalContent className="dark:bg-111c44 dark:text-white">
            <ModalHeader className="text-lg font-bold text-navy-700 dark:text-white">
              Edit User
            </ModalHeader>
            <div className="modalButton_close">
              <ModalCloseButton />
            </div>
            <ModalBody pb={6}>
              <FormControl>
                <FormLabel>Email: </FormLabel>
                <Input
                  className="w-full rounded-lg border border-solid border-gray-300 p-2 dark:border-[#202848] dark:bg-[#111c44]"
                  name="email"
                  defaultValue={editFormData.email}
                  onChange={handleInputChange}
                />
              </FormControl>{" "}
              <FormControl>
                <FormLabel>Password: </FormLabel>
                <Input
                  className="w-full rounded-lg border border-solid border-gray-300 p-2 dark:border-[#202848] dark:bg-[#111c44]"
                  name="password"
                  defaultValue={passwordNew}
                  onChange={handleInputChange}
                />
              </FormControl>
              <FormControl>
                <FormLabel>Role: </FormLabel>
                <Select
                  placeholder="Select role"
                  className="w-full rounded-lg border border-solid border-gray-300 p-2 dark:border-[#202848] dark:bg-[#111c44] dark:text-white"
                  name="role"
                  value={editFormData.role}
                  onChange={handleInputChange}
                >
                  <option value="Buyer">Buyer</option>
                  <option value="Seller">Seller</option>
                  <option value="Admin">Admin</option>
                </Select>
              </FormControl>
            </ModalBody>

            <ModalFooter>
              <Button
                colorScheme="blue"
                mr={3}
                className="linear mt-4 rounded-xl bg-brand-500 px-2 py-2 text-base font-medium text-white dark:bg-brand-400 dark:hover:bg-brand-300 dark:active:bg-brand-200"
                onClick={handleSave}
              >
                Save
              </Button>
              <Button
                onClick={onClose}
                className="linear mt-4 rounded-xl bg-brand-500 px-2 py-2 text-base font-medium text-white dark:bg-brand-400 dark:hover:bg-brand-300 dark:active:bg-brand-200"
              >
                Cancel
              </Button>
            </ModalFooter>
          </ModalContent>
        </div>
      </Modal>
    </Card>
  );
};

export default UserTable;
