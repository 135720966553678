import React from "react";
import {  Button } from "@chakra-ui/react";

const ConfirmationModal = ({ onSubmit,onCancel, headerText="Confirm Action", saveText="Save", cancelText='Cancel', mainText="Do you want to close?" }) => {
    return (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 h-full w-full z-50 flex items-center justify-center" id="my-modal">
            <div className="relative bg-white border shadow-lg rounded-md w-[600px] h-[200px] flex flex-col">
                {/*Header*/}
                <header className="px-6 py-2 border-b flex justify-between items-center z-10">
                    <p className="text-lg font-bold">{headerText}</p>
                </header>
                {/*Body*/}
                <main className="flex h-[60%] justify-center items-center">
                    <div className="text-gray-700 text-lg">
                        <span>{mainText}</span>
                    </div>
                </main>
                {/*footer*/}
                <footer className="px-5 py-1 border-t z-10">
                    <div className="flex justify-end space-x-3 text-lg">
                        <Button
                            className={`linear relative rounded px-6 text-md bg-gray-700 hover:bg-gray-500 py-1
                            active:bg-gray-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200 text-white transition duration-200`}
                            onClick={onCancel}
                        >
                            {cancelText}
                        </Button>
                        <Button
                            className={`linear relative rounded px-6 text-md bg-brand-500 hover:bg-brand-600 
                            active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200 text-white transition duration-200`}
                            onClick={onSubmit}
                        >
                            {saveText}
                        </Button>
                    </div>
                </footer>
            </div>
        </div>
    );
};

export default ConfirmationModal;
