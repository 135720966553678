import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../Api";

const initialState = {
    data: [],
    status: "idle",
    isLoading: false,
    error: null,
};

// Create async thunks for vendor operations
export const fetchVendors = createAsyncThunk(
    "vendor/fetchVendors",
    async (_, thunkAPI) => {
        try {
            const response = await api.get(`${process.env.REACT_APP_API_URL}/api/fence/getVendors`);
            return response.data.data;
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: error.message });
        }
    }
);

export const addVendor = createAsyncThunk(
    "vendor/addVendor",
    async (vendorData, { rejectWithValue }) => {
        try {
            const response = await api.post(`${process.env.REACT_APP_API_URL}/api/fence/addVendor`, vendorData);
            if (response.data.success) {
                return response.data.data;
            } else {
                return rejectWithValue({
                    error: response.data.message || "Failed to add vendor",
                });
            }
        } catch (error) {
            console.error(error, "Error while adding vendor");
            return rejectWithValue({ error: error.message });
        }
    }
);

export const updateVendor = createAsyncThunk(
    "vendor/updateVendor",
    async (vendorData, thunkAPI) => {
        try {
            const response = await api.put(`${process.env.REACT_APP_API_URL}/api/fence/updateVendor`, vendorData);
            return response.data.data;
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: error.message });
        }
    }
);

export const deleteVendor = createAsyncThunk(
    "vendor/deleteVendor",
    async (id, thunkAPI) => {
        try {
            await api.delete(`${process.env.REACT_APP_API_URL}/api/fence/deleteVendor?id=${id}`);
            return id;
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: error.message });
        }
    }
);

const vendorSlice = createSlice({
    name: "vendor",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchVendors.pending, (state) => {
                state.status = "loading";
            })
            .addCase(fetchVendors.fulfilled, (state, action) => {
                state.status = "succeeded";
                state.data = action.payload;
            })
            .addCase(fetchVendors.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.error.message;
            })
            .addCase(addVendor.pending, (state) => {
                state.isLoading = true;
                state.status = "loading";
            })
            .addCase(addVendor.fulfilled, (state, action) => {
                state.status = "succeeded";
                state.isLoading = false;
                state.data.push(action.payload);
            })
            .addCase(addVendor.rejected, (state, action) => {
                state.isLoading = false;
                state.status = "failed";
                state.error = action.error.message;
            })
            .addCase(updateVendor.pending, (state) => {
                state.isLoading = true;
                state.status = "loading";
            })
            .addCase(updateVendor.fulfilled, (state, action) => {
                state.status = "succeeded";
                state.isLoading = false;
                const index = state.data.findIndex(vendor => vendor._id === action.payload._id);
                if (index !== -1) {
                    state.data[index] = action.payload;
                }
            })
            .addCase(updateVendor.rejected, (state, action) => {
                state.isLoading = false;
                state.status = "failed";
                state.error = action.error.message;
            })
            .addCase(deleteVendor.fulfilled, (state, action) => {
                state.status = "succeeded";
                state.data = state.data.filter(vendor => vendor._id !== action.payload);
            });
    },
});

export default vendorSlice.reducer;
