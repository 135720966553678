import React from 'react';
import { FormControl } from "@chakra-ui/react";
import FloatingLabelInput from "../../../../../common-comps/FloatingLabelInput";

const SearchComponent = ({ onChange,placeholder }) => {
    const [searchTerm, setSearchTerm] = React.useState('');

    return (
        <FormControl className="flex justify-between items-center w-[25%]">
            <FloatingLabelInput
                id="search"
                type="text"
                label="Search"
                value={searchTerm}
                onChange={(e) => {
                    setSearchTerm(e.target.value)
                    onChange(e.target.value)
                }}
                placeholder={placeholder || "Search by description"}
                customClasses={{ inputClass: 'w-[300px] h-[30px] text-sm' }}
            />
        </FormControl>
    );
};

export default SearchComponent;
