import React, { useEffect, useState } from "react";
import NftCard from "../../../components/card/NftCard";
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    FormControl,
    FormLabel,
    Input,
    useDisclosure,
    Button,
} from "@chakra-ui/react";

import { useDispatch, useSelector } from "react-redux";
import { fetchShape, addShape } from "../../../redux/reducers/shape/shapeSlice";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Navbar from "../../../components/navbar";
const Elements = () => {
    const [formData, setFormData] = useState({
        title: "",
        selectedImage: null,
    });
    const [imagePreview, setImagePreview] = useState(null);
    const [loading, setLoading] = useState(false);

    const { isOpen, onOpen, onClose } = useDisclosure();

    const dispatch = useDispatch();
    const shapeData = useSelector((state) => state?.shape?.data);

    useEffect(() => {
        dispatch(fetchShape());
    }, [dispatch]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleImageChange = (e) => {
        const selectedImage = e.target.files[0];
        setFormData((prevData) => ({
            ...prevData,
            selectedImage,
        }));
        if (selectedImage) {
            const reader = new FileReader();

            reader.onloadend = () => {
                setImagePreview(reader.result);
            };

            reader.readAsDataURL(selectedImage);
        } else {
            setImagePreview(null);
        }
    };

    const handleSave = async () => {
        try {
            setLoading(true);
            if (formData.selectedImage && formData.title !== "") {
                let formDataAppend = new FormData();

                formDataAppend.append("name", formData?.title);
                formDataAppend.append("image", formData?.selectedImage);
                const response = await dispatch(addShape(formDataAppend));
                if (response.meta.requestStatus === "fulfilled") {
                    toast.success("Successfully Shape added", {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                    onClose();
                    setFormData({ title: "", selectedImage: null });
                    setImagePreview(null);
                } else {
                    toast.error(`Network error`, {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                    onClose();
                    setFormData({ title: "", selectedImage: null });
                    setImagePreview(null);
                }
            } else {
                toast.error("Please Fill Mandatory Data!", {
                    position: toast.POSITION.TOP_RIGHT,
                });
            }
        } catch (error) {
            toast.error(error, {
                position: toast.POSITION.TOP_RIGHT,
            });

            onClose();
        } finally {
            setLoading(false);
        }
    };

    const initialRef = React.useRef(null);
    const finalRef = React.useRef(null);

    return (
        <div>
            <Navbar
                buttonText={"Add Element"}
                linkText={"elements"}
                heading={"Elements"}
                handleButtonClicked={onOpen}
            />
            <div>
                <div className="grid grid-cols-1 gap-5 md:grid-cols-4">
                    {shapeData !== undefined &&
                    shapeData.length !== 0 &&
                    shapeData.map((item) => {
                        return (
                            <NftCard
                                bidders={[]}
                                title={item?.name}
                                image={item?.image}
                                key={item?.id}
                                itemId={item?._id}
                            />
                        );
                    })}
                </div>
            </div>

            <Modal
                initialFocusRef={initialRef}
                finalFocusRef={finalRef}
                isOpen={isOpen}
                onClose={onClose}
            >
                <div className="main-modal-chakar main-modal-chakar-dark fixed top-0 left-0 h-full w-full ">
                    {" "}
                    <ModalOverlay />
                    <ModalContent className="dark:bg-111c44 dark:text-white">
                        <ModalHeader className="text-lg font-bold text-navy-700 dark:text-white">
                            Add Shape
                        </ModalHeader>
                        <div className="modalButton_close">
                            <ModalCloseButton />
                        </div>

                        <ModalBody pb={6}>
                            <FormControl>
                                <FormLabel>Title</FormLabel>
                                <Input
                                    className="mb-2 w-full rounded-lg border border-solid border-gray-300 p-2 dark:border-[#202848] dark:bg-[#111c44]"
                                    ref={initialRef}
                                    placeholder="Title"
                                    name="title"
                                    value={formData.title}
                                    onChange={handleInputChange}
                                />
                            </FormControl>
                            {imagePreview && (
                                <img
                                    src={imagePreview}
                                    alt="Preview"
                                    style={{ maxWidth: "100px", maxHeight: "100px" }}
                                    className="mb-2"
                                />
                            )}
                            <FormControl mt={4}>
                                <label className="flex w-full cursor-pointer items-center justify-center rounded-lg bg-blue-500 px-4 py-2 text-white hover:bg-blue-600 dark:bg-[#7551ff]">
                                    <input
                                        type="file"
                                        onChange={handleImageChange}
                                        className="hidden"
                                    />
                                    Upload Image
                                </label>
                            </FormControl>
                        </ModalBody>

                        <ModalFooter>
                            <Button
                                className={`linear mt-4 flex items-center justify-center rounded mr-2 bg-brand-500 px-2 py-2 text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200`}
                                colorScheme="blue"
                                onClick={() => handleSave()}
                                isLoading={loading}
                                loadingText="Saving..."
                            >
                                {loading && (
                                    <div className="absolute inset-0 flex items-center justify-center">
                                        <div className="h-6 w-6 animate-spin rounded-full border-t-4 border-brand-700 border-t-brand-700 border-r-brand-500 border-b-brand-500"></div>
                                    </div>
                                )}
                                Save
                            </Button>
                            <Button
                                className="linear mt-4 flex items-center justify-center rounded bg-brand-500 px-2 py-2 text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
                                onClick={onClose}
                            >
                                Cancel
                            </Button>
                        </ModalFooter>
                    </ModalContent>
                </div>
            </Modal>
        </div>
    );
};

export default Elements;
