import React, { useEffect, useState } from "react";
import {
    FormControl,
    FormLabel,
    Input,
    Button,
    Select,
} from "@chakra-ui/react";
import { useDispatch } from "react-redux";
import { addUsers } from "../../../redux/reducers/users/userSilce";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Card from "components/card";
const AddUserModal = ({isOpen,onClose}) => {
    let formObj = {
        fname: "",
        lname: "",
        phone: "",
        email: "",
        stageForEmail: "personal",
        stageForphone: "primary",
        business_information: {
            business_name: "",
            mobile_number: "",
            email: "",
            email_type: "",
        },
        personal_information: {
            mobile_number: "",
            email: "",
            custom_fields: [{ field_name: "", value: "" }],
            addtional_info: "",
        },
        Imagename: "user-files",
        primary: false,
        files: [],
        address: {
            billing_address: {
                str1: "",
                str2: "",
                city: "",
                state: "",
                zip: "",
                country: "",
            },
            job_site_address: {
                str1: "",
                str2: "",
                city: "",
                state: "",
                zip: "",
                country: "",
                addtional_info: "",
                custom_fields: [{ field_name: "", value: "" }],
            },
        },

        billing_address_checkbox: false,
    };

    const dispatch = useDispatch();

    const [formData, setFormData] = useState(formObj);
    const [imagePreviews, setImagePreviews] = useState([]);
    const [loading, setLoading] = useState(false);

    const handleSave = async () => {
        try {
            setLoading(true);
            if (
                formData.stageForphone !== "primary" &&
                formData.business_information.mobile_number === ""
            ) {
                formData.business_information.mobile_number =
                    formData.personal_information.mobile_number;
                formData.personal_information.mobile_number = "";
            }
            if (
                formData.stageForEmail !== "personal" &&
                formData.business_information.email === ""
            ) {
                formData.business_information.email =
                    formData.personal_information.email;

                formData.personal_information.email = "";
                formData.business_information.email_type = formData?.stageForEmail;
            }
            if (
                (formData.fname || formData?.business_information?.business_name) &&
                (formData.email || formData.phone) &&
                formData?.address?.job_site_address?.str1 &&
                formData?.address?.job_site_address?.city &&
                formData?.address?.job_site_address?.state &&
                formData?.address?.job_site_address?.zip &&
                formData?.address?.job_site_address?.country
            ) {
                const response = await dispatch(addUsers(formData));
                if (response?.meta?.requestStatus === "fulfilled") {
                    toast.success("Successfully User added", {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                    // setFormData(formObj);
                } else {
                    toast.error(response?.data?.message, {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                    // setFormData(formObj);
                }
            } else {
                toast.error("Please Fill Mandatory Data!", {
                    position: toast.POSITION.TOP_RIGHT,
                });
            }
        } catch (error) {
            console.error("Error:", error);
            toast.error(error.message || "An error occurred", {
                position: toast.POSITION.TOP_RIGHT,
            });
        } finally {
            setLoading(false);
        }
    };

    const handleChange = (e) => {
        // Split the name into parts based on "."
        const { name, value, checked } = e.target;
        if (name === "primary") {
            setFormData((prevData) => ({
                ...prevData,
                [name]: checked,
            }));
        } else if (name === "billing_address_checkbox") {
            if (checked === true) {
                setFormData((prevData) => ({
                    ...prevData,
                    [name]: checked,
                    address: {
                        ...prevData.address,
                        billing_address: {
                            str1: prevData.address.job_site_address.str1 || "",
                            str2: prevData.address.job_site_address.str2 || "",
                            city: prevData.address.job_site_address.city || "",
                            state: prevData.address.job_site_address.state || "",
                            zip: prevData.address.job_site_address.zip || "",
                            country: prevData.address.job_site_address.country || "",
                        },
                    },
                }));
            } else {
                setFormData((prevData) => ({
                    ...prevData,
                    [name]: checked,
                }));
            }
        } else {
            const nameParts = name.split(".");

            // If there is only one part, update the top-level property
            if (nameParts.length === 1) {
                setFormData((prevData) => ({
                    ...prevData,
                    [name]: value,
                }));
            } else {
                // For nested properties or arrays
                setFormData((prevData) => {
                    let updatedData = { ...prevData };
                    let currentLevel = updatedData;

                    // Traverse through the nested structure
                    for (let i = 0; i < nameParts.length; i++) {
                        const currentPart = nameParts[i];

                        if (i === nameParts.length - 1) {
                            // If it's the last part, update the property
                            if (Array.isArray(currentLevel)) {
                                currentLevel[parseInt(currentPart)] = value;
                            } else {
                                currentLevel[currentPart] = value;
                            }
                        } else {
                            // If not the last part, move to the next level
                            if (Array.isArray(currentLevel)) {
                                currentLevel = currentLevel[parseInt(currentPart)];
                            } else {
                                currentLevel = currentLevel[currentPart];
                            }
                        }
                    }

                    return updatedData;
                });
            }
        }
    };
    const handleAvatarChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setImagePreviews(reader.result);
            };
            reader.readAsDataURL(file);
        }
    };
    if (!isOpen) return null;

    return (
        <div className="fixed top-3 w-[70%] max-h-[70%] shadow-xl z-40">
            <Card>
                <div className="rounded-[20px] px-6 py-3">
                    <div className="flex justify-start text-lg font-bold text-navy-700 dark:text-white py-2">
                        Add User
                    </div>

                    <div className="flex flex-col space-y-4">
                        <div className=" border-[#cbd5e0]-600 border  px-5 ">
                            <div className=" lg text-black mb-4 mt-4 flex justify-start text-lg  font-bold dark:text-white">
                                User Information
                            </div>
                            <div className="mb-4 mt-4 flex flex-col md:flex-row md:space-x-4">
                                <FormControl>
                                    <FormLabel>First Name: </FormLabel>
                                    <Input
                                        className="w-full rounded-lg border border-solid border-gray-300 p-2 dark:border-[#202848] dark:bg-[#111c44]"
                                        placeholder="first name"
                                        name="fname"
                                        value={formData?.fname}
                                        onChange={(e) => handleChange(e)}
                                    />
                                </FormControl>
                                <FormControl className="">
                                    <FormLabel>Last Name</FormLabel>
                                    <Input
                                        className=" w-full rounded-lg border border-solid border-gray-300 p-2 dark:border-[#202848] dark:bg-[#111c44]"
                                        placeholder="last name"
                                        name="lname"
                                        value={formData?.lname}
                                        onChange={(e) => handleChange(e)}
                                    />
                                </FormControl>
                                <FormControl className="tags_input ">
                                    <FormLabel>Company Name</FormLabel>
                                    <Input
                                        className=" w-full rounded-lg border border-solid border-gray-300 p-2 dark:border-[#202848] dark:bg-[#111c44]"
                                        placeholder="company name"
                                        name="business_information.business_name"
                                        value={formData?.business_information?.business_name}
                                        onChange={(e) => handleChange(e)}
                                    />
                                </FormControl>
                                <FormControl className="tags_input checkboxUser flex items-center gap-2">
                                    <Input
                                        className="h-[13px] w-[13px] rounded-lg border border-solid border-gray-300 p-2 dark:border-[#202848] dark:bg-[#111c44]"
                                        type="checkbox"
                                        name="primary"
                                        value={formData?.primary}
                                        onChange={(e) => handleChange(e)}
                                    />{" "}
                                    <span>Use company name as Primary</span>
                                </FormControl>
                                <div className="">
                                    <div className="flex items-center space-x-4">
                                        <div className="w-20 h-20 rounded-full overflow-hidden border border-gray-300">
                                            {imagePreviews ? (
                                                <img src={imagePreviews} alt="Avatar preview" className="w-full h-full object-cover" />
                                            ) : (
                                                <div className="w-full h-full flex items-center justify-center text-gray-400">No image</div>
                                            )}
                                        </div>
                                        <label className="cursor-pointer rounded-lg bg-blue-500 px-4 py-2 text-sm text-white hover:bg-blue-600 dark:bg-[#7551ff]">
                                            <span>Upload Image</span>
                                            <input
                                                type="file"
                                                onChange={handleAvatarChange}
                                                className="hidden"
                                                accept="image/*"
                                            />
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className=" border-[#cbd5e0]-600 border  px-5 ">
                            <div className="lg text-black mt-3 flex justify-start text-lg  font-bold dark:text-white">
                                Contact Information
                            </div>
                            <div className="flex w-full">
                                <div className="my-2 flex flex-col md:flex-row md:space-x-4 mr-2 w-[50%]">
                                    <FormControl className="flex-1">
                                        <FormLabel>Add phone: </FormLabel>
                                        <Input className="w-full rounded-lg border border-solid border-gray-300 p-2 dark:border-[#202848] dark:bg-[#111c44]"
                                               name={
                                                   formData.stageForphone !== "primary"
                                                       ? "business_information.mobile_number"
                                                       : "personal_information.mobile_number"
                                               }
                                               value={
                                                   formData.stageForphone !== "primary" &&
                                                   formData.business_information.mobile_number !== ""
                                                       ? formData?.business_information?.mobile_number
                                                       : formData?.personal_information?.mobile_number
                                               }
                                               onChange={(e) => {
                                                   handleChange(e);
                                                   setFormData((prevData) => ({
                                                       ...prevData,
                                                       phone: e.target.value,
                                                   }));
                                               }}
                                        />
                                    </FormControl>
                                    <FormControl className="w-[25%]">
                                        <FormLabel>Stage: </FormLabel>
                                        <Select
                                            // placeholder="Select stages"
                                            className="w-full rounded-lg border border-solid border-gray-300 p-2 dark:border-[#202848] dark:bg-[#111c44]"
                                            name="stageForphone"
                                            value={formData?.stageForphone}
                                            onChange={(e) => handleChange(e)}
                                        >
                                            <option value="primary">Primary</option>
                                            <option value="main">Main</option>
                                            <option value="work">Work</option>
                                            <option value="home">Home</option>
                                            <option value="mobile">Mobile</option>
                                            <option value="fax">Fax</option>
                                            <option value="other">Other</option>
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className="my-2 flex flex-col md:flex-row md:space-x-4 w-[50%]">
                                    <FormControl className="flex-1 w-[50%]">
                                        <FormLabel>Add email: </FormLabel>
                                        <Input
                                            className="w-full rounded-lg border border-solid border-gray-300 p-2 dark:border-[#202848] dark:bg-[#111c44]"
                                            name={
                                                formData.stageForEmail !== "personal"
                                                    ? "business_information.email"
                                                    : "personal_information.email"
                                            }
                                            value={
                                                formData.stageForEmail !== "personal" &&
                                                formData.business_information.email !== ""
                                                    ? formData?.business_information?.email
                                                    : formData?.personal_information?.email
                                            }
                                            onChange={(e) => {
                                                handleChange(e);
                                                setFormData((prevData) => ({
                                                    ...prevData,
                                                    email: e.target.value,
                                                }));
                                            }}
                                        />
                                    </FormControl>
                                    <FormControl className="flex-1 w-[50%]">
                                        <FormLabel>Stage: </FormLabel>
                                        <Select
                                            // placeholder="Select stages"
                                            className="w-full rounded-lg border border-solid border-gray-300 p-2 dark:border-[#202848] dark:bg-[#111c44]"
                                            name="stageForEmail"
                                            value={formData?.stageForEmail}
                                            onChange={(e) => handleChange(e)}
                                        >
                                            <option value="work">Work</option>
                                            <option value="personal">Personal</option>
                                            <option value="other">Other</option>
                                        </Select>
                                    </FormControl>
                                </div>
                            </div>

                            <div className="grid grid-cols-8 ">
                                {imagePreviews.map((preview, index) => (
                                    <img
                                        key={index}
                                        src={preview}
                                        alt={`Preview ${index + 1}`}
                                        style={{ maxWidth: "100px", maxHeight: "100px" }}
                                        className="mb-2"
                                    />
                                ))}
                            </div>
                        </div>

                        <div className=" border-[#cbd5e0]-600 border  px-5 ">
                            <div className="lg text-black mb-4 mt-4 flex justify-start text-lg  font-bold dark:text-white">
                                Job site address
                            </div>
                            <div className="mb-4 mt-4 flex flex-col md:flex-row md:space-x-4">
                                <FormControl>
                                    <FormLabel>Street 1: </FormLabel>
                                    <Input
                                        className="w-full rounded-lg border border-solid border-gray-300 p-2 dark:border-[#202848] dark:bg-[#111c44]"
                                        name="address.job_site_address.str1"
                                        value={formData?.address?.job_site_address?.str1}
                                        onChange={(e) => handleChange(e)}
                                    />
                                </FormControl>
                                <FormControl>
                                    <FormLabel>Street 2: </FormLabel>
                                    <Input
                                        className="w-full rounded-lg border border-solid border-gray-300 p-2 dark:border-[#202848] dark:bg-[#111c44]"
                                        name="address.job_site_address.str2"
                                        value={formData?.address?.job_site_address?.str2}
                                        onChange={(e) => handleChange(e)}
                                    />
                                </FormControl>
                                <FormControl>
                                    <FormLabel>City: </FormLabel>
                                    <Input
                                        className="w-full rounded-lg border border-solid border-gray-300 p-2 dark:border-[#202848] dark:bg-[#111c44]"
                                        name="address.job_site_address.city"
                                        value={formData?.address?.job_site_address?.city}
                                        onChange={(e) => handleChange(e)}
                                    />
                                </FormControl>
                                <FormControl>
                                    <FormLabel>State: </FormLabel>
                                    <Input
                                        className="w-full rounded-lg border border-solid border-gray-300 p-2 dark:border-[#202848] dark:bg-[#111c44]"
                                        name="address.job_site_address.state"
                                        value={formData?.address?.job_site_address?.state}
                                        onChange={(e) => handleChange(e)}
                                    />
                                </FormControl>
                                <FormControl>
                                    <FormLabel>Zip / Postal Code: </FormLabel>
                                    <Input
                                        className="w-full rounded-lg border border-solid border-gray-300 p-2 dark:border-[#202848] dark:bg-[#111c44]"
                                        name="address.job_site_address.zip"
                                        value={formData?.address?.job_site_address?.zip}
                                        onChange={(e) => handleChange(e)}
                                    />
                                </FormControl>
                                <FormControl>
                                    <FormLabel>Country: </FormLabel>
                                    <Input
                                        className="w-full rounded-lg border border-solid border-gray-300 p-2 dark:border-[#202848] dark:bg-[#111c44]"
                                        name="address.job_site_address.country"
                                        value={formData?.address?.job_site_address?.country}
                                        onChange={(e) => handleChange(e)}
                                    />
                                </FormControl>

                                <FormControl className="tags_input checkboxUser flex items-center gap-2">
                                    <Input
                                        className=" h-[13px] w-[13px] rounded-lg border border-solid border-gray-300 p-2 dark:border-[#202848] dark:bg-[#111c44]"
                                        type="checkbox"
                                        name="billing_address_checkbox"
                                        value={formData?.billing_address_checkbox}
                                        onChange={(e) => handleChange(e)}
                                    />{" "}
                                    <span>Billing address is the same</span>
                                </FormControl>
                            </div>
                        </div>
                        <div className="border-[#cbd5e0]-600 border px-5 ">
                            <div className="lg text-black mb-4 mt-4 flex justify-start text-lg  font-bold dark:text-white">
                                Billing address
                            </div>
                            <div className="mb-4 mt-4 flex flex-col md:flex-row md:space-x-4">
                                <FormControl>
                                    <FormLabel>Street 1: </FormLabel>
                                    <Input
                                        className="w-full rounded-lg border border-solid border-gray-300 p-2 dark:border-[#202848] dark:bg-[#111c44]"
                                        name="address.billing_address.str1"
                                        value={formData?.address?.billing_address?.str1}
                                        onChange={(e) => handleChange(e)}
                                    />
                                </FormControl>
                                <FormControl>
                                    <FormLabel>Street 2: </FormLabel>
                                    <Input
                                        className="w-full rounded-lg border border-solid border-gray-300 p-2 dark:border-[#202848] dark:bg-[#111c44]"
                                        name="address.billing_address.str2"
                                        value={formData?.address?.billing_address?.str2}
                                        onChange={(e) => handleChange(e)}
                                    />
                                </FormControl>
                                <FormControl>
                                    <FormLabel>City: </FormLabel>
                                    <Input
                                        className="w-full rounded-lg border border-solid border-gray-300 p-2 dark:border-[#202848] dark:bg-[#111c44]"
                                        name="address.billing_address.city"
                                        value={formData?.address?.billing_address?.city}
                                        onChange={(e) => handleChange(e)}
                                    />
                                </FormControl>
                                <FormControl>
                                    <FormLabel>State: </FormLabel>
                                    <Input
                                        className="w-full rounded-lg border border-solid border-gray-300 p-2 dark:border-[#202848] dark:bg-[#111c44]"
                                        name="address.billing_address.state"
                                        value={formData?.address?.billing_address?.state}
                                        onChange={(e) => handleChange(e)}
                                    />
                                </FormControl>
                                <FormControl>
                                    <FormLabel>Zip / Postal Code: </FormLabel>
                                    <Input
                                        className="w-full rounded-lg border border-solid border-gray-300 p-2 dark:border-[#202848] dark:bg-[#111c44]"
                                        name="address.billing_address.zip"
                                        value={formData?.address?.billing_address?.zip}
                                        onChange={(e) => handleChange(e)}
                                    />
                                </FormControl>
                                <FormControl>
                                    <FormLabel>Country: </FormLabel>
                                    <Input
                                        className="w-full rounded-lg border border-solid border-gray-300 p-2 dark:border-[#202848] dark:bg-[#111c44]"
                                        name="address.billing_address.country"
                                        value={formData?.address?.billing_address?.country}
                                        onChange={(e) => handleChange(e)}
                                    />
                                </FormControl>
                            </div>
                        </div>

                        <div className="flex w-full justify-end">
                            <Button
                                onClick={onClose}
                                className="mr-2 w-[15%] h-10 rounded-xl text-gray-700 border border-gray-300 hover:bg-gray-100">
                            Cancel
                            </Button>
                            <Button
                                className={`linear relative h-10 w-[15%] rounded-xl ${
                                    loading
                                        ? "cursor-not-allowed bg-gray-400"
                                        : "bg-brand-500 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
                                } py-[12px] text-base font-medium text-white transition duration-200`}
                                colorScheme="blue"
                                onClick={handleSave}
                                isLoading={loading}
                                loadingText="Saving..."
                            >
                                {loading && (
                                    <div className="absolute inset-0 h-full flex items-center justify-center">
                                        <div className="h-full animate-spin rounded-full border-t-4 border-brand-700 border-t-brand-700 border-r-brand-500 border-b-brand-500"></div>
                                    </div>
                                )}
                                Add User
                            </Button>
                        </div>
                    </div>
                </div>
            </Card>
        </div>
    );
};

export default AddUserModal;
