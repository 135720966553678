import InputField from "components/fields/InputField";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { login } from "../../redux/reducers/auth/authSlice";
import { useNavigate } from "react-router-dom";
import { getCookieValue } from "../../utils/handleCookie";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function SignIn() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [data, setData] = useState({
    email: "",
    password: "",
  });
  const [loading, setLoading] = useState(false);
  const { authToken } = getCookieValue();

  useEffect(() => {
    if (authToken) {
      // User is already logged in, redirect to admin page
      navigate("/admin");
    }
  }, [authToken, navigate]);
  const handleChange = (e) => {
    setData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };
  const handleSubmit = async () => {
    try {
      setLoading(true);
      if (data.email !== "" && data.password !== "") {
        const response = await dispatch(login(data));
        if (response.payload.role !== undefined) {
          toast.success("Successfully login", {
            position: toast.POSITION.TOP_RIGHT,
          });
          localStorage.setItem("username", response?.payload?.userName);
          localStorage.setItem("profileImage", response?.payload?.profileImage);

          setLoading(false);

          setLoading(false);

          navigate("/admin");
        } else {
          toast.error(response.payload, {
            position: toast.POSITION.TOP_RIGHT,
          });
          setLoading(false);
        }
      } else {
        toast.error("Please Fill Mandatory Data!", {
          position: toast.POSITION.TOP_RIGHT,
        });
        setLoading(false);
      }
    } catch (error) {
      toast.error(error, {
        position: toast.POSITION.TOP_RIGHT,
      });
      setLoading(false);
    }
  };
  return (
    <div className="mt-16 mb-16 flex h-full w-full items-center justify-center px-2 md:mx-0 md:px-0 lg:mb-10 lg:items-center lg:justify-start">
      {/* Sign in section */}
      <div className="mt-[10vh] w-full max-w-full flex-col items-center md:pl-4 lg:pl-0 xl:max-w-[420px]">
        <h4 className="mb-2.5 text-4xl font-bold text-navy-700 dark:text-white">
          Sign In
        </h4>
        <p className="mb-9 ml-1 text-base text-gray-600">
          Enter your email and password to sign in!
        </p>
        {/* <div className="mb-6 flex h-[50px] w-full items-center justify-center gap-2 rounded-xl bg-lightPrimary hover:cursor-pointer dark:bg-navy-800">
          <div className="rounded-full text-xl">
            <FcGoogle />
          </div>
          <h5 className="text-sm font-medium text-navy-700 dark:text-white">
            Sign In with Google
          </h5>
        </div> */}
        {/* <div className="mb-6 flex items-center  gap-3">
          <div className="h-px w-full bg-gray-200 dark:bg-navy-700" />
          <p className="text-base text-gray-600 dark:text-white"> or </p>
          <div className="h-px w-full bg-gray-200 dark:bg-navy-700" />
        </div> */}
        {/* Email */}
        <InputField
          variant="auth"
          extra="mb-3"
          label="Email*"
          placeholder="mail@simmmple.com"
          id="email"
          // type="text"

          name="email"
          onChange={(e) => handleChange(e)}
        />

        {/* Password */}
        <InputField
          variant="auth"
          extra="mb-3"
          label="Password*"
          placeholder="Min. 8 characters"
          id="password"
          type="password"
          name="password"
          onChange={(e) => handleChange(e)}
        />
        {/* Checkbox */}
        <div className="mb-4 flex items-center justify-between px-2">
          {/* <div className="flex items-center">
            <Checkbox />
            <p className="ml-2 text-sm font-medium text-navy-700 dark:text-white">
              Keep me logged In
            </p>
          </div> */}
          {/* <a
            className="text-sm font-medium text-brand-500 hover:text-brand-600 dark:text-white"
            href=" "
          >
            Forgot Password?
          </a> */}
        </div>
        <button
          onClick={() => handleSubmit()}
          className={`linear relative mt-2 w-full rounded-xl ${
            loading
              ? "cursor-not-allowed bg-gray-400"
              : "bg-brand-500 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
          } py-[12px] text-base font-medium text-white transition duration-200`}
          disabled={loading}
        >
          {loading && (
            <div className="absolute inset-0 flex items-center justify-center">
              <div className="h-6 w-6 animate-spin rounded-full border-t-4 border-brand-700 border-t-brand-700 border-r-brand-500 border-b-brand-500"></div>
            </div>
          )}
          {loading ? "Signing In..." : "Sign In"}
        </button>

        {/* <div className="mt-4">
          <span className=" text-sm font-medium text-navy-700 dark:text-gray-600">
            Not registered yet?
          </span>
          <a
            href=" "
            className="ml-1 text-sm font-medium text-brand-500 hover:text-brand-600 dark:text-white"
          >
            Create an account
          </a>
        </div> */}
      </div>
    </div>
  );
}
