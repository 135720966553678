// Import necessary libraries
import axios from "axios";
import {
  setCookieValue,
  removeTokenCookie,
  getCookieValue,
} from "utils/handleCookie";

// Create an Axios instance with default configuration
const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  //   withCredentials: true, // Set this to true if you need to include credentials (like cookies) with your requests
});

// Add a request interceptor
api.interceptors.request.use(
  async (config) => {
    // Do something before each request is sent
    // For example, you can attach the authentication token to the headers
    const { authToken } = getCookieValue();
    if (authToken) {
      config.headers.Authorization = `Bearer ${authToken}`;
    }
    return config;
  },
  (error) => {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
api.interceptors.response.use(
  (response) => {
    // Do something with successful response
    return response;
  },
  async (error) => {
    // Do something with response error
    const originalRequest = error.config;

    // Check if the error is due to an expired token
    if (error.response.status === 403 && !originalRequest._retry) {
      originalRequest._retry = true;

      // Refresh the token (you need to implement this function)
      try {
        const newToken = await refreshAuthToken();

        // Update the Authorization header with the new token
        originalRequest.headers.Authorization = `Bearer ${newToken}`;

        // Retry the original request with the new token
        return api(originalRequest);
      } catch (refreshError) {
        console.log("Error during token refresh:", refreshError);
        throw refreshError;
      }
    } else {
      console.log("Other response error:", error);
      throw error;
    }
    // return Promise.reject(error);
  }
);

// Function to refresh the authentication token (you need to implement this)
async function refreshAuthToken() {
  const { refreshToken } = getCookieValue();

  try {
    const response = await api.post(
      `${process.env.REACT_APP_API_URL}/api/users/getNewAccessToken`,
      { refreshToken }
    );

    const newToken = response.data.token;

    // Update the token in the cookie
    let cookieValue = newToken;
    removeTokenCookie();
    setCookieValue(cookieValue);

    return newToken;
  } catch (error) {
    if (
      error.response &&
      (error.response.status === 401 || error.response.status === 403)
    ) {
      // Handle the case where both access and refresh tokens are expired
      // Remove tokens from the cookie
      removeTokenCookie();
      console.error(
        "Both access and refresh tokens are expired. User needs to log in again."
      );
      throw error; // Propagate the error to the calling code if needed
    } else {
      // Handle other errors when refreshing the token
      console.error("Error refreshing token:", error);
      throw error; // Propagate the error to the calling code if needed
    }
  }
}

export default api;
