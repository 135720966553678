import React, { useMemo } from "react";
import { useTable } from "react-table";
import Card from "components/card";
import { FaEdit } from "react-icons/fa";
import { MdDelete } from "react-icons/md";

const TypesTable = ({ typesData, onUpdate, onDelete }) => {
    const data = useMemo(() => typesData.map((item, index) => ({
        ...item,
        index: index + 1,  // Adding an index property
    })), [typesData]);

    const columns = useMemo(() => [
        {
            Header: 'Index',
            accessor: 'index',
        },
        {
            Header: 'Type',
            accessor: 'type',
        },
        {
            Header: 'Abbreviation',
            accessor: 'abbreviation',  // Define a column for abbreviation
        },
        {
            Header: 'Actions',
            accessor: 'actions',
            Cell: ({ row }) => (
                <div className="flex gap-2">
                    <button onClick={() => onUpdate(row.original)}>
                        <FaEdit className="text-lg text-[#3b82f6]" />
                    </button>
                    <button onClick={() => onDelete(row.original)}>
                        <MdDelete className="text-[20px] text-[#FF0000]" />
                    </button>
                </div>
            ),
        }
    ], [onDelete, onUpdate]);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = useTable({ columns, data });

    return (
        <Card extra="w-full h-full p-4">
            <table {...getTableProps()} className="w-full">
                <thead>
                {headerGroups.map(headerGroup => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map(column => (
                            <th {...column.getHeaderProps()} className="border-b border-gray-200 pb-3 text-start dark:border-navy-700 text-xs">
                                {column.render('Header')}
                            </th>
                        ))}
                    </tr>
                ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                {rows.map(row => {
                    prepareRow(row);
                    return (
                        <tr {...row.getRowProps()} className="border-b border-gray-200">
                            {row.cells.map(cell => {
                                return (
                                    <td {...cell.getCellProps()} className="p-3 text-xs">
                                        {cell.render('Cell')}
                                    </td>
                                );
                            })}
                        </tr>
                    );
                })}
                </tbody>
            </table>
        </Card>
    );
};

export default TypesTable;
