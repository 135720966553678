import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchFenceTypes } from "../../../../../redux/reducers/fence/types-slice/fenceTypeSlice";

const AddCategoryModal = ({ isOpen, onClose, editMode, onSave, isSaving, selectedItem }) => {
    const dispatch = useDispatch();
    const fencesTypes = useSelector(state => state?.fenceTypes?.data);

    const [categoryData, setCategoryData] = useState({
        _id: selectedItem?._id || '',
        categoryName: selectedItem?.categoryName || '',
        abbreviation: selectedItem?.abbreviation || '',
        fenceTypeId: selectedItem?.fenceTypeId || ''
    });

    useEffect(() => {
        if (fencesTypes.length === 0) {
            dispatch(fetchFenceTypes()); // Fetch fence types if not already loaded
        }
    }, [dispatch, fencesTypes.length]);

    useEffect(() => {
        // Initialize categoryData when selectedItem changes or fencesTypes is loaded
        if (selectedItem) {
            setCategoryData({
                _id: selectedItem._id,
                categoryName: selectedItem.categoryName,
                abbreviation: selectedItem.abbreviation,
                fenceTypeId: selectedItem?.fenceType?._id || (fencesTypes.length ? fencesTypes[0]._id : '')
            });
        } else if (fencesTypes.length) {
            setCategoryData(cd => ({ ...cd, fenceTypeId: fencesTypes[0]._id }));
        }
    }, [selectedItem, fencesTypes]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setCategoryData(prev => ({ ...prev, [name]: value }));
    };

    const handleAddCategory = () => {
        onSave(categoryData);
        onClose();
    };

    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 z-50 overflow-auto bg-smoke-500">
            <div className="flex items-start justify-center min-h-screen">
                <div className="relative p-10 top-[100px] left-[50px] bg-white rounded shadow-lg">
                    <div className="flex items-center justify-between">
                        <h2 className="text-lg font-bold">{editMode ? "Update " : "Add New"} Category</h2>
                        <button onClick={onClose} className="text-2xl font-semibold">&times;</button>
                    </div>
                    <div className="mt-4">
                        <div className="grid md:grid-cols-2 gap-4">
                            <div className="pb-2">
                                <label htmlFor="categoryName" className="text-[16px] font-bold cursor-auto text-gray-900">
                                    Category Name
                                </label>
                                <input
                                    type="text"
                                    id="categoryName"
                                    name="categoryName"
                                    placeholder="Enter category name"
                                    value={categoryData.categoryName}
                                    onChange={handleChange}
                                    className="w-full mt-1 px-3 py-2 border border-gray-500 rounded"
                                />
                            </div>
                            <div className="pb-2">
                                <label htmlFor="abbreviation" className="text-[16px] font-bold cursor-auto text-gray-900">
                                    Abbreviation
                                </label>
                                <input
                                    type="text"
                                    id="abbreviation"
                                    name="abbreviation"
                                    placeholder="Enter abbreviation e.g. (Fittings => FT)"
                                    value={categoryData.abbreviation}
                                    onChange={handleChange}
                                    className="w-full mt-1 px-3 py-2 border border-gray-500 rounded"
                                />
                            </div>
                            <div className="pb-2">
                                <label htmlFor="fenceTypeId" className="text-sm font-medium text-gray-700">Fence Type</label>
                                <select
                                    id="fenceTypeId"
                                    name="fenceTypeId"
                                    value={categoryData.fenceTypeId}
                                    onChange={handleChange}
                                    className="w-full mt-1 px-3 py-2 border border-gray-500 rounded text-gray-800"
                                >
                                    {fencesTypes.map((type) => (
                                        <option key={type._id} value={type._id}>{type.type}</option>
                                    ))}
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="flex justify-center mt-4 space-x-2">
                        <button onClick={onClose} className="px-4 py-2 text-gray-700 border border-gray-300 rounded hover:bg-gray-100">
                            Cancel
                        </button>
                        <button disabled={isSaving} onClick={handleAddCategory} className="disabled:opacity-50 px-4 py-2 text-white bg-blue-500 rounded hover:bg-blue-600">
                            {editMode ? "Update" : "Add"} Category
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AddCategoryModal;
