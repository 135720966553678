import { configureStore } from "@reduxjs/toolkit";
import authSlice from "./reducers/auth/authSlice";
import shapeSlice from "./reducers/shape/shapeSlice";
import linesSlice from "./reducers/lines/linesSlice";
import userSilce from "./reducers/users/userSilce";
import componentSlice from "./reducers/fence/fence-component-slice/fenceComponentSlice";
import fenceTypesSlice from "./reducers/fence/types-slice/fenceTypeSlice";
import fenceStylesSlice from "./reducers/fence/styles-slice/fenceStyleSlice";
import myFenceStylesSlice from "./reducers/fence/fences/myFenceStylesSlice";
import componentCollectionsSlice from "./reducers/fence/comp-collections/componentCollectionsSlice"; // Import the new slice
import fenceComponentCategoriesSlice from "./reducers/fence/fence-component-categories/fenceComponentCategoriesSlice";
import fenceVendorSlice from "./reducers/vendor/vendorSlice";
import fenceBuilderSlice from "./reducers/fence/fence-builder-redux/fenceBuilderSlice";
import fenceCompsCsvSlice from "./reducers/fence/fence-comps-csv/fenceCompsCsvSlice";

const store = configureStore({
  reducer: {
    user: authSlice,
    shape: shapeSlice,
    line: linesSlice,
    userDetail: userSilce,
    fenceComponents: componentSlice,
    fenceTypes: fenceTypesSlice,
    fenceStyles: fenceStylesSlice,
    componentCollections: componentCollectionsSlice, // Add the slice to the store
    fenceComponentCategories: fenceComponentCategoriesSlice,
    myFenceStyles: myFenceStylesSlice,
    fenceVendors: fenceVendorSlice,
    fenceBuilder:fenceBuilderSlice,
    fenceCompsCsv:fenceCompsCsvSlice
  },
});

export default store;
