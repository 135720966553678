import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "Api";

const initialState = {
  data: [],
  status: "idle",
  isLoading: false,
  error: null,
};
export const fetchLine = createAsyncThunk(
  "lines/fetchLines",
  async (_, thunkAPI) => {
    try {
      const response = await api.get(
        `${process.env.REACT_APP_API_URL}/api/line/lines`
      );
      return response.data.data;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const addLine = createAsyncThunk(
  "lines/addLines",
  async (newLine, { rejectWithValue }) => {
    try {
      const response = await api.post(
        `${process.env.REACT_APP_API_URL}/api/line/addLine`,
        newLine
      );

      if (response.data.success) {
        return response.data.data;
      } else {
        return rejectWithValue({
          error: response.data.message || "Failed to add lines",
        });
      }
    } catch (error) {
      console.error(error, "Error while adding lines");
      return rejectWithValue({ error: error.message });
    }
  }
);

export const updateLine = createAsyncThunk(
  "lines/updateLines",
  async (updatedLine, thunkAPI) => {
    try {
      const response = await api.put(
        `${process.env.REACT_APP_API_URL}/api/line/updateLine`,
        updatedLine
      );

      return response.data.data;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const deleteLine = createAsyncThunk(
  "lines/deleteLine",
  async (lineId) => {
    try {
      await api.delete(
        `${process.env.REACT_APP_API_URL}/api/line/deleteLine/${lineId}`
      );
      return lineId;
    } catch (error) {}
  }
);

const lineSlice = createSlice({
  name: "lines",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchLine.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchLine.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.data = action.payload;
      })
      .addCase(fetchLine.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(addLine.pending, (state) => {
        state.isLoading = true;
        state.status = "loading";
      })
      .addCase(addLine.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.isLoading = false;
        state.data.push(action.payload);
      })
      .addCase(addLine.rejected, (state, action) => {
        state.isLoading = false;
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(updateLine.fulfilled, (state, action) => {
        const updatedLine = action.payload;
        const index = state.data.findIndex(
          (lines) => lines._id === updatedLine._id
        );
        if (index !== -1) {
          state.data[index] = updatedLine;
        }
      })
      .addCase(deleteLine.fulfilled, (state, action) => {
        const lineId = action.payload;
        state.status = "succeeded";
        state.data = state.data.filter((lines) => lines._id !== lineId);
      });
  },
});

export default lineSlice.reducer;
