import { toast } from 'react-toastify';

/**
 * Shows a dynamic toast notification.
 * @param {string} message - Message to display in the toast.
 * @param {('success'|'error')} type - Type of the toast, controls the toast styling.
 * @param {number} duration - Duration for which the toast should be visible.
 * @param {string} position - Position of the toast on the screen.
 */
export const showToast = (message, type = 'success', duration = 2000, position = toast.POSITION.TOP_RIGHT) => {
    const options = {
        position,
        autoClose: duration,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    };

    if (type === 'success') {
        toast.success(message, options);
    } else if (type === 'error') {
        toast.error(message, options);
    } else {
        toast.info(message, options);
    }
};
