import React from "react";
import Dropdown from "components/dropdown";
import { Link } from "react-router-dom";
import { RiMoonFill, RiSunFill } from "react-icons/ri";
import { removeCookies } from "utils/handleCookie";

const SidebarCard = () => {
  const [darkmode, setDarkmode] = React.useState(false);
  const Username = localStorage.getItem("username");
  const profileImg = localStorage.getItem("profileImage");

  const handleLogout = () => {
    removeCookies();
  };
  return (
      <div className="relative flex border border-2 flex-grow items-center justify-around gap-2 rounded-full bg-white px-2 py-1 shadow-xl shadow-500 dark:!bg-navy-800 dark:shadow-none border-gray-300 md:w-[85px] md:flex-grow-0 md:gap-1 xl:w-[70px] xl:gap-2">
        <div
            className="cursor-pointer text-gray-600"
            onClick={() => {
              if (darkmode) {
                document.body.classList.remove("dark");
                setDarkmode(false);
              } else {
                document.body.classList.add("dark");
                setDarkmode(true);
              }
            }}
        >
          {darkmode ? (
              <RiSunFill className="h-4 w-4 text-gray-600 dark:text-white" />
          ) : (
              <RiMoonFill className="h-4 w-4 text-gray-600 dark:text-white" />
          )}
        </div>
        {/* Profile & Dropdown */}

        <Dropdown
            button={
              <div className="h-8 w-8 overflow-hidden rounded-full">
                {profileImg !== "undefined" && profileImg ? (
                    <img
                        className="h-full w-full object-cover"
                        src={`${process.env.REACT_APP_API_URL}/uploads/profileImages/${profileImg}`}
                        alt="Profile"
                    />
                ) : (
                    <div className="flex select-none h-full w-full items-center justify-center bg-gray-300 text-lg font-bold text-gray-600 rounded-full">
                      {Username ? Username.charAt(0).toUpperCase() : ""}
                    </div>
                )}
              </div>
            }
            children={
              <div className="flex w-56 flex-col justify-start rounded-[20px] bg-white bg-cover bg-no-repeat shadow-xl shadow-shadow-500 dark:!bg-navy-700 dark:text-white dark:shadow-none">
                <div className="p-4">
                  <div className="flex items-center gap-2">
                    <p className="text-sm font-bold text-navy-700 dark:text-white">
                      {Username && Username}
                    </p>{" "}
                  </div>
                </div>
                <div className="h-px w-full bg-gray-200 dark:bg-white/20 " />

                <div className="flex flex-col p-4">
                  <a
                      href="/admin/resetPassword"
                      className="text-sm text-gray-800 dark:text-white hover:dark:text-white"
                  >
                    Reset Password
                  </a>

                  <a
                      href=" "
                      className="mt-3 text-sm font-medium text-red-500 hover:text-red-500"
                      onClick={() => handleLogout()}
                  >
                    Log Out
                  </a>
                </div>
              </div>
            }
            classNames={"py-2 top-8 -left-[180px] w-max"}
        />
      </div>

  );
};

export default SidebarCard;
