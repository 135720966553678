import {FENCE_COMPONENT_COLORS} from "../../../../../variables/global-data/globalConstants";
import {useEffect} from "react";

export function getParticularValue(comp,type="diameter"){
    let val=null;
    if (comp && (type === "diameter" || type === "size1")) {
        val = comp?.size1PostDiameterInches || comp?.size1PostDiameter || comp?.postDiameter
    }
    return val;
}
export function getSizeOfComponent(comp,key="size1",exact=false){
    let val=null;
    if (comp) {
        const sizeKey = Object.keys(comp).find(o=>exact ? o === key :o.includes(key))
        if (sizeKey) val = comp[sizeKey]
    }
    return val;
}

export function getParsedValue(value) {
    return typeof value === "string" ? parseFloat(value) : value
}
export function getIntegerValue(value) {
    return typeof value === "string" ? parseInt(value) : value
}
export function getConvertedSizeValue(value,type="inch"){
    let val = getParsedValue(value)
    if (type === "inch"){
        val = val * 12; // 1 ft === 12 inch
    }
    return val

}

export function convertInchesToFeet(inches) {
    if (!inches){
        return ''
    }
    if (typeof inches === 'string') {
        inches = parseFloat(inches);
    }

    if (isNaN(inches)) {
        throw new Error('Input must be a number or a string representing a number');
    }

    return inches / 12;
}
export function getUpdateSystemNumber(partNumber,value,heights,colors){
    if (!partNumber || !value) return null;
    let parts = partNumber.split("-");  // Split the string into an array of parts
    // Replace the fourth part (index 3, since arrays are zero-indexed) with "1.375"
    parts[3] = value;
    parts[5] = heights?.length === 1 ? heights[0] : parts[5];
    parts[6] = colors?.length === 1 ? FENCE_COMPONENT_COLORS[colors[0].toLowerCase()] : parts[6];
    // Join the parts back together with "-"
    partNumber = parts.join("-");
    return partNumber;
}
export function generateNewSystemNumber(partNumber,size1,size2,size3,color){
    if (!partNumber) return null;
    let parts = partNumber.split("-");  // Split the string into an array of parts
    // Replace the fourth part (index 3, since arrays are zero-indexed) with "1.375"
    parts[3] = size1 || parts[3];
    parts[4] = size2 || parts[4];
    parts[5] = size3 || parts[5];
    parts[6] = color ? FENCE_COMPONENT_COLORS[color.toLowerCase()] : parts[6];
    partNumber = parts.join("-");
    return partNumber;
}

export function updateCollectionsByCategory(categoryType,categories,setCollections,setSelectedCollection,setSelectedCollectionName,setSystemNo){
    if (categoryType) {
        const collections = categories?.find(c => c.sectionName === categoryType.sectionName)?.componentCollections;
        setCollections(collections || []);
        if (collections?.length) {
            setSelectedCollectionName && setSelectedCollectionName(collections[0].collectionName);
            setSelectedCollection(collections[0]);
        }else {
            setSelectedCollectionName && setSelectedCollectionName('');
            setSelectedCollection(null);
            setSystemNo && setSystemNo('')
        }
    }
}


// ADD FENCE MODAl HOOKS


export function useComponentSystemNoUpdater(selectedComponent,selectedFenceDataValues,setSelectedCompSysNo) {
    useEffect(() => {
        let parts = selectedComponent?.systemNumber?.split("-");  // Split the string into an array of parts
        if (parts?.length){
            const color = selectedFenceDataValues?.colors?.length === 1 ? selectedFenceDataValues?.colors[0] : undefined
            parts[6] = (color && FENCE_COMPONENT_COLORS[color?.toLowerCase()] === parts[6]) ? parts[6] : 'XX';
            setSelectedCompSysNo(parts.join("-"));
        }else {
            setSelectedCompSysNo('no component selected');
        }
    }, [selectedComponent,selectedFenceDataValues]);
}

export function useCategoryUpdater(selectedCategory,categories, setCollections,setSelectedCollection,setSelectedCollectionName,setGeneratedSystemNumber) {
    useEffect(() => {
        updateCollectionsByCategory(selectedCategory,categories, setCollections,setSelectedCollection,setSelectedCollectionName,setGeneratedSystemNumber)
    }, [categories, selectedCategory]);
}
function getFilteredComponentsBySize3(components,selectedHeight,selectedColor){
    let comps = []
    components?.forEach(comp=>{
        const height = getSizeOfComponent(comp,'size3')
        if (height === getConvertedSizeValue(selectedHeight,'inch') && comp.color?.toLowerCase() === selectedColor ){
            comps.push(comp)
        }
    })
    return comps;
}
function getFilteredComponentsBySize1(components,selectedHeight,selectedColor,selectedPostComponent){
    let comps = []
    components?.forEach(comp=>{
        const diameter = getSizeOfComponent(comp,'size1');
        const selectedDiameterForPost = getSizeOfComponent(selectedPostComponent,'size1');
        if (diameter === selectedDiameterForPost && comp.color?.toLowerCase() === selectedColor){
            comps.push(comp)
        }
    })
    return comps;
}
function getFilteredComponentsByColor(components,selectedColor){
    let comps = []
    components?.forEach(comp=>{
        if (comp.color?.toLowerCase() === selectedColor){
            comps.push(comp)
        }
    })
    return comps;
}
function getFilteredComponentsByDiameter(components,selectedDiameter){
    let comps = []
    components?.forEach(comp=>{
        if (getSizeOfComponent(comp,'size1') === selectedDiameter?.diameter){
            comps.push(comp)
        }
    })
    return comps;
}
export function useCollectionUpdater(selectedCollection,setCollectionComponents,setSelectedComponent,setSelectedComponentName) {
    useEffect(() => {
        setCollectionComponents(selectedCollection?.components || [])
        setSelectedComponent(selectedCollection?.components?.length ? selectedCollection?.components[0] : null)
        setSelectedComponentName && setSelectedComponentName(selectedCollection?.components?.length ? selectedCollection?.components[0].name : null)
    }, [selectedCollection]);
}

export function useUpdateFilteredComponents(selectedCollection,selectedFenceDataValues,setCollectionComponents,setSelectedComponent,setSelectedComponentName,size="size3",selectedPostComponent, shouldFilter=true){
    useEffect(()=>{
        const comps = (shouldFilter ? size === "size1" ? getFilteredComponentsBySize1(selectedCollection?.components,selectedFenceDataValues?.height,selectedFenceDataValues?.color,selectedPostComponent) :
            getFilteredComponentsBySize3(selectedCollection?.components,selectedFenceDataValues?.height,selectedFenceDataValues?.color) : selectedCollection?.components) || []
        setCollectionComponents(comps || [])
        setSelectedComponent(comps?.length ? comps[0] : null)
        setSelectedComponentName && setSelectedComponentName(comps?.length ? comps[0].name : '')
    },[selectedCollection,selectedFenceDataValues])
}
export function useUpdateFilteredDiametersByColor(selectedCollection,selectedFenceDataValues,setSelectedPostDiameters,setSelectedPostDiameter){
    useEffect(()=>{
        const comps = getFilteredComponentsByColor(selectedCollection?.components, selectedFenceDataValues?.color) || [];
        const diameters = Array.from(new Set(comps.map(comp => getSizeOfComponent(comp, 'size1'))))
            .map(diameter => ({ diameter }));
        setSelectedPostDiameters(diameters);
        setSelectedPostDiameter(diameters.length ? diameters[0] : null);
    },[selectedCollection,selectedFenceDataValues])
}
export function useUpdateFilteredCompsByDiameter(selectedDiameter,selectedPostCollection,setCollectionComponents,setSelectedComponent,setSelectedComponentName){
    useEffect(()=>{
        const comps = getFilteredComponentsByDiameter(selectedPostCollection?.components,selectedDiameter) || []
        setCollectionComponents(comps || [])
        setSelectedComponent(comps?.length ? comps[0] : null)
        setSelectedComponentName && setSelectedComponentName(comps?.length ? comps[0].name : '')
    },[selectedDiameter])
}
export function useUpdateFilteredComponentsByColor(selectedCollection,selectedFenceDataValues,setCollectionComponents,setSelectedComponent,setSelectedComponentName){
    useEffect(()=>{
        const comps = getFilteredComponentsByColor(selectedCollection?.components,selectedFenceDataValues?.color) || []
        setCollectionComponents(comps || [])
        setSelectedComponent(comps?.length ? comps[0] : null)
        setSelectedComponentName && setSelectedComponentName(comps?.length ? comps[0].name : '')
    },[selectedCollection,selectedFenceDataValues])
}

export function getUniqueItems(a){
    return [... new Set(a)]
}