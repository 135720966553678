import {getParsedValue} from "../admin/fence/fences/fence-builder/addFenceUtils";

export function capitalizeFirstLetter(string) {
    if (!string) return string;
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export function convertToCamelCase(str) {
    return str
        ?.replace(/\s(.)/g, function($1) { return $1.toUpperCase(); })
        .replace(/\s/g, '')
        .replace(/^(.)/, function($1) { return $1.toLowerCase(); });
}
export function camelCaseToNormal(str) {
    // Step 1: Add a space before each uppercase letter.
    const spaced = str.replace(/([A-Z])/g, ' $1');

    // Step 2: Capitalize the first letter of the resulting string and make the rest lower case.
    const result = spaced.charAt(0).toUpperCase() + spaced.slice(1).toLowerCase();

    return result.trim();
}


export function extractFileName(url) {
    try {
        const parsedUrl = new URL(url);
        const pathname = parsedUrl.pathname;  // "/fenceImages/1717233261419_Profile.PNG"
        const fileName = pathname.split('/').pop();  // Extract the last part of the path
        return fileName;
    } catch (error) {
        console.error("Invalid URL provided:", error);
        return null;  // Return null if the URL is invalid
    }
}

function convertInchesToFeet(inches) {
    if (typeof inches === 'string') {
        inches = parseFloat(inches);
    }

    if (isNaN(inches)) {
        console.log("Input must be a number or a string representing a number in (convertInchesToFeet) function")
        return 0;
    }

    return inches / 12;
}


export const convertToFeet = (height, unit) => {
    switch (unit) {
        case 'inch':
            return (height / 12).toFixed(2); // Converts inches to feet
        case 'cm':
            return (height / 30.48).toFixed(2); // Converts centimeters to feet
        default:
            return height; // Assumes the height is already in feet if not inches or cm
    }
};

export const makeValuesNumbered=(list)=>{
    return list.map(val=>getParsedValue(val))
}