import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import IconButton from "../../../../common-comps/tailwind-css/buttons/IconButton";
import { MdOutlineAdd } from "react-icons/md";
import { FormControl, FormLabel } from "@chakra-ui/react";
import {
    fetchFenceComponentCategories,
    addFenceComponentCategory,
    updateFenceComponentCategory,
    deleteFenceComponentCategory,
} from "../../../../../redux/reducers/fence/fence-component-categories/fenceComponentCategoriesSlice";
import CategoriesTable from "./CategoriesTable";
import AddCategoryModal from "./AddCategoryModal";
import { toast } from "react-toastify";
import Navbar from "../../../../../components/navbar";

const ComponentCategories = () => {
    const dispatch = useDispatch();
    const categories = useSelector((state) => state.fenceComponentCategories.allCategories.categories);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [componentCategories, setComponentCategories] = useState([]);
    const [isSaving, setIsSaving] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);
    const [editMode, setEditMode] = useState(false);

    useEffect(() => {
        dispatch(fetchFenceComponentCategories());
    }, [dispatch]);

    useEffect(() => {
        setComponentCategories(categories || [])
    }, [categories]);

    const handleAddNewCategory = () => {
        setSelectedItem(null);
        setEditMode(false);
        setIsModalOpen(true);
    };

    const handleEditCategory = (category) => {
        const cate = componentCategories.find(c => c._id === category._id)
        if (!cate) return
        setSelectedItem(cate);
        setEditMode(true);
        setIsModalOpen(true);
    };

    const handleDeleteCategory = async (categoryData) => {
        setIsSaving(true);
        try {
            const id = categoryData._id;
            await dispatch(deleteFenceComponentCategory(id));
            toast.success("Category deleted successfully", {
                position: toast.POSITION.TOP_RIGHT,
            });
            setIsSaving(false);
            setIsModalOpen(false);
            setEditMode(false);
        } catch (error) {
            toast.error("Error deleting category", {
                position: toast.POSITION.TOP_RIGHT,
            });
        } finally {
            setIsSaving(false);
        }
    };

    const handleSaveOrUpdate = async (categoryData) => {
        setIsSaving(true);
        try {
            let res;
            if (editMode) {
                // Update the existing category
                res = await dispatch(updateFenceComponentCategory(categoryData));
            } else {
                // Remove _id property when adding a new category
                const newData = { ...categoryData };
                delete newData._id;
                res = await dispatch(addFenceComponentCategory(newData));
            }

            if (res.error) {
                toast.error(res.error.message || "An error occurred", {
                    position: toast.POSITION.TOP_CENTER,
                });
            } else {
                toast.success(`${editMode ? "Category updated" : "Category added"} successfully`, {
                    position: toast.POSITION.TOP_RIGHT,
                });
                dispatch(fetchFenceComponentCategories()); // Refetch categories to update the list
            }
        } catch (error) {
            toast.error("Error saving category: " + error.message, {
                position: toast.POSITION.TOP_RIGHT,
            });
        } finally {
            setIsSaving(false);
            setIsModalOpen(false);
            setEditMode(false);
        }
    };


    return (
        <div style={{height:'calc(100% - 40px)'}}>
            <div className="flex flex-col">
                <FormControl className="flex-1">
                    <Navbar
                        buttonText={"Add New Category"}
                        linkText={"component-categories"}
                        heading={"Fence Component Categories"}
                        handleButtonClicked={handleAddNewCategory}
                    />
                    <CategoriesTable
                        categoriesData={componentCategories || []}
                        onUpdate={handleEditCategory}
                        onDelete={handleDeleteCategory}
                    />
                </FormControl>
            </div>

            <AddCategoryModal
                isOpen={isModalOpen}
                editMode={editMode}
                onClose={() => setIsModalOpen(false)}
                selectedItem={selectedItem}
                isSaving={isSaving}
                onSave={handleSaveOrUpdate}
            />
        </div>
    );
};

export default ComponentCategories;
