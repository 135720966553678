import {FENCE_COMPONENT_COLORS} from "../../../../../variables/global-data/globalConstants";

export const COMPONENT_COLUMNS = [
    { Header: "Name", accessor: "name", mappingKeys:['Name','name','Component Name'], type:'text', show:true },
    { Header: "Description", accessor: "description",mappingKeys:['description'], type:'text', show:true },
    { Header: "Size 1 / Post Diameter (inches)", accessor: "size1",mappingKeys:['size 1','size1','Size 1 / Post Diameter inches'], checked: true,show:true },
    { Header: "Size 2 / Wall / Rail Diameter (inches)", accessor: "size2",mappingKeys:['size 2','size2','Size 2 / Wall / Rail Diameter (inches)'], checked: true,show:true },
    { Header: "Size 3 / Height / Length (inches)", accessor: "size3",mappingKeys:['size 3','size3','Size 3 / Height / Length (inches)'], checked: true,show:true },
    { Header: "Primary Vendor Part Number", accessor: "primaryVendorPartNumber",type:'number',mappingKeys: ['Primary Vendor Part number'], show:true},
    { Header: "Full Bundle/Case/Pallet", accessor: "fullBundleCasePallet", mappingKeys:['full bundle /case/pallet'], checked: true,show:true },
    { Header: "Sold In Increments Of", accessor: "soldInIncrementsOf",mappingKeys:['sold in increments of'],type:'number', show:true},
    { Header: "Unit of Measure", accessor: "unitOfMeasure",mappingKeys:['Unit of measure'],type:'text', show:true},
    { Header: "Weight", accessor: "weight",type:'number',mappingKeys:['Weight','weight'], show:true},
    { Header: "Price", accessor: "price",type:'number',mappingKeys:['prices','price'], show:true },
    { Header: "Color", accessor: "color",type:'text',mappingKeys:['Color','color'], show:false },
    { Header: "Color Abbreviation", accessor: "colorAbbreviation",mappingKeys:['Color Abreviation'], show:false},
    { Header: "Images", accessor: "images",mappingKeys:['image','images'], show: false },
    { Header: "Cost", accessor: "cost",type:'number',mappingKeys:['cost'], show:true},
    { Header: "Brand/Manufacturer", accessor: "brandManufacturer",mappingKeys:['Brand / Manufacturer'], show:true},
    { Header: "SKU", accessor: "sku",mappingKeys:['SKU','sku'], show:true},
    { Header: "Part Number", accessor: "partNumber",mappingKeys:['Part Number'], show:true},
    { Header: "UPC Barcode", accessor: "upcBarcode",mappingKeys:['UPC Bar Code'], show:true},
    { Header: "MPN", accessor: "mpn",mappingKeys:['MPN','mpn'], show:true},
    { Header: "EAN-13 or JAN Barcode",mappingKeys:['EAN-13 or JAN barcode'], accessor: "ean13OrJanBarcode", show:true},
    { Header: "Reference Number", accessor: "referenceNumber",mappingKeys:['Reference Number'],type:'number', show:true},
    { Header: "Active", accessor: "active",mappingKeys:['Active'], show:false},
];

export const initialCompData = {
    name: '', description: '', size1: '', size2: '', size3: '',
    primaryVendorPartNumber: '',
    fullBundleCasePallet: '',
    soldInIncrementsOf: '',
    unitOfMeasure: '',
    weight: '',
    price: '',
    color: Object.keys(FENCE_COMPONENT_COLORS)[0],
    colorAbbreviation: FENCE_COMPONENT_COLORS[Object.keys(FENCE_COMPONENT_COLORS)[0]],
    images: [],
    cost: '',
    brandManufacturer: '',
    sku: '',
    partNumber: '',
    upcBarcode: '',
    mpn: '',
    ean13OrJanBarcode: '',
    referenceNumber: '',
    active: 'Yes'
}


export const Categories_Sizes_Mapping = {
    FT:{
        size1:'Post Diameter inches',
        size2:'Wall / Rail Diameter (inches)',
        size3:'Height / Length (inches)'
    },
    GH:{
        size1:'Post Diameter inches',
        size2:'Wall / Rail Diameter (inches)',
        size3:'Height / Length (inches)'
    },
    AC:{
        size1:'Post Diameter inches',
        size2:'Wall / Rail Diameter (inches)',
        size3:'Height / Length (inches)'
    },
    GT:{
        size1:'min opening',
        size2:'max opening',
        size3:'height'
    },
    PT:{
        size1:'Post Diameter inches',
        size2:'Wall / Rail Diameter (inches)',
        size3:'Height / Length (inches)'
    },
    CM:{
        size1:'Diamond Size',
        size2:'Wire diameter',
        size3:'Height / Length (inches)'
    },
}

// const components_logic = {
//     post,
//     postCap,
//     tensionBand,
//     braceBand,
//     railEnd,
//     railEndComboCup,
//     tensionBar,
//     nutsAndBolts,
//     trussRod,
//     trussRodTightener,
//     braceRail,
//     chainLinkMesh,
//     tensionWire,
//     topRail,
//     lineRailTies,
//     barb
// }