import React from "react";

// Auth Imports
import SignIn from "views/auth/SignIn";

// Icon Imports
import {
  // MdHome,
  // MdOutlineShoppingCart,
  // MdBarChart,
  // MdLock,
  MdPerson,
  MdFence,
} from "react-icons/md";
import { BsSignpostSplitFill } from "react-icons/bs"
import { FaToriiGate } from "react-icons/fa";
import { GiWrappingStar } from "react-icons/gi";
import { CgStyle } from "react-icons/cg";
import { VscGroupByRefType } from "react-icons/vsc";
// import { IoListSharp } from "react-icons/io5";
import { FaShapes, FaUsers,FaList } from "react-icons/fa";
import { TbComponents } from "react-icons/tb";
// import { FiType } from "react-icons/fi";
// import Shape from "views/admin/fence/Shape";
// import Lines from "views/admin/fence/Lines";
// import Type from "views/admin/fence/Type";

import Users from "views/admin/users/Users";
import Types from "./views/admin/fence/types/Types";
import Elements from "./views/admin/elements/Elements";
import MyFenceStyles from "./views/admin/fence/fences/MyFenceStyles";
import ComponentCategories from "./views/admin/fence/components/comp-categories/ComponentCategories";
import FenceVendors from "./views/admin/vendors/FenceVendors";
import ComponentCollections from "./views/admin/fence/components/comp-collections/ComponentCollections";
import FenceComponents from "./views/admin/fence/components/fence-components/FenceComponents";

// import ResetPassword from "views/admin/resetPassword";
// import FenceStyles from "./views/admin/fence/styles/FenceStyles";
// import CsvDataSheet from "./views/admin/fence/components/csv data sheet/CsvDataSheet";

const routes = [
  {
    name: "Users",
    layout: "/admin",
    path: "users",
    icon: <FaUsers className="h-6 w-6" />,
    component: <Users />,
  },
  {
    name: "Types",
    layout: "/admin",
    path: "types",
    icon: <VscGroupByRefType className="h-6 w-6" />,
    component: <Types />,
  },
  // {
  //   name: "Styles",
  //   layout: "/admin",
  //   path: "styles",
  //   icon: <CgStyle className="h-6 w-6" />,
  //   component: <FenceStyles />,
  // },
  {
    name: "Components",
    layout: "/admin",
    path: "fence-components",
    icon: <TbComponents className="h-6 w-6" />,
    component: <FenceComponents />,
  },
  // {
  //   name: "CSV file upload",
  //   layout: "/admin",
  //   path: "fence-components-csv",
  //   icon: <TbComponents className="h-6 w-6" />,
  //   component: <CsvDataSheet />,
  // },
  {
    name: "Collections",
    layout: "/admin",
    path: "collections",
    icon: <BsSignpostSplitFill className="h-5 w-5" />,
    component: <ComponentCollections />,
  },
  {
    name: "Categories",
    layout: "/admin",
    path: "comp-categories",
    icon: <VscGroupByRefType className="h-5 w-5" />,
    component: <ComponentCategories />,
  },
  {
    name: "Fence Styles",
    layout: "/admin",
    path: "fence-styles",
    icon: <MdFence className="h-6 w-6" />,
    component: <MyFenceStyles />,
  },
  {
    name: "Vendors",
    layout: "/admin",
    path: "vendors",
    icon: <FaList className="h-4 w-5" />,
    component: <FenceVendors />,
  },
  {
    name: "Elements",
    layout: "/admin",
    path: "elements",
    icon: <FaShapes className="h-5 w-6" />,
    component: <Elements />,
  },
  // {
  //   name: "Main Dashboard",
  //   layout: "/admin",
  //   path: "default",
  //   icon: <MdHome className="h-6 w-6" />,
  //   component: <MainDashboard />,
  // },
  // {
  //   name: "Reset Password",
  //   layout: "/admin",
  //   path: "resetPassword",
  //   icon: <MdPerson className="hidden h-6 w-6" />,
  //   component: <ResetPassword />,
  // },
  // {
  //   name: "NFT Marketplace",
  //   layout: "/admin",
  //   path: "nft-marketplace",
  //   icon: <MdOutlineShoppingCart className="h-6 w-6" />,
  //   component: <NFTMarketplace />,
  //   secondary: true,
  // },
  // {
  //   name: "Data Tables",
  //   layout: "/admin",
  //   icon: <MdBarChart className="h-6 w-6" />,
  //   path: "data-tables",
  //   component: <DataTables />,
  // },
  // {
  //   name: "Profile",
  //   layout: "/admin",
  //   path: "profile",
  //   icon: <MdPerson className="h-6 w-6" />,
  //   component: <Profile />,
  // },
  {
    // name: "Sign In",
    layout: "/auth",
    path: "sign-in",
    icon: <MdPerson className="hidden h-6 w-6" />,
    component: <SignIn />,
  },
  // {
  //   name: "RTL Admin",
  //   layout: "/rtl",
  //   path: "rtl",
  //   icon: <MdHome className="h-6 w-6" />,
  //   component: <RTLDefault />,
  // },
];
export default routes;
