import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../Api";

const initialState = {
  data: [],
  status: "idle",
  isLoading: false,
  error: null,
};
export const fetchUsers = createAsyncThunk(
  "user/fetchUsers",
  async (_, thunkAPI) => {
    try {
      const response = await api.get(
        `${process.env.REACT_APP_API_URL}/api/admin/getUser`
      );

      return response.data.data;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const addUsers = createAsyncThunk(
  "user/addUsers",
  async (newUsers, { rejectWithValue }) => {
    try {
      const response = await api.post(
        `${process.env.REACT_APP_API_URL}/api/admin/addUser`,
        newUsers
      );
      if (response.data.success) {
        return response.data.data;
      } else {
        return rejectWithValue({
          error: response.data.data.message || "Failed to add user",
        });
      }
    } catch (error) {
      console.error(error, "Error while adding user");
      return rejectWithValue({ error: error });
    }
  }
);

export const updateUsers = createAsyncThunk(
  "user/updateUsers",
  async (updatedUsers, thunkAPI) => {
    try {
      const response = await api.put(
        `${process.env.REACT_APP_API_URL}/api/admin/updateUser`,
        updatedUsers
      );

      return response.data.data;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const deleteUsers = createAsyncThunk(
  "user/deleteUsers",
  async (userId, thunkAPI) => {
    try {
      const response = await api.delete(
        `${process.env.REACT_APP_API_URL}/api/admin/deleteUser/${userId}`
      );
      return response.data.data;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);
export const resetPassword = createAsyncThunk(
  "user/resetPassword",
  async (passwords, thunkAPI) => {
    try {
      const response = await api.post(
        `${process.env.REACT_APP_API_URL}/api/users/changeUserPassword`,
        passwords
      );

      return response.data.data;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);
const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchUsers.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchUsers.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.data = action.payload;
      })
      .addCase(fetchUsers.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(addUsers.pending, (state) => {
        state.isLoading = true;
        state.status = "loading";
      })
      .addCase(addUsers.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.isLoading = false;
        state.data.push(action.payload);
      })
      .addCase(addUsers.rejected, (state, action) => {
        state.isLoading = false;
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(updateUsers.fulfilled, (state, action) => {
        const updatedUsers = action.payload;
        const index = state.data.findIndex(
          (user) => user._id === updatedUsers._id
        );
        if (index !== -1) {
          state.data[index] = updatedUsers;
        }
      })
      .addCase(deleteUsers.fulfilled, (state, action) => {
        const userId = action.payload;
        state.status = "succeeded";
        state.data = state.data.filter((user) => user._id !== userId);
      })
      .addCase(resetPassword.pending, (state) => {
        state.status = "loading";
      })
      .addCase(resetPassword.fulfilled, (state, action) => {
        state.status = "succeeded";
      })
      .addCase(resetPassword.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export default userSlice.reducer;
