import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "./../../../Api";

const initialState = {
  data: [],
  status: "idle",
  isLoading: false,
  error: null,
};
// Create an async thunk for fetching shape
export const fetchShape = createAsyncThunk(
  "shape/fetchShape",
  async (_, thunkAPI) => {
    try {
      const response = await api.get(
        `${process.env.REACT_APP_API_URL}/api/shape/shapes`
      );

      return response.data.data;
    } catch (error) {
      // You can handle errors here if needed
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

// Create an async thunk for adding a new shape
export const addShape = createAsyncThunk(
  "shape/addShapes",
  async (newShape, { rejectWithValue }) => {
    try {
      const response = await api.post(
        `${process.env.REACT_APP_API_URL}/api/shape/addShapes`,
        newShape
      );

      // Assuming the response structure is { code, success, message, data }
      if (response.data.success) {
        // Shape added successfully
        return response.data.data; // Assuming you want to return the added shape data
      } else {
        // Handle the case where the request was successful but the server indicates failure
        return rejectWithValue({
          error: response.data.message || "Failed to add shape",
        });
      }
    } catch (error) {
      console.error(error, "Error while adding shape");
      // Handle errors here if needed
      return rejectWithValue({ error: error.message });
    }
  }
);

// Create an async thunk for updating a shape
export const updateShape = createAsyncThunk(
  "shape/updateShapes",
  async (updatedShape, thunkAPI) => {
    try {
      const response = await api.put(
        `${process.env.REACT_APP_API_URL}/api/shape/updateShapes`,
        updatedShape
      );

      return response.data.data;
    } catch (error) {
      // You can handle errors here if needed
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

// Create an async thunk for deleting a shape
export const deleteShape = createAsyncThunk(
  "shape/deleteShape",
  async (shapeId) => {
    try {
      await api.delete(
        `${process.env.REACT_APP_API_URL}/api/shape/deleteShapes/${shapeId}`
      );
      return shapeId;
    } catch (error) {}
  }
);

const shapeSlice = createSlice({
  name: "shape",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchShape.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchShape.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.data = action.payload;
      })
      .addCase(fetchShape.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(addShape.pending, (state) => {
        state.isLoading = true;
        state.status = "loading";
      })
      .addCase(addShape.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.isLoading = false;
        state.data.push(action.payload);
      })
      .addCase(addShape.rejected, (state, action) => {
        state.isLoading = false;
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(updateShape.fulfilled, (state, action) => {
        const updatedShape = action.payload;
        const index = state.data.findIndex(
          (shape) => shape._id === updatedShape._id
        );
        if (index !== -1) {
          state.data[index] = updatedShape;
        }
      })
      .addCase(deleteShape.fulfilled, (state, action) => {
        const shapeId = action.payload;
        state.status = "succeeded";
        state.data = state.data.filter((shape) => shape._id !== shapeId);
      });
  },
});

export default shapeSlice.reducer;
