import React from 'react';

const IconButton = ({ text, outlined = false, icon, onClick, customClasses }) => {
    const hasCustomTextColor = /text-[\w-]+/.test(customClasses);

    const baseClasses = "flex items-center px-4 py-2 rounded leading-tight focus:outline-none focus:shadow-outline ";
    const outlinedClasses = `border ${hasCustomTextColor ? '' : 'text-gray-700'} hover:bg-gray-700 hover:text-white `;
    const filledClasses = "bg-gray-700 text-white hover:bg-gray-500 ";

    const buttonClasses = outlined ? baseClasses + outlinedClasses : baseClasses + filledClasses;

    return (
        <div className="flex space-x-4 whitespace-nowrap">
            <button className={buttonClasses + customClasses} onClick={onClick}>
                <span>{text}</span>
                {icon}
            </button>
        </div>
    );
};

export default IconButton;
