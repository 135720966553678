import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    data: {
        selectedStyle: '',
        selectedType: '',
        selectedHeight: '',
        selectedColor: '',
        selectedTerminalPost: null,
        selectedTopRail: null,
    }
};

export const fenceBuilderSlice = createSlice({
    name: 'fenceBuilder',
    initialState,
    reducers: {
        updateFenceBuilderData: (state, action) => {
            const { key, value } = action.payload;
            if (key in state.data) {
                state.data[key] = value;
            }
        }
    }
});

export const { updateFenceBuilderData } = fenceBuilderSlice.actions;

export default fenceBuilderSlice.reducer;
