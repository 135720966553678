import React, { useEffect, useState } from 'react';
import { POST_INSTALLATION_METHODS } from "../../../../../../utils/constants";
import { getParsedValue } from "../../addFenceUtils";

function PostInstallationMethods({ setSelectedData, selectedData }) {
    const [methods, setMethods] = useState([]);

    const toggleMethod = index => {
        setMethods(currentMethods => currentMethods.map((method, idx) => idx === index ? { ...method, active: !method.active } : method));
    };

    useEffect(() => {
        let meths = POST_INSTALLATION_METHODS.map((method) => {
            let s = selectedData?.find(sMethod => sMethod.id === method.id);
            if (s) {
                method = {
                    ...method,
                    active: true,
                    data: method.data?.map((d) => {
                        if (d.id === "excavateHoles" && s.excavateHoles) {
                            d = {
                                ...d,
                                children: d.children.map(c => {
                                    if (c.id === "holeDiameter") {
                                        c.value = s.excavateHoles.holeDiameter;
                                    }
                                    if (c.id === "holeDepth") {
                                        c.value = s.excavateHoles.holeDepth;
                                    }
                                    return c;
                                })
                            }
                        }
                        return {
                            ...d,
                            value: s[d.id] !== undefined ? s[d.id] : '',
                        };
                    })
                };
            } else {
                method = {
                    ...method,
                    active: false,
                    data: method.data?.map(d => ({
                        ...d,
                        value: '',
                        children: d.children ? d.children.map(c => ({ ...c, value: '' })) : []
                    }))
                };
            }
            return method;
        });
        setMethods(meths);
    }, [selectedData]);

    useEffect(() => {
        let newData = [];
        for (const method of methods) {
            if (!method.active) continue;
            const excavateHoles = method.data?.find(d => d.id === "excavateHoles" && d.value);
            newData.push({
                method: method.name,
                id: method.id,
                terminalPostLength: getParsedValue(method.data?.find(d => d.id === 'terminalPostLength')?.value),
                gatePostLength: getParsedValue(method.data?.find(d => d.id === 'gatePostLength')?.value),
                linePostLength: getParsedValue(method.data?.find(d => d.id === 'linePostLength')?.value),
                setPostsWithConcrete: method.data?.find(d => d.id === "setPostsWithConcrete" && d.value),
                excavateHoles: excavateHoles ? {
                    holeDiameter: excavateHoles.children?.find(c => c.id === "holeDiameter")?.value,
                    holeDepth: excavateHoles.children?.find(c => c.id === "holeDepth")?.value
                } : null
            });
        }
        setSelectedData(newData || []);
    }, [methods, setSelectedData]);

    const handleChangeDetail = (index, id, value, type) => {
        setMethods(methods => methods.map((method, idx) => {
            if (idx !== index) return method; // Only update the selected method

            return {
                ...method,
                data: method.data.map(detail => {
                    if (detail.id === id) {
                        // Update the main detail
                        return { ...detail, value };
                    }
                    if (detail.children) {
                        // Update children if they depend on the changed detail
                        return {
                            ...detail,
                            children: detail.children.map(child => {
                                if (child.id === id) {
                                    return { ...child, value };
                                }
                                if (child.dependsOn === id) {
                                    return { ...child, value: type === 'checkbox' && !value ? '' : child.value };
                                }
                                return child;
                            })
                        };
                    }
                    return detail;
                })
            };
        }));
    };

    return (
        <div className="w-full max-w-4xl">
            <label className="text-sm font-bold">
                Post Installation Methods
            </label>
            <div className="ml-4">
                {methods.map((method, index) => (
                    <div key={method.name}>
                        <label className="flex items-center mt-1">
                            <input
                                type="checkbox"
                                checked={method.active}
                                onChange={() => toggleMethod(index)}
                                className="form-checkbox h-3.5 w-3.5 text-indigo-600"
                            />
                            <span className="ml-2 text-gray-800 font-medium text-[13px]">{method.name}</span>
                        </label>
                        <div className="pl-6 flex items-start">
                            {method.active && method.data.map((detail, i) => (
                                <div key={detail.id} className="px-1">
                                    {detail.type === 'checkbox' ? (
                                        <label className="flex items-center">
                                            <input
                                                type="checkbox"
                                                checked={detail.value}
                                                onChange={(e) => handleChangeDetail(index, detail.id, e.target.checked, 'checkbox')}
                                                className="form-checkbox h-4 w-4 text-indigo-600"
                                            />
                                            <span className="ml-2 text-xs text-gray-700">{detail.label}</span>
                                        </label>
                                    ) : (
                                        <label className="block">
                                            <span className="text-xs text-gray-700">{detail.label}</span>
                                            <input
                                                type="text"
                                                value={detail.value}
                                                placeholder={detail.placeholder}
                                                onChange={(e) => handleChangeDetail(index, detail.id, e.target.value, 'text')}
                                                className="mt-1 block w-full px-2 py-1 bg-white border border-gray-300 rounded shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-xs"
                                                disabled={detail.dependsOn ? !method.data.find(x => x.id === detail.dependsOn).value : false}
                                            />
                                        </label>
                                    )}
                                    {detail.children && detail.value && detail.children.map((child, childIndex) => (
                                        <div key={child.id} className="pl-6">
                                            <label className="block">
                                                <span className="text-xs text-gray-700">{child.label}</span>
                                                <input
                                                    type="text"
                                                    value={child.value}
                                                    placeholder={child.placeholder}
                                                    onChange={(e) => handleChangeDetail(index, child.id, e.target.value, 'text')}
                                                    className="mt-1 block w-full px-2 py-1 bg-white border border-gray-300 rounded shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-xs"
                                                    disabled={!detail.value}
                                                />
                                            </label>
                                        </div>
                                    ))}
                                </div>
                            ))}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default PostInstallationMethods;
