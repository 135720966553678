import React from 'react';

const MyButton = ({
                      text,
                      onClick,
                      color = 'blue',
                      size = 'medium',
                      textSize = 'medium',
                      customClasses = '',
                      loading = false, // new prop to handle loading state
                      loadingText
                  }) => {
    // Define button padding sizes
    const sizes = {
        small: 'px-2 py-1',
        medium: 'px-4 py-2',
        large: 'px-6 py-3',
    };

    // Define text sizes
    const textSizes = {
        xSmall: 'text-xs',
        small: 'text-sm',
        medium: 'text-md',
        large: 'text-lg',
    };

    // Define button colors
    const colors = {
        blue: 'bg-blue-500 hover:bg-blue-700 text-white',
        red: 'bg-red-500 hover:bg-red-700 text-white',
        green: 'bg-green-500 hover:bg-green-700 text-white',
        yellow: 'bg-yellow-400 hover:bg-yellow-600 text-white',
        gray: 'bg-gray-400 hover:bg-gray-600 text-white',
        purple: 'bg-purple-400 hover:bg-purple-600 text-white',
    };

    // Button className combines base, padding size, text size, color classes and custom classes
    const buttonClass = `rounded focus:outline-none focus:shadow-outline ${sizes[size]} ${textSizes[textSize]} ${colors[color]} ${customClasses} ${loading ? 'cursor-not-allowed bg-gray-400' : ''}`;

    return (
        <button
            onClick={!loading ? onClick : undefined} // Prevent onClick action when loading
            className={buttonClass}
            disabled={loading} // Disable button when loading
        >
            {loading ? (
                <div className="flex items-center justify-center">
                    <div className="mr-2 animate-spin rounded-full border-t-2 border-r-2 border-b-2 border-l-2 border-white h-4 w-4"></div>
                    {loadingText || "Saving ..."}
                </div>
            ) : text}
        </button>
    );
};

export default MyButton;
