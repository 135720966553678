import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../../Api";

const initialState = {
    allComponents: {
        data: [],
        status: "idle",
        isLoading: false,
        error: null,
    },
    componentsByCollectionId: {
        data: [],
        status: "idle",
        isLoading: false,
        error: null,
    }
};
// Async thunk to fetch all fence components
export const fetchFenceComponents = createAsyncThunk(
    "fenceComponent/fetchFenceComponents",
    async (_, thunkAPI) => {
        try {
            const response = await api.get(
                `${process.env.REACT_APP_API_URL}/api/fence/getFenceComponents`
            );
            return response.data.data;
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: error.message });
        }
    }
);

// Async thunk to fetch fence components by collection ID
export const fetchFenceComponentsByCollectionId = createAsyncThunk(
    "fenceComponent/fetchFenceComponentsByCollectionId",
    async (collectionId, thunkAPI) => {
        try {
            const response = await api.get(
                `${process.env.REACT_APP_API_URL}/api/fence/getFenceComponentsByCollectionId/${collectionId}`
            );
            return response.data.data;
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: error.message });
        }
    }
);

// Async thunk to add a new fence component
export const addFenceComponent = createAsyncThunk(
    "fenceComponent/addFenceComponent",
    async (newComponent, { rejectWithValue }) => {
        try {
            const response = await api.post(
                `${process.env.REACT_APP_API_URL}/api/fence/addFenceComponent`,
                newComponent
            );
            if (response.data.success) {
                return response.data.data;
            } else {
                return rejectWithValue({ error: response.data.message || "Failed to add component" });
            }
        } catch (error) {
            return rejectWithValue({ error: error.response.data.message || error.message });
        }
    }
);

// Async thunk to update a fence component
export const updateFenceComponent = createAsyncThunk(
    "fenceComponent/updateFenceComponent",
    async (updatedComponent, thunkAPI) => {
        try {
            const response = await api.put(
                `${process.env.REACT_APP_API_URL}/api/fence/updateFenceComponent`,
                updatedComponent
            );
            return response.data.data;
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: error.response?.data?.message || error.message });
        }
    }
);

// Async thunk to delete a fence component
export const deleteFenceComponent = createAsyncThunk(
    "fenceComponent/deleteFenceComponent",
    async (componentId, thunkAPI) => {
        try {
            await api.delete(
                `${process.env.REACT_APP_API_URL}/api/fence/deleteFenceComponent?id=${componentId}`
            );

            return componentId;
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: error.message });
        }
    }
);

const fenceComponentSlice = createSlice({
    name: "fenceComponent",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchFenceComponents.pending, (state) => {
                state.allComponents.status = "loading";
                state.allComponents.isLoading = true;
            })
            .addCase(fetchFenceComponents.fulfilled, (state, action) => {
                state.allComponents.status = "succeeded";
                state.allComponents.data = action.payload;
                state.allComponents.isLoading = false;

            })
            .addCase(fetchFenceComponents.rejected, (state, action) => {
                state.allComponents.status = "failed";
                state.allComponents.error = action.error.message;
                state.allComponents.isLoading = false;

            })

            // Handle state for components by collection ID
            .addCase(fetchFenceComponentsByCollectionId.pending, (state) => {
                state.componentsByCollectionId.status = "loading";
            })
            .addCase(fetchFenceComponentsByCollectionId.fulfilled, (state, action) => {
                state.componentsByCollectionId.status = "succeeded";
                state.componentsByCollectionId.data = action.payload;
            })
            .addCase(fetchFenceComponentsByCollectionId.rejected, (state, action) => {
                state.componentsByCollectionId.status = "failed";
                state.componentsByCollectionId.error = action.error.message;
            })
            // add fence component
            .addCase(addFenceComponent.pending, (state) => {
                state.allComponents.isLoading = true;
                state.allComponents.status = "loading";
            })
            .addCase(addFenceComponent.fulfilled, (state, action) => {
                state.allComponents.status = "succeeded";
                state.allComponents.isLoading = false;
                state.allComponents.data.push(action.payload);
            })
            .addCase(addFenceComponent.rejected, (state, action) => {
                state.allComponents.isLoading = false;
                state.allComponents.status = "failed";
                state.allComponents.status = action.error.message;
            })

            // update fence component
            .addCase(updateFenceComponent.pending, (state) => {
                state.allComponents.isLoading = true;
                state.allComponents.status = "loading";
            })
            .addCase(updateFenceComponent.fulfilled, (state, action) => {
                state.allComponents.status = "succeeded";
                state.allComponents.isLoading = false;
                const updatedComponent = action.payload;
                const index = state.allComponents.data.findIndex(
                    (component) => component._id === updatedComponent._id
                );
                if (index !== -1) {
                    state.allComponents.data[index] = updatedComponent;
                }
            })
            .addCase(updateFenceComponent.rejected, (state, action) => {
                state.allComponents.isLoading = false;
                state.allComponents.status = "failed";
                state.allComponents.error = action.error.message;
            })

            // delete fence component
            .addCase(deleteFenceComponent.pending, (state) => {
                state.allComponents.isLoading = true;
            })
            .addCase(deleteFenceComponent.fulfilled, (state, action) => {
                state.allComponents.isLoading = false;
                state.allComponents.status = "succeeded";
                const componentId = action.payload;
                state.allComponents.data = state.allComponents.data.filter(
                    (component) => component._id !== componentId
                );
            })
            .addCase(deleteFenceComponent.rejected, (state, action) => {
                state.allComponents.isLoading = false;
                state.allComponents.status = "failed";
                state.allComponents.error = action.error.message;
            });
    },
});

export default fenceComponentSlice.reducer;
