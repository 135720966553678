import React, { useMemo } from "react";
import { useTable } from "react-table";
import Card from "components/card"; // Ensure this is correctly imported from your project structure
import { FaEdit } from "react-icons/fa";
import { MdDelete } from "react-icons/md";

const VendorsTable = ({ vendors, onEdit, onDelete }) => {
    const data = useMemo(() => vendors.map((item, index) => ({
        ...item,
        index: index + 1,  // Adding an index property for display
    })), [vendors]);

    const columns = useMemo(() => [
        {
            Header: 'Index',
            accessor: 'index',
        },
        {
            Header: 'Name',
            accessor: 'name',
        },
        {
            Header: 'Address',
            accessor: 'address',
        },
        {
            Header: 'Contact Number',
            accessor: 'contactNumber',
        },
        {
            Header: 'Email',
            accessor: 'email',
        },
        {
            Header: 'Website',
            accessor: 'website',
        },
        {
            Header: 'Actions',
            accessor: 'actions',
            Cell: ({ row }) => (
                <div className="flex gap-2">
                    <button onClick={() => onEdit(row.original)} className="text-lg text-blue-500">
                        <FaEdit />
                    </button>
                    <button onClick={() => onDelete(row.original)} className="text-[20px] text-red-500">
                        <MdDelete />
                    </button>
                </div>
            ),
        }
    ], [onDelete, onEdit]);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = useTable({ columns, data });

    return (
        <Card extra="w-full h-full p-4">
            <table {...getTableProps()} className="w-full text-xs text-left text-gray-700 dark:text-gray-400">
                <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                {headerGroups.map(headerGroup => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map(column => (
                            <th {...column.getHeaderProps()} className="py-1 px-6 text-xs">
                                {column.render('Header')}
                            </th>
                        ))}
                    </tr>
                ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                {rows.map(row => {
                    prepareRow(row);
                    return (
                        <tr {...row.getRowProps()} className="bg-white text-xs border-b dark:bg-gray-800 dark:border-gray-700">
                            {row.cells.map(cell => (
                                <td {...cell.getCellProps()} className="py-1 px-6">
                                    {cell.render('Cell')}
                                </td>
                            ))}
                        </tr>
                    );
                })}
                </tbody>
            </table>
        </Card>
    );
};

export default VendorsTable;
