import React, { useState, useEffect, lazy, Suspense } from "react";
import { useDispatch, useSelector } from "react-redux";
import { MdOutlineAdd } from "react-icons/md";
import { toast } from "react-toastify";
import {
    fetchVendors,
    addVendor,
    updateVendor,
    deleteVendor
} from "../../../redux/reducers/vendor/vendorSlice";
import VendorsTable from "./VendorTable";
import IconButton from "../../common-comps/tailwind-css/buttons/IconButton";
import Navbar from "../../../components/navbar";

// Lazy load the modals
const AddVendorModal = lazy(() => import("./AddVendorModal"));
const DeleteConfirmationModal = lazy(() => import("../../../utils/DeleteConfirmationModal"));

const FenceVendors = () => {
    const dispatch = useDispatch();
    const vendors = useSelector((state) => state.fenceVendors.data);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [deleteId, setDeleteId] = useState("");
    const [isDeleting, setIsDeleting] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [selectedVendor, setSelectedVendor] = useState(null);
    const [editMode, setEditMode] = useState(false);

    useEffect(() => {
        dispatch(fetchVendors());
    }, [dispatch]);

    const handleEdit = (vendor) => {
        setSelectedVendor(vendor);
        setEditMode(true);
        setIsModalOpen(true);
    };

    const handleDelete = async (vendorId) => {
        try {
            setIsDeleting(true);
            await dispatch(deleteVendor(vendorId));
            toast.success("Vendor successfully deleted", {
                position: toast.POSITION.TOP_RIGHT,
            });
            setIsDeleteModalOpen(false);
        } catch (error) {
            toast.error(`Error deleting vendor: ${error.message}`, {
                position: toast.POSITION.TOP_RIGHT,
            });
        } finally {
            setIsDeleting(false);
        }
    };

    const handleUpdate = async (vendorData) => {
        try {
            setIsSaving(true);
            await dispatch(updateVendor(vendorData));
            toast.success("Vendor successfully updated", {
                position: toast.POSITION.TOP_RIGHT,
            });
        } catch (error) {
            toast.error(`Error updating vendor: ${error.message}`, {
                position: toast.POSITION.TOP_RIGHT,
            });
        } finally {
            setIsSaving(false);
            setEditMode(false);
            setIsModalOpen(false);
        }
    };

    const handleAdd = async (vendorData) => {
        try {
            setIsSaving(true);
            await dispatch(addVendor(vendorData));
            toast.success("Vendor successfully added", {
                position: toast.POSITION.TOP_RIGHT,
            });
        } catch (error) {
            toast.error(`Error adding vendor: ${error.message}`, {
                position: toast.POSITION.TOP_RIGHT,
            });
        } finally {
            setIsSaving(false);
            setIsModalOpen(false);
        }
    };

    return (
        <div style={{height:'calc(100% - 40px)'}}>
            <Navbar
                buttonText={"Add Vendor"}
                linkText={"Vendors"}
                heading={"Vendors"}
                handleButtonClicked={() => setIsModalOpen(true)}
            />
            <VendorsTable vendors={vendors} onEdit={handleEdit} onDelete={(vendor) => {
                setIsDeleteModalOpen(true);
                setDeleteId(vendor._id);
            }} />
            <Suspense fallback={<div>Loading...</div>}>
                {isModalOpen && <AddVendorModal
                    isOpen={isModalOpen}
                    editMode={editMode}
                    onClose={() => setIsModalOpen(false)}
                    selectedVendor={selectedVendor}
                    isSaving={isSaving}
                    onSave={editMode ? handleUpdate : handleAdd}
                />}
                {isDeleteModalOpen && <DeleteConfirmationModal
                    isOpen={isDeleteModalOpen}
                    loading={isDeleting}
                    onClose={() => setIsDeleteModalOpen(false)}
                    onDelete={() => handleDelete(deleteId)}
                />}
            </Suspense>
        </div>
    );
};

export default FenceVendors;
